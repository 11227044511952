import { Box, Divider, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useEffect, useState } from 'react'
import {
	showSuccessToast,
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import {
	ContactDetail,
	ContactDetailMap,
	CustomerAPIKey,
} from '../../../../utils/interfaces/DBModels'
import {
	ContactDetailTypes,
	CustomerPartners,
	InvalidIDs,
	ReturnTypes,
	Roles,
} from '../../../../utils/enums/enums'
import { LumenPartnerID } from '../../../../utils/constants/constants'
import { RoleCommunicationEmails } from '../../../../utils/interfaces/ComponentModels'
import Preloader from '../../../shared/loading/preloader/Preloader'
import UseCrud from '../../../../utils/customHooks/APICalls/UseCrud'
import { DataResponse } from '../../../../utils/interfaces/APIModels'
import CustomerAPIKeySection from './CustomerAPIKeySection/CustomerAPIKeySection'
import RoleCommunicationSettings from './RoleCommunicationSettings/RoleCommunicationSettings'
import UseAPIHandler from '../../../../utils/customHooks/APICalls/UseAPIHandler'

const CommunicationSettings = () => {
	// Global
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInUserRole = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	// Hooks
	const { fetchData, modifyData } = UseCrud()
	const { handleAPICall } = UseAPIHandler()

	// Display constants
	const [customerAPIKey, setCustomerAPIKey] = useState('')
	const [roleCommunicationList, setRoleCommunicationList] = useState(
		[] as RoleCommunicationEmails[]
	)

	// Logic constants
	const [customerAPIKeyID, setCustomerAPIKeyID] = useState(0)
	const [contactDetailList, setContactDetailList] = useState(
		[] as ContactDetail[]
	)

	// Flags
	const [initialCallsDone, setInitialCallsDone] = useState(false)

	// Check if customer admin
	const isCustomerAdmin = loggedInUserRole[0].RoleID === Roles.CustomerAdmin

	// Convert enum to array list = {Type, Value}
	const roleList = Object.keys(ContactDetailTypes)
		.filter((key) => isNaN(Number(key)))
		.map((key) => ({
			Type: key as keyof typeof ContactDetailTypes,
			Value: ContactDetailTypes[key as keyof typeof ContactDetailTypes],
		}))

	useEffect(() => {
		// Make initial call
		performInitialCalls()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Handle trigger of GET calls
	const performInitialCalls = async () => {
		if (isCustomerAdmin) {
			await getCustomerAccessKey()
		}
		await getRoleEmails()
		setInitialCallsDone(true)
	}

	// API Calls
	// GET: Get Customer Access key from DB
	const getCustomerAccessKey = async () => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CommunicationSettings.tsx: getCustomerAccessKey()',
			QueryURL: `CustomerAPIKey.First(CustomerAPIKey.CustomerID = '${loggedInUser.customerID}')`,
			ErrorMessage: 'An error occurred when adding API Access key to DB',
			ShowErrorToast: true,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && dataResponse.Obj) {
			var customerAPIKeyObj = dataResponse.Obj.CustomerAPIKey as CustomerAPIKey
			if (customerAPIKeyObj && customerAPIKeyObj.APIKey) {
				// Decrypt the key and assign
				var decryptedKey = await toAlphaString(customerAPIKeyObj.APIKey)
				if (decryptedKey) {
					setCustomerAPIKey(decryptedKey)
				}

				// Set ID for delete purposes
				setCustomerAPIKeyID(Number(customerAPIKeyObj.CustomerAPIKeyID))
			}
		}
	}

	// GET: Get the role emails
	const getRoleEmails = async () => {
		// List to fill
		var contactDetailListResponse = [] as ContactDetail[]

		// Generate request URL based on role
		var whereClause = `ContactDetailMap.CustomerPartnerID = '${LumenPartnerID}'`
		if (isCustomerAdmin) {
			whereClause = `ContactDetailMap.CustomerID = '${loggedInUser.customerID}'`
		}
		var requestURL = `ContactDetail.ContactDetailMap.Where(${whereClause} & ContactDetail.isRoleEmail = '1')`

		var dataResponse = (await fetchData({
			FileAndFunctionName: 'CommunicationSettings: getRoleEmails()',
			QueryURL: requestURL,
			ErrorMessage: 'An error occurred when getting role email information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			contactDetailListResponse = dataResponse.Obj
				.ContactDetailList as ContactDetail[]
		}

		// Loop through roles to build display
		var roleCommunicationList = [] as RoleCommunicationEmails[]
		for (let r = 0; r < roleList.length; r++) {
			// Check if role email exists, else add a blank
			if (contactDetailListResponse) {
				var roleFound = contactDetailListResponse.find(
					(c) => Number(c.ContactDetailTypeID) === roleList[r].Value
				)
				if (roleFound) {
					roleCommunicationList.push({
						RoleID: roleList[r].Value,
						Email: roleFound.ContactPersonEmail,
						Synced: true,
					})
				} else {
					roleCommunicationList.push({
						RoleID: roleList[r].Value,
						Email: '',
						Synced: false,
					})
				}
			} else {
				roleCommunicationList.push({
					RoleID: roleList[r].Value,
					Email: '',
					Synced: false,
				})
			}
		}

		setRoleCommunicationList(roleCommunicationList)
		setContactDetailList(contactDetailListResponse)
	}

	// POST (API Key): Add Access Key
	const addCustomerAccessKey = async (key: string) => {
		// Encrypt the key first then add to DB
		var customerAPIKeyObj: CustomerAPIKey = {
			CustomerID: loggedInUser.customerID,
			APIKey: toBetaString(key),
		}
		var addCustomerKeyObj = {
			CustomerAPIKey: customerAPIKeyObj,
		}

		var keyAdded = await modifyData({
			FileAndFunctionName: 'CustomerAPIKeySection: addCustomerAccessKey()',
			QueryURL: `AddV2?Params=CustomerAPIKey`,
			ErrorMessage: 'An error occurred when adding API Access key to DB',
			QueryObj: addCustomerKeyObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: 'API Access key has been generated successfully',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
		})
		if (keyAdded) {
			await getCustomerAccessKey()
		}
	}

	// POST (Role Emails): Delete access key
	const deleteCustomerAccessKey = async () => {
		// Encrypt the key first then add to DB
		var customerAPIKeyObj: CustomerAPIKey = {
			CustomerAPIKeyID: customerAPIKeyID,
		}
		var addCustomerKeyObj = {
			CustomerAPIKey: customerAPIKeyObj,
		}

		var keyDeleted = await modifyData({
			FileAndFunctionName: 'CommunicationSettings: deleteCustomerAccessKey()',
			QueryURL: `DeleteV2?Params=CustomerAPIKey`,
			ErrorMessage: 'An error occurred when adding API Access key to DB',
			QueryObj: addCustomerKeyObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: 'API Access key has been deleted successfully',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
		})
		if (keyDeleted) {
			setCustomerAPIKeyID(0)
			setCustomerAPIKey('')
		}
	}

	// POST (API Key): Add/Update Role Emails
	const modifyRoleEmails = async (emailList: RoleCommunicationEmails[]) => {
		// First check which emails need to be added and updated and perform two operations if needed
		var addList = [] as ContactDetail[]
		var updateList = [] as ContactDetail[]

		for (let r = 0; r < emailList.length; r++) {
			// Ensure email has a value
			var roleEmail = emailList[r].Email
			if (roleEmail && roleEmail.length > 0) {
				var roleFound = contactDetailList.find(
					(c) => Number(c.ContactDetailTypeID) === Number(emailList[r].RoleID)
				)
				if (roleFound) {
					// Push to update list if emails are different
					if (roleFound.ContactPersonEmail !== emailList[r].Email) {
						updateList.push({
							ContactDetailID: roleFound.ContactDetailID,
							ContactPersonEmail: emailList[r].Email,
						})
					}
				} else {
					// Push to add list
					addList.push({
						ContactDetailTypeID: emailList[r].RoleID,
						ContactPersonEmail: emailList[r].Email,
						IsRoleEmail: true,
					})
				}
			}
		}

		// Perform relevant actions
		var modifySuccess: boolean | null | void = null
		if (addList.length > 0) {
			// Add to DB then return IDs for mapping
			var addEmailsObj = {
				ContactDetailList: addList,
			}

			var dataResponse = await handleAPICall<DataResponse>({
				Action: 'Add Role Email Addresses',
				RequestURL: `AddV2?Params=ContactDetail:list`,
				RequestObj: addEmailsObj,
				IsMutations: true,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage: 'An error occurred when adding role emails',
				LogErrorToDB: true,
			})
			if (dataResponse && dataResponse.Obj) {
				var addedContactDetailResponse = dataResponse.Obj
					.ContactDetailList as ContactDetail[]

				if (
					addedContactDetailResponse &&
					addedContactDetailResponse.length > 0
				) {
					// Perform mapping
					var contactDetailMapList = [] as ContactDetailMap[]
					for (let a = 0; a < addedContactDetailResponse.length; a++) {
						contactDetailMapList.push({
							ContactDetailID: addedContactDetailResponse[a].ContactDetailID,
							CustomerPartnerID: isCustomerAdmin
								? CustomerPartners.No_Partner
								: LumenPartnerID,
							CarrierID: 1,
							CustomerID: isCustomerAdmin
								? loggedInUser.customerID
								: InvalidIDs.No_Customer,
						})
					}

					var addContactMapObj = {
						ContactDetailMapList: contactDetailMapList,
					}

					modifySuccess = await modifyData({
						FileAndFunctionName: 'CommunicationSettings: modifyRoleEmails()',
						QueryURL: `AddV2?Params=ContactDetailMap:list`,
						ErrorMessage: 'An error occurred when adding role email mappings',
						QueryObj: addContactMapObj,
						LogErrorToDB: true,
						UserName: loggedInUser.email,
						SuccessMessage: 'Successfully added role email mappings',
						ShowErrorMessage: false,
						ShowSuccessMessage: false,
					})
				}
			}
		}
		if (updateList.length > 0) {
			// Update emails
			var updateEmailsObj = {
				ContactDetailList: updateList,
			}

			modifySuccess = await modifyData({
				FileAndFunctionName: 'CommunicationSettings: modifyRoleEmails()',
				QueryURL: `UpdateV2?Params=ContactDetail:list`,
				ErrorMessage: 'An error occurred when updating role emails',
				QueryObj: updateEmailsObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully updated role emails',
				ShowErrorMessage: false,
				ShowSuccessMessage: false,
			})
		}

		if (modifySuccess) {
			showSuccessToast('All role emails have been modified successfully.')
			await getRoleEmails()
		}
	}

	// POST (Role Emails): Delete role email
	const deleteRoleEmail = async (roleID: number) => {
		// Get ID from the contact list
		var roleEmail = contactDetailList.find(
			(c) => Number(c.ContactDetailTypeID) === roleID
		)
		if (roleEmail) {
			// Delete contact detail and map
			var deleteContactDetailMapObj: ContactDetailMap = {
				ContactDetailID: roleEmail.ContactDetailID,
			}
			var deleteContactDetailObj: ContactDetail = {
				ContactDetailID: roleEmail.ContactDetailID,
			}
			var deleteObj = {
				ContactDetailMap: deleteContactDetailMapObj,
				ContactDetail: deleteContactDetailObj,
			}

			var emailDeleted = await modifyData({
				FileAndFunctionName: 'RoleCommunicationSettings: deleteRoleEmail()',
				QueryURL: `DeleteV2?Params=ContactDetailMap,ContactDetail`,
				ErrorMessage: 'An error occurred when delete role email',
				QueryObj: deleteObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: `${
					ContactDetailTypes[Number(roleEmail.ContactDetailTypeID)]
				} email address has been deleted successfully`,
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
			if (emailDeleted) {
				getRoleEmails()
			}
		}
	}

	// POST (Role Emails): Delete ALL role email
	const deleteAllRoleEmails = async () => {
		if (contactDetailList.length > 0) {
			// Build contact map list
			var deleteContactMapList = [] as ContactDetailMap[]
			for (let c = 0; c < contactDetailList.length; c++) {
				var contactMapList = contactDetailList[c]
					.ContactDetailMapList as ContactDetailMap[]
				deleteContactMapList.push(...contactMapList)
			}

			// Delete contact detail and maps
			var deleteObj = {
				ContactDetailMapList: deleteContactMapList,
				ContactDetailList: contactDetailList,
			}

			var emailDeleted = await modifyData({
				FileAndFunctionName: 'RoleCommunicationSettings: deleteAllRoleEmails()',
				QueryURL: `DeleteV2?Params=ContactDetailMap:list,ContactDetail:list`,
				ErrorMessage: 'An error occurred when delete role emails',
				QueryObj: deleteObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: `All role emails have been deleted as requested`,
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
			if (emailDeleted) {
				getRoleEmails()
			}
		}
	}

	return initialCallsDone ? (
		<>
			<Box component='section'>
				{/* Header */}
				<Box className='s-text'>
					<Typography component='h2'>Communication Settings</Typography>
					<Typography component='p'>
						Manage your API/email communication settings.
					</Typography>
				</Box>
				<Divider />
				{/* Section 1 - API Key Generation */}
				{isCustomerAdmin && (
					<>
						<CustomerAPIKeySection
							customerAPIKey={customerAPIKey}
							addCustomerAccessKey={addCustomerAccessKey}
							deleteCustomerAccessKey={deleteCustomerAccessKey}
						/>
						<Divider />
					</>
				)}
				{/* Section 2 - Communication Settings */}
				<RoleCommunicationSettings
					roleCommunicationList={roleCommunicationList}
					contactDetailList={contactDetailList}
					modifyRoleEmails={modifyRoleEmails}
					deleteRoleEmail={deleteRoleEmail}
					deleteAllRoleEmails={deleteAllRoleEmails}
				/>
			</Box>
		</>
	) : (
		<Preloader />
	)
}

export default CommunicationSettings
