import { useSelector } from 'react-redux'
import OrderDisplay from '../../OrderManagement/OrderDisplay'
import { RootState } from '../../../../../store/store'
import FilterOrdersHook from '../../../../../utils/customHooks/FilterOrdersHook/FilterOrdersHook'
import PostUpdateDataHook from '../../../../../utils/customHooks/APICalls/PostUpdateDataHook'
import { useEffect, useRef, useState } from 'react'
import {
	FilteredOrderResponse,
	FilterRequest,
	OrderResponse,
	PostHookObj,
} from '../../../../../utils/interfaces/APIModels'
import { useAppDispatch } from '../../../../../store/hooks'
import { setPageTitle } from '../../../../../store/reducers/reducers'
import { LumenPartnerID } from '../../../../../utils/constants/constants'
import { Order, OrderSKU } from '../../../../../utils/interfaces/DBModels'
import {
	FilteredOrderStatus,
	OrderStatuses,
	Roles,
} from '../../../../../utils/enums/enums'
import LoadingCard from '../../../../shared/loading/loadingCard/LoadingCard'

const PartnerOrderOverview = () => {
	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const isPartnerAdmin = useSelector(
		(state: RootState) => state.RootReducer.isPartnerAdminReducer.value
	)

	// Hooks
	const { retrieveFilteredOrders } = FilterOrdersHook()
	const { postUpdateDataWithoutRefetch } = PostUpdateDataHook()

	// Display constant
	const [orderResponse, setOrderResponse] = useState(
		{} as FilteredOrderResponse
	)
	// Flags
	const [orderCallMade, setOrderCallMade] = useState(false)
	const [callStatus, setCallStatus] = useState(0)
	const [orderCallDone, setOrderCallDone] = useState(false)
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(0)

	// General
	const dispatch = useAppDispatch()

	const customerID = useRef<string>('')

	useEffect(() => {
		// Page Title
		if (pageTitle !== 'Orders') {
			dispatch(setPageTitle('Orders'))
		}

		customerID.current = loggedInUser.customerID

		// Make call
		if (!orderCallMade) {
			setOrderCallMade(true)
			getOrderDisplay({
				pageNo: 1,
				pageSize: 5,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// API Calls
	// GET: Retrieve filtered orders
	const getOrderDisplay = async (filterRequest: FilterRequest) => {
		// Set the page number and size
		setPageNo(filterRequest.pageNo)
		setPageSize(filterRequest.pageSize)

		// Ensure filters is initialized
		const filters = filterRequest.filters ? { ...filterRequest.filters } : {}
		// Add filters for CustomerAdmins and PatnerAdmins
		if (customerID.current && !isPartnerAdmin) {
			if (customerID.current) {
				filters['CustomerID'] = `${customerID.current}`
			} else {
				// Set the filter value
				filters['CustomerPartnerID'] = `${LumenPartnerID}`
			}
		} else {
			// Set the filter value
			filters['CustomerPartnerID'] = `${LumenPartnerID}`

			if (customerID.current) {
				filters['CustomerID'] = `${customerID.current}`
			}
		}

		if (isPartnerAdmin) {
			filters['SipcomDirect'] = 'false'
		}

		// Replace filters in filterRequest
		const updatedFilterRequest = { ...filterRequest, filters }

		// Make hook call
		var orderResponse: OrderResponse = await retrieveFilteredOrders(
			updatedFilterRequest
		)

		// Check response
		if (orderResponse && orderResponse?.FilteredOrderResponse) {
			if (orderResponse.Status === FilteredOrderStatus.Success) {
				// Set response
				setOrderResponse(
					orderResponse.FilteredOrderResponse as FilteredOrderResponse
				)
			}

			setCallStatus(orderResponse.Status)
		}

		// Call done
		setOrderCallDone(true)
	}

	// POST: Confirm order
	const confirmOrder = async (
		orderID: number,
		hasOrderNotes: boolean,
		orderNotes: string
	) => {
		//get orderSKUs and checked if all autoRenew fields are set if not default them to true
		var matchingOrder = orderResponse.FilteredOrdersResponse?.find(
			(x) => x.OrderID === orderID
		)
		var orderSKUsToUpdate = matchingOrder?.OrderSKUs?.filter(
			(x) => x.AutoRenew == null
		)
		if (orderSKUsToUpdate && orderSKUsToUpdate.length > 0) {
			var orderSKUObjList = [] as OrderSKU[]

			orderSKUsToUpdate.map((x) =>
				orderSKUObjList.push({
					OrderSKUID: x.OrderSKUID,
					AutoRenew: true,
				})
			)
			var orderSKUUpdateObj = {
				OrderSKUList: orderSKUObjList,
			}
			var postHookObj: PostHookObj

			// Create post obj and make call
			postHookObj = {
				Action: 'Set Auto Renew Default',
				LoggedInUser: loggedInUser,
				RequestURL: 'UpdateV2?Params=OrderSKU:list',
				RequestObj: orderSKUUpdateObj,
				ShowSuccessMessage: false,
				ShowErrorMessage: true,
				ErrorMessage:
					'An error occurred when setting default value for Auto Renew',
				LogErrorToDB: true,
			}
			await postUpdateDataWithoutRefetch(postHookObj)
		}

		// Update obj
		var orderObj: Order = {
			OrderID: orderID,
			OrderStatusID: OrderStatuses.PartnerApproved, // Set order status ID to confirmed
			HasOrderNotes: hasOrderNotes,
		}

		// Update comments only if order notes were entered
		if (orderNotes.length > 0) {
			orderObj.Comments = orderNotes
		}

		var confirmOrderObj = {
			Order: orderObj,
		}

		// Create post obj and make call
		postHookObj = {
			Action: 'Confirm Order',
			LoggedInUser: loggedInUser,
			RequestURL: 'UpdateV2?Params=Order',
			RequestObj: confirmOrderObj,
			ShowSuccessMessage: true,
			SuccessMessage: 'Order has been confirmed successfully',
			ShowErrorMessage: true,
			ErrorMessage: 'An error occurred when confirming your order',
			LogErrorToDB: true,
		}
		await postUpdateDataWithoutRefetch(postHookObj)
	}

	return orderCallDone ? (
		<OrderDisplay
			orderResponse={orderResponse}
			callStatus={callStatus}
			pageNo={pageNo}
			pageSize={pageSize}
			// API Calls
			getOrderDisplay={getOrderDisplay}
			confirmOrder={confirmOrder}
		/>
	) : (
		<LoadingCard
			loadingCardTitle='Loading your order information'
			loadingCardDescription='Please wait as we receive your order information'
		/>
	)
}

export default PartnerOrderOverview
