import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store/hooks'
import {
	setAccessToken,
	setIsPartnerAdmin,
	setLoggedInUser,
	setLoggedInUserRolesPermissions,
	setPageTitle,
} from '../../store/reducers/reducers'
import { RootState } from '../../store/store'
import {
	toAlphaString,
	toBetaString,
} from '../../utils/helperFunctions/helperFunctions'
import './Dashboard.scss'
import { useAuth0 } from '@auth0/auth0-react'
import Preloader from '../../components/shared/loading/preloader/Preloader'
import ErrorPage from '../../components/shared/error/ErrorPage'
import { Roles } from '../../utils/enums/enums'
import VideoToolTips from '../../components/distinct/dashboard/VideoTooltips/VideoTooltips'
import Landing from '../../components/distinct/landing/Landing'
import DashboardCards from '../../components/distinct/dashboard/DashboardCards/DashboardCards'
import PostAndRetrieveDataHook from '../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { RolePermissionMap } from '../../utils/interfaces/DBModels'
import {
	BetaObject,
	GetUsersByOrgIDRequestModel,
	User,
} from '../../utils/interfaces/APIModels'
import {
	useCheckIfCustomerHasProductMutation,
	useGetUsersByOrgIDMutation,
} from '../../services/proxyAPIData'
import PartnerOrderOverview from '../../components/distinct/dashboard/OrderOverviews/PartnerOrderOverview/PartnerOrderOverview'
import ErrorLogging from '../../utils/customHooks/ErrorLogging'

function Dashboard() {
	const SDAProductID = 'SDA'
	// Need to get from state based on Auth0
	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const [errorMessage, setErrorMessage] = useState('')
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE
	const isLocalHost = false
	const [loadData, setLoadData] = useState(null as any)
	const [rolesAndPermissionsRetrieved, setRolesAndPermissionsRetrieved] =
		useState(false)
	const [showDashboardCards, setShowDashboardCards] = useState(false)
	const [roleID, setRoleID] = useState(0)
	const [dataLoaded, setDataLoaded] = useState(false)
	const [users, setUsers] = useState([] as User[])
	const [getUsersError, setGetUsersError] = useState('')
	const {
		isAuthenticated,
		user,
		error: auth0Error,
		getAccessTokenWithPopup,
		getAccessTokenSilently,
		isLoading: isAuth0Loading,
	} = useAuth0()
	const accessToken = useSelector(
		(state: RootState) => state.RootReducer.accessTokenReducer.value
	)
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()

	const [getUsersByOrgID] = useGetUsersByOrgIDMutation()
	const [checkIfCustomerHasProduct] = useCheckIfCustomerHasProductMutation()
	const { addErrorLog } = ErrorLogging()
	const isPartnerAdmin = useRef<boolean>(false)

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		//set page title
		if (auth0Error || getUsersError) {
			dispatch(setPageTitle('Dashboard - Error'))
		} else {
			if (pageTitle !== 'Dashboard') {
				dispatch(setPageTitle('Dashboard'))
			}
		}

		if (isAuthenticated && !auth0Error) {
			getAccessToken()
		}

		if (auth0Error) {
			displayAuth0Error()
		}

		if (loadData && users.length === 0) {
			getUsers()
		}

		if (!rolesAndPermissionsRetrieved) {
			if (users && users?.length > 0) {
				setRolesAndPermissionsRetrieved(true)
				getRolesAndPermissions()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		pageTitle,
		users,
		auth0Error,
		loadData,
		isAuthenticated,
		postAndRetrieveDataLoading,
	])

	const getUsers = async () => {
		var getUsersByOrgIDRequestModel: GetUsersByOrgIDRequestModel = {
			OrganizationID: process.env.REACT_APP_ORGANIZATION_ID + '',
			UserEmails: [user?.email + ''],
			AuthorizationToken: accessToken,
		}
		var betaObject: BetaObject = {
			Content: toBetaString(JSON.stringify(getUsersByOrgIDRequestModel)),
		}
		await getUsersByOrgID(betaObject)
			.unwrap()
			.then(async (usersDataResponse) => {
				if (usersDataResponse && usersDataResponse.Content) {
					var alphaText = await toAlphaString(usersDataResponse.Content + '')
					var _users = JSON.parse(alphaText) as User[]
					setUsers(_users)
				}
			})
			.catch((error) => {
				setGetUsersError('Failed getting users')
			})
	}

	const displayAuth0Error = () => {
		if (auth0Error) {
			var errorMessage = ''
			switch (true) {
				case auth0Error.message.trim().includes('is not part of'):
					errorMessage =
						'The account you tried logging in with does not exist in our system. Please try to log in with another account.'
					break

				case auth0Error.message
					.trim()
					.includes('invitation not found or already used'):
					errorMessage =
						'The invitation for the account you logged in was not found or it has already been used. Please try to log in with another account.'
					break

				case auth0Error.message.trim().toLowerCase().includes('invalid state'):
					// Do nothing
					break

				default:
					errorMessage =
						'There was an issue when logging you in. Please try to log in again.'
					break
			}
			setErrorMessage(errorMessage)
		}
	}

	const getAccessToken = async () => {
		if (accessToken.length === 0) {
			;(async () => {
				const scope =
					'read:users read:role read:organizations_summary read:organization_member_roles read:organization_invitations read:organizations read:user delete:user add:user'
				var token = ''
				if (isLocalHost) {
					token = await getAccessTokenWithPopup({
						audience: audience,
						scope: scope,
						prompt: 'consent',
					})
				} else {
					token = await getAccessTokenSilently({
						audience: audience,
						scope: scope,
					})
				}
				dispatch(setAccessToken(token))
				setLoadData(true)
			})()
		} else {
			setLoadData(true)
		}
	}

	const CheckIfCustomerCanDoSDA = async (customerID: string) => {
		var canCustomerDoSDA = false
		// check if logged in customer can do SDA
		//If so, set flag to show Dashboard cards
		var encCustomerID = toBetaString(customerID)
		var encProductID = toBetaString(SDAProductID)
		var checkIfCustomerHasSDAProductError = ''

		var checkIfCustomerHasSDAProductResp = await checkIfCustomerHasProduct(
			`customerID=${encCustomerID}&productID=${encProductID}`
		)
			.unwrap()
			.catch((error) => {
				checkIfCustomerHasSDAProductError = JSON.stringify(error)
			})

		var decryptedCheckIfCustomerHasSDAProductResp = await toAlphaString(
			checkIfCustomerHasSDAProductResp?.Content + ''
		)

		try {
			canCustomerDoSDA = JSON.parse(
				decryptedCheckIfCustomerHasSDAProductResp
			) as boolean
		} catch (error) {
			canCustomerDoSDA = false
			await addErrorLog(
				'Lumen Portal Check if Customer can do SDA',
				`${JSON.stringify(error)} ${checkIfCustomerHasSDAProductError}`
			)
		}

		setShowDashboardCards(canCustomerDoSDA)
	}

	const getRolesAndPermissions = async () => {
		var dataResponse = await postAndRetrieveDataFromDB(
			'Get User Role',
			`RolePermissionMap.Role.Where(Role.IsInternal = '0')`,
			true,
			true
		)

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _rolePermissionMapList = dataResponse.Obj
				.RolePermissionMapList as RolePermissionMap[]

			if (_rolePermissionMapList && users && user) {
				var _loggedInUser = users.find((x) => x.email === user.email)
				if (_loggedInUser) {
					dispatch(setLoggedInUser(_loggedInUser))
					var userRolesAndPermissions = _rolePermissionMapList.filter(
						(rolePermission) =>
							_loggedInUser?.roles.find(
								(authRole) => authRole.name === rolePermission.Role?.RoleName
							)
					)
					setRoleID(Number(userRolesAndPermissions[0].RoleID))
					// Check if user is PartnerAdmin
					isPartnerAdmin.current =
						Number(userRolesAndPermissions[0].RoleID) ===
						Number(Roles.PartnerAdmin)

					if (
						Number(userRolesAndPermissions[0].RoleID) ===
						Number(Roles.CustomerAdmin)
					) {
						await CheckIfCustomerCanDoSDA(_loggedInUser.customerID)
					}

					dispatch(setLoggedInUserRolesPermissions(userRolesAndPermissions))
					dispatch(setIsPartnerAdmin(isPartnerAdmin.current))
				}
			}
		}
		setDataLoaded(true)
	}

	return (
		<>
			{(postAndRetrieveDataError || getUsersError.length > 0) &&
			!isAuth0Loading ? (
				<ErrorPage></ErrorPage>
			) : !isAuthenticated && accessToken.length === 0 && !isAuth0Loading ? (
				<Landing errorMessage={errorMessage} />
			) : dataLoaded &&
			  isAuthenticated &&
			  !postAndRetrieveDataError &&
			  getUsersError.length === 0 &&
			  !auth0Error &&
			  !isAuth0Loading ? (
				<Box className='dashboard'>
					<Box className='dashboard-content'>
						{/* Check role */}
						{Number(roleID) === Number(Roles.CustomerAdmin) &&
							showDashboardCards && (
								<Box className='quick-actions-container'>
									<Box className='header-ln'>
										<Typography component='h2' color='text.header'>
											Quick Actions
										</Typography>
									</Box>
									<DashboardCards customerID={loggedInUser.customerID} />
								</Box>
							)}

						{/* Orders overview */}
						<Box className='dashboard-orders-overview'>
							<Box className='header-ln'>
								<Typography component='h2' color='text.header'>
									Orders Overview
								</Typography>
							</Box>
							<PartnerOrderOverview />
						</Box>

						{/* Learn More - Video tutorial links*/}
						<Box className='dashboard-orders-overview'>
							<Box className='header-ln'>
								<Typography component='h2' color='text.header'>
									Learn More
								</Typography>
							</Box>
							<VideoToolTips />
						</Box>
					</Box>
				</Box>
			) : (
				<Preloader />
			)}
		</>
	)
}

export default Dashboard
