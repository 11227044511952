import { Breadcrumbs, Link, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import HomeIcon from '@mui/icons-material/Home'
import './TitleAndBreadcrumbs.scss'
import { useEffect, useState } from 'react'
import {
	BreadcrumbsDisplay,
	BreadcrumbsList,
} from '../../../../utils/interfaces/ComponentModels'
import { useNavigate } from 'react-router'
import LayersIcon from '@mui/icons-material/Layers'

const TitleAndBreadcrumbs = () => {
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	// Get current path location
	const location = window.location.pathname

	// General variables
	const [isHome, setIsHome] = useState(false)
	const [breadcrumbsDisplayList, setBreadcrumbsDisplayList] = useState(
		[] as BreadcrumbsDisplay[]
	)
	const navigate = useNavigate()

	// Breadcrumbs
	const breadcrumbsList: BreadcrumbsList[] = [
		// Customer Portal
		{
			BreadcrumbTitle: 'Teams Direct Routing',
			BreadcrumbLink: '/dashboard/teams-dr',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Teams Direct Routing - Reconfiguration',
			BreadcrumbLink: '/dashboard/teams-dr-reconfigure',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Teams User Management',
			BreadcrumbLink: '/dashboard/teams-user-management',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Overview',
			BreadcrumbLink: `/dashboard/overview/${loggedInUser.customerID}`,
			HasChildren: false,
		},
		// Partner Portal
		{
			BreadcrumbTitle: 'Number Provisioning',
			BreadcrumbLink: '/dashboard/tn-provisioning',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Numbers',
			BreadcrumbLink: '/dashboard/tn-management',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Trial Order',
			BreadcrumbLink: '/dashboard/trial',
			HasChildren: true,
			ChildBreadcrumbs: {
				ChildBreadcrumbTitle: 'Add Trial Order',
				ChildBreadcrumbLink: '/add-trial-order',
			},
		},
		{
			BreadcrumbTitle: 'Customers',
			BreadcrumbLink: '/dashboard/customer-management',
			HasChildren: true,
			ChildBreadcrumbs: {
				ChildBreadcrumbTitle: 'Customer Overview',
				ChildBreadcrumbLink: '/customer-overview/',
			},
		},
		{
			BreadcrumbTitle: 'Settings',
			BreadcrumbLink: '/settings',
			HasChildren: true,
		},
		{
			BreadcrumbTitle: 'Knowledge Base',
			BreadcrumbLink: '/knowledge-base',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Orders',
			BreadcrumbLink: '/dashboard/order-management',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Price Books',
			BreadcrumbLink: '/dashboard/pricebook-management',
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Teams Configuration',
			BreadcrumbLink: `/dashboard/teams-overview/${loggedInUser.customerID}`,
			HasChildren: false,
		},
		{
			BreadcrumbTitle: 'Leads',
			BreadcrumbLink: '/dashboard/leads',
			HasChildren: false,
		},
	]

	useEffect(() => {
		if (location === '/' || location === '/dashboard') {
			setIsHome(true)
		} else {
			setIsHome(false)
			// Loop through breadcrumbs list to build display list
			var _breadcrumbsDisplayList = [] as BreadcrumbsDisplay[]
			breadcrumbsList.forEach((b) => {
				if (b.HasChildren) {
					// Check for children
					if (location.includes(b.ChildBreadcrumbs?.ChildBreadcrumbLink + '')) {
						_breadcrumbsDisplayList = [
							{
								BreadcrumbTitle: b.BreadcrumbTitle,
								BreadcrumbLink: b.BreadcrumbLink,
								IsActive: false,
							},
							{
								BreadcrumbTitle: b.ChildBreadcrumbs?.ChildBreadcrumbTitle + '',
								BreadcrumbLink: b.ChildBreadcrumbs?.ChildBreadcrumbLink + '',
								IsActive: true,
							},
						]
					} else {
						if (b.BreadcrumbLink === location) {
							// Add to breadcrumbs display list
							_breadcrumbsDisplayList = [
								{
									BreadcrumbTitle: b.BreadcrumbTitle,
									BreadcrumbLink: b.BreadcrumbLink,
									IsActive: true,
								},
							]
						}
					}
				} else {
					if (b.BreadcrumbLink === location) {
						// Add to breadcrumbs display list
						_breadcrumbsDisplayList = [
							{
								BreadcrumbTitle: b.BreadcrumbTitle,
								BreadcrumbLink: b.BreadcrumbLink,
								IsActive: true,
							},
						]
					}
				}
			})
			// Set breadcrumbs list
			setBreadcrumbsDisplayList(_breadcrumbsDisplayList)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, isHome])

	const handleBreadcrumbClick = (link: string) => {
		navigate(link)
	}

	return (
		<>
			<Box
				id='breadcrumbs'
				className='breadcrumbs-container'
				bgcolor='primary.main'>
				<Box className='flex-box sb'>
					{/* Box below shows page title */}
					<Box className='breadcrumbs-page-info'>
						{/* Note - breadcrumbs and page title to be passed in */}
						<Typography
							component='h1'
							className='page-header'
							color='text.light'>
							{pageTitle}
						</Typography>
					</Box>
					{/* Box below shows breadcrumbs */}
					<Box className='flex-box ctr-all'>
						<Breadcrumbs
							aria-label='breadcrumb'
							separator={
								<NavigateNextIcon
									fontSize='small'
									sx={{ color: 'text.light' }}
								/>
							}>
							{/* Home */}
							{isHome ? (
								<Typography
									sx={{ display: 'flex', alignItems: 'center' }}
									color='text.light'>
									<HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
									Home
								</Typography>
							) : (
								<Link
									underline='hover'
									sx={{
										display: 'flex',
										alignItems: 'center',
										cursor: 'pointer',
									}}
									color='text.light'
									onClick={() => handleBreadcrumbClick('/')}>
									<HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
									Home
								</Link>
							)}
							{/* The other pages */}
							{breadcrumbsDisplayList.map((item, index) => {
								return isHome ? null : !item.IsActive ? (
									<Link
										key={index}
										underline='hover'
										sx={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
										}}
										color='text.light'
										onClick={() => handleBreadcrumbClick(item.BreadcrumbLink)}>
										<LayersIcon sx={{ mr: 0.5 }} fontSize='inherit' />
										{item.BreadcrumbTitle}
									</Link>
								) : (
									<Typography
										key={index}
										sx={{ display: 'flex', alignItems: 'center' }}
										color='text.light'>
										<LayersIcon sx={{ mr: 0.5 }} fontSize='inherit' />
										{item.BreadcrumbTitle}
									</Typography>
								)
							})}
						</Breadcrumbs>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default TitleAndBreadcrumbs
