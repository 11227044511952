import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { setPageTitle } from '../../../store/reducers/reducers'
import { Box, Typography } from '@mui/material'
import { showErrorToast } from '../../../utils/helperFunctions/helperFunctions'
import './CustomerOverview.scss'
import OverviewDisplay from '../../../components/distinct/dashboard/CustomerOverview/OverviewDisplay'
import OverviewTabDisplay from '../../../components/distinct/dashboard/CustomerOverview/OverviewTabDisplay'
import { useNavigate, useParams } from 'react-router'
import Preloader from '../../../components/shared/loading/preloader/Preloader'
import {
	Customer,
	DDI,
	MSTeamsUser,
	Service,
	ServiceManagementReport,
} from '../../../utils/interfaces/DBModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import PostUpdateDataHook from '../../../utils/customHooks/APICalls/PostUpdateDataHook'
import { PostHookObj } from '../../../utils/interfaces/APIModels'

function CustomerOverview() {
	// Global variables
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()

	const {
		postUpdateDataWithoutRefetch,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
	} = PostUpdateDataHook()

	// Params
	const { customerID } = useParams()

	const [hasPOCOrder, setHasPOCOrder] = useState(false)
	const [ddiList, setDDIList] = useState({} as DDI[])
	const [customerName, setCustomerName] = useState('')
	const [customerMSTeamsList, setCustomerMSTeamsList] = useState(
		[] as MSTeamsUser[]
	)
	const [customerServicesList, setCustomerServicesList] = useState(
		[] as Service[]
	)
	const [
		customerServiceManagementReportList,
		setCustomerServiceManagementReportList,
	] = useState([] as ServiceManagementReport[])
	const [isServiceManagerRetrieved, setIsServiceManagerRetrieved] =
		useState(false)

	// General
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	// Flags
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [isCustomerRetrieved, setIsCustomerRetrieved] = useState(false)
	const [isValidCustomer, setIsValidCustomer] = useState(false)
	const [isOrdersRetrieved, setIsOrdersRetrieved] = useState(false)
	const [isDDIsRetrieved, setIsDDIsRetrieved] = useState(false)

	const DeleteDDIRange = async (ddiRangeID: number) => {
		if (ddiRangeID > 0) {
			var ObjToDeleteByDDIRangeID = {
				DDIRangeID: ddiRangeID,
			}

			var deleteDDIInfoObj = {
				DDIRangeTrunkMap: ObjToDeleteByDDIRangeID,
				DDI: ObjToDeleteByDDIRangeID,
				DDIRange: ObjToDeleteByDDIRangeID,
			}

			// Post call to delete
			var postHookObj: PostHookObj = {
				Action: 'Delete DDIs',
				LoggedInUser: loggedInUser,
				RequestURL: 'DeleteV2?Params=DDIRangeTrunkMap,DDI,DDIRange',
				RequestObj: deleteDDIInfoObj,
				ShowSuccessMessage: true,
				SuccessMessage: 'Successfully Deleted Number Range',
				ShowErrorMessage: true,
				ErrorMessage: 'An error occurred when deleting Number Range',
				LogErrorToDB: true,
			}

			setAPICallsDone(false)
			await postUpdateDataWithoutRefetch(postHookObj).then(async () => {
				setIsDDIsRetrieved(false)
			})
		}
	}

	useEffect(() => {
		var pageTitle = 'Customer Overview'
		dispatch(setPageTitle(pageTitle))

		const checkCustomer = async () => {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'Customer',
				`Customer.First(Customer.CustomerID = '${customerID}')`,
				false,
				true
			)
			if (dataResponse && dataResponse.Obj) {
				// Save customer name if found
				var customerResponse = dataResponse.Obj.Customer as Customer
				if (customerResponse && customerResponse.CustomerName) {
					setCustomerName(customerResponse.CustomerName)
				}

				// Valid customer
				setIsValidCustomer(true)
			} else {
				navigate('/')
				showErrorToast(
					'Customer was not found in our system. Please contact support.'
				)
			}
		}

		// GET: Check for POC Order
		const checkForPOCOrder = async () => {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'Orders',
				`Order.Exists(Order.CustomerID = '${customerID}' & Order.ExternalOrderID ~ 'POC')`,
				false,
				true
			)
			if (dataResponse && dataResponse.Obj && dataResponse.Obj === true) {
				// Customer has POC order
				setHasPOCOrder(true)
			}
		}

		const getDDIs = async () => {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'numbers',
				`DDI.DDIRange.Where(DDIRange.CustomerID = '${customerID}')`,
				false,
				true
			)
			setDDIList([] as DDI[])
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var ddiResponse = dataResponse.Obj.DDIList as DDI[]
				if (ddiResponse) {
					setDDIList(ddiResponse)
				}
			}
		}

		const getServiceManagementStatus = async () => {
			// Make call
			var dataResponse = await postAndRetrieveDataFromDB(
				'Service Manager Report',
				`MSTeamsUser.Where(MSTeamsUser.CustomerID = '${customerID}'),Service.Where(Service.ServiceID ~ '${customerID}'),ServiceManagementReport.Where(ServiceManagementReport.CustomerID = '${customerID}').Pagination(NumberOfRows = '5' & PageNumber = '1' & Column = 'ServiceManagementReport.LogTime' & SortType = 'DESC')`,
				false,
				true
			)
			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var msTeamsResponse = dataResponse.Obj.MSTeamsUserList as MSTeamsUser[]

				if (msTeamsResponse && msTeamsResponse.length > 0) {
					setCustomerMSTeamsList(msTeamsResponse)
				}

				var servicesResponse = dataResponse.Obj?.ServiceList as Service[]

				if (servicesResponse && servicesResponse.length > 0) {
					setCustomerServicesList(servicesResponse)
				}

				// Service Manager Reports
				var smReportResponse = dataResponse.Obj
					.ServiceManagementReportList as ServiceManagementReport[]

				if (smReportResponse && smReportResponse.length > 0) {
					setCustomerServiceManagementReportList(smReportResponse)
				}
			}
		}

		if (!postAndRetrieveDataLoading && !postAndRetrieveDataError) {
			// Make calls
			if (!isCustomerRetrieved) {
				setIsCustomerRetrieved(true)
				checkCustomer()
			} else {
				// If existing customer, make rest of calls
				if (isValidCustomer) {
					// Get Orders
					if (!isOrdersRetrieved) {
						setIsOrdersRetrieved(true)
						checkForPOCOrder()
					}

					// Get DDIs
					if (!isDDIsRetrieved) {
						setIsDDIsRetrieved(true)
						getDDIs()
					}

					// Check for service manager
					if (!isServiceManagerRetrieved) {
						setIsServiceManagerRetrieved(true)
						getServiceManagementStatus()
					}

					if (
						isOrdersRetrieved &&
						isDDIsRetrieved &&
						isServiceManagerRetrieved
					) {
						setAPICallsDone(true)
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		postAndRetrieveDataLoading,
		postAndRetrieveDataError,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
		ddiList,
		// Flags
		isCustomerRetrieved,
		isValidCustomer,
		isOrdersRetrieved,
		isDDIsRetrieved,
		isServiceManagerRetrieved,
		apiCallsDone,
	])

	return (
		<>
			<Box id='customer-overview' className='flex-box col rg'>
				{apiCallsDone ? (
					<>
						{loggedInUser.customerID === null && (
							<Box className='flex-box icon-header'>
								<img
									src={require(`../../../assets/icons/customerManagement/lmn/cust-overview.png`)}
									alt='Customer Management'
								/>
								<Typography component='h2' color='text.header'>
									{customerName}
								</Typography>
							</Box>
						)}
						<OverviewDisplay
							ServiceManagementReportList={customerServiceManagementReportList}
							MSTeamsUserList={customerMSTeamsList}
							ServiceList={customerServicesList}
						/>
						<OverviewTabDisplay
							customerID={customerID + ''}
							ddiList={ddiList}
							hasPOCOrder={hasPOCOrder}
							onDeleteDDIs={DeleteDDIRange}
						/>
					</>
				) : (
					<Preloader />
				)}
			</Box>
		</>
	)
}

export default CustomerOverview
