import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { CustomerListDisplay } from '../../../../utils/interfaces/ComponentModels'
import { CustomerStatus } from '../../../../utils/enums/enums'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../shared/datagrid/StyledDataGrid'
import PageviewIcon from '@mui/icons-material/Pageview'
import { useNavigate } from 'react-router'
import { useAppDispatch } from '../../../../store/hooks'
import { setCustomerID } from '../../../../store/reducers/reducers'
import { Customer } from '../../../../utils/interfaces/DBModels'

const CustomerDisplay = ({ customerList }: { customerList: Customer[] }) => {
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})
	const [customerRows, setCustomerRows] = useState([] as CustomerListDisplay[])
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (customerList.length > 0) {
			var _customerDetails = [] as CustomerListDisplay[]
			customerList.forEach((c) => {
				var _customerPartnerReference = ''
				var _customerStatus = ''

				if (c) {
					if (c.CustomerStatusID === CustomerStatus.ACTIVE) {
						_customerStatus = 'Active'
					} else {
						_customerStatus = 'Not Active'
					}

					if (c.CustomerPartnerReference != null) {
						_customerPartnerReference = c.CustomerPartnerReference
					}
				}

				var _customerObj: CustomerListDisplay = {
					CustomerID: c.CustomerID + '',
					CustomerName: c.CustomerName + '',
					CustomerPartner: c.CustomerPartner?.CustomerPartnerName + '',
					CustomerPartnerReference: _customerPartnerReference + '',
					CustomerStatus: _customerStatus + '',
				}
				//Auth0 Test Customer - not sure why this is here?
				if (_customerObj.CustomerID !== '7DK414') {
					_customerDetails.push(_customerObj)
				}
			})
			setCustomerRows(_customerDetails)
		}
	}, [customerList])

	const customerColumns: GridColDef[] = [
		{
			field: 'CustomerID',
			headerName: 'Customer ID',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerName',
			headerName: 'Customer Name',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerStatus',
			headerName: 'Customer Status',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerPartnerReference',
			headerName: 'BizOrg',
			width: 250,
			hideable: false,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			cellClassName: 'actions',
			headerName: 'Manage',
			width: 50,
			editable: false,
			align: 'center',
			hideable: false,
			flex: 1,
			getActions: ({ id }) => {
				return [
					<>
						<Box className='customer-management-option'>
							<Tooltip title='Customer Overview'>
								<IconButton
									onClick={() => {
										handleCustomerOverviewNavigate(id + '')
									}}>
									<PageviewIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</>,
				]
			},
		},
	]

	const handleCustomerOverviewNavigate = (id: string) => {
		var customerID = id
		dispatch(setCustomerID(customerID))
		navigate(`/dashboard/customer-overview/${customerID}`)
	}

	return (
		<>
			<Box id='customer-list'>
				{customerRows.length > 0 ? (
					<StyledDataGrid
						rows={customerRows}
						columns={customerColumns}
						editMode='row'
						checkboxSelection={false}
						pagination
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						pageSizeOptions={[10]}
						slots={{
							pagination: CustomPagination,
							toolbar: GridToolbar,
						}}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
						getRowId={(row) => row.CustomerID}
						autoHeight
						rowSelection={false}
					/>
				) : null}
			</Box>
		</>
	)
}

export default CustomerDisplay
