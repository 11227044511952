import { Box, Tab } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { setPageTitle } from '../../store/reducers/reducers'
import { StyledTabPanel } from '../../styles/styledComponents/displays/StyledTabPanel'
import { StyledTabs } from '../../styles/styledComponents/displays/StyledTabs'
import PortalUserManagement from '../../components/distinct/settings/PortalUserManagement/PortalUserManagement'
import CommunicationSettings from '../../components/distinct/settings/CommunicationSettings/CommunicationSettings'
import './Settings.scss'

function Settings() {
	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)

	// Tab constants
	const [tabValue, setTabValue] = useState(0)

	// Handle tab value change
	const handleTabChange = (event: React.SyntheticEvent, newvalue: number) => {
		setTabValue(newvalue)
	}

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (pageTitle !== 'Settings') {
			dispatch(setPageTitle('Settings'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box className='settings-container'>
				{/* Tabbed Layout */}
				<StyledTabs value={tabValue} onChange={handleTabChange}>
					<Tab label='Portal Users' />
					<Tab label='Communication' />
				</StyledTabs>
				<StyledTabPanel index={0} value={tabValue}>
					{/* Tab 1 - Portal User Management */}
					<PortalUserManagement />
				</StyledTabPanel>
				<StyledTabPanel index={1} value={tabValue}>
					{/* Tab 2 - Communication settings */}
					<CommunicationSettings />
				</StyledTabPanel>
			</Box>
		</>
	)
}

export default Settings
