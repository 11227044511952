import {
	Box,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	Tooltip,
	Typography,
} from '@mui/material'
import { RoleCommunicationEmails } from '../../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import {
	ContactDetailTypes,
	FormChangeStatus,
} from '../../../../../utils/enums/enums'
import { ContactDetail } from '../../../../../utils/interfaces/DBModels'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { StyledDialog } from '../../../../../styles/styledComponents/displays/StyledDialog'
import { timeout } from '../../../../../utils/helperFunctions/helperFunctions'
import { StyledButton } from '../../../../../styles/styledComponents/inputs/StyledButton'

const RoleCommunicationSettings = ({
	roleCommunicationList,
	contactDetailList,
	modifyRoleEmails,
	deleteRoleEmail,
	deleteAllRoleEmails,
}: {
	roleCommunicationList: RoleCommunicationEmails[]
	contactDetailList: ContactDetail[]
	modifyRoleEmails: (emailList: RoleCommunicationEmails[]) => Promise<void>
	deleteRoleEmail: (roleID: number) => Promise<void>
	deleteAllRoleEmails: () => Promise<void>
}) => {
	// Display constants
	const [roleEmailList, setRoleEmailList] = useState(
		[] as RoleCommunicationEmails[]
	)
	const [dialogDescription, setDialogDescription] = useState('')

	// Logic constants
	const [roleIDEmailToDelete, setRoleIDEmailToDelete] = useState(0)
	const [openDialog, setOpenDialog] = useState(false)
	const [modifyLoading, setModifyLoading] = useState(false)
	const [deleteAllEmails, setDeleteAllEmails] = useState(false)

	useEffect(() => {
		// Set initial list
		setRoleEmailList(roleCommunicationList)
	}, [roleCommunicationList])

	// Handle functions
	// Delete of email, set roleID to delete and open dialog
	const handleDeleteRoleEmail = (roleID: number) => {
		setRoleIDEmailToDelete(roleID)
		setDialogDescription(
			`Are you sure you wish to delete the ${ContactDetailTypes[roleID]} email address?`
		)
		setOpenDialog(true)
	}

	// Handle modify emails
	const handleModifyEmails = async () => {
		setModifyLoading(true)
		await modifyRoleEmails(roleEmailList)
		setModifyLoading(false)
	}

	// Handle delete email
	const handleDeleteEmail = async () => {
		if (roleIDEmailToDelete > 0) {
			await deleteRoleEmail(roleIDEmailToDelete)
		}
		if (deleteAllEmails) {
			await deleteAllRoleEmails()
		}
		handleClearDelete()
	}

	// Clear delete constants after delete or cancel
	const handleClearDelete = async () => {
		setOpenDialog(false)
		await timeout(500)
		setRoleIDEmailToDelete(0)
		setDialogDescription('')
		setDeleteAllEmails(false)
	}

	// Handle change of email per role
	const handleEmailChange = (roleID: number, value: string) => {
		// Check for role and if different then set isChanged to true for tracking of icon buttons
		var emailChangeStatus = 0
		if (value.length > 0) {
			if (
				contactDetailList.find(
					(c) =>
						Number(c.ContactDetailTypeID) === roleID &&
						c.ContactPersonEmail !== value
				)
			) {
				emailChangeStatus = FormChangeStatus.UpdatedValue
			} else {
				emailChangeStatus = FormChangeStatus.NewValue
			}
		}

		// Set new value
		setRoleEmailList((prev) =>
			prev.map((item) =>
				Number(item.RoleID) === roleID
					? { ...item, Email: value, ChangeStatus: emailChangeStatus }
					: item
			)
		)
	}

	// Handle revert change
	const handleRevertChange = (roleID: number) => {
		// Check if new or updated
		var roleFound = roleEmailList.find((r) => Number(r.RoleID) === roleID)
		if (roleFound) {
			// Get old record and revert change
			var oldRecord = contactDetailList.find(
				(c) => Number(c.ContactDetailTypeID) === roleID
			)
			if (oldRecord) {
				// Set old value from DB
				setRoleEmailList((prev) =>
					prev.map((item) =>
						Number(item.RoleID) === roleID
							? {
									...item,
									Email: oldRecord?.ContactPersonEmail,
									ChangeStatus: FormChangeStatus.NoChange,
							  }
							: item
					)
				)
			} else {
				// Clear value
				setRoleEmailList((prev) =>
					prev.map((item) =>
						Number(item.RoleID) === roleID
							? {
									...item,
									Email: '',
									ChangeStatus: FormChangeStatus.NoChange,
							  }
							: item
					)
				)
			}
		}
	}

	// Handle delete all emails
	const handleDeleteAllEmails = () => {
		setRoleIDEmailToDelete(0)
		setDeleteAllEmails(true)
		setDialogDescription(
			`Are you sure you wish to delete ALL the email addresses?`
		)
		setOpenDialog(true)
	}

	// Handle disable of button
	const disableButton = () => {
		var disableButton = true

		// Check both lists to see if anything has changed
		for (let e = 0; e < roleEmailList.length; e++) {
			// Check if new email added or existing email updated
			var email = roleEmailList[e].Email
			if (email && email.length > 0) {
				var roleEmailFound = contactDetailList.find(
					(c) =>
						Number(c.ContactDetailTypeID) === Number(roleEmailList[e].RoleID)
				)
				if (roleEmailFound) {
					// Existing email
					if (roleEmailFound.ContactPersonEmail !== roleEmailList[e].Email) {
						disableButton = false
					}
				} else {
					// If adding a new one
					disableButton = false
				}
			}
		}

		return disableButton
	}

	return (
		<>
			{/* Dialog for deleting */}
			<StyledDialog
				open={openDialog}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Delete Email Address</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{dialogDescription}
					</DialogContentText>
				</DialogContent>
				<Divider />
				<DialogActions>
					<StyledButton
						variant='contained'
						onClick={handleClearDelete}
						className='cancel'>
						Cancel
					</StyledButton>
					<StyledButton
						variant='contained'
						onClick={handleDeleteEmail}
						autoFocus>
						Confirm
					</StyledButton>
				</DialogActions>
			</StyledDialog>
			{/* Display */}
			<Box component='section'>
				{/* Header */}
				<Box className='s-header'>
					<Box className='s-text'>
						<Typography component='h4'>Role communication settings</Typography>
						<Typography component='p'>
							Set the e-mail address for specific role required communication.
						</Typography>
					</Box>
					<Box className='s-buttons'>
						<StyledLoadingButton
							disabled={disableButton()}
							loading={modifyLoading}
							onClick={handleModifyEmails}>
							Save Changes
						</StyledLoadingButton>
					</Box>
				</Box>
			</Box>
			{/* Role email communication settings table */}
			<Box className='s-table'>
				{/* Header */}
				<Box className='s-row head'>
					<Box className='s-cell'>
						<Typography component='h5'>Role Type</Typography>
					</Box>
					<Box className='s-cell'>
						<Typography component='h5'>Email Address</Typography>
					</Box>
					<Box className='s-cell'>
						<Tooltip
							title={
								roleEmailList.find((r) => r.Synced === true)
									? 'Delete all role emails'
									: 'No role emails to remove'
							}>
							<span>
								<IconButton
									disabled={!roleEmailList.find((r) => r.Synced === true)}
									aria-label='Delete All Role Email Addresses'
									onClick={handleDeleteAllEmails}>
									<DeleteSweepIcon />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
				{/* Array - View/Add/Delete role emails */}
				{roleEmailList.map((item, index) => (
					<>
						<Box className='s-row'>
							{/* Role Type */}
							<Box className='s-cell r'>
								<SupervisorAccountIcon />
								<Typography key={index}>
									{ContactDetailTypes[Number(item.RoleID)]}
								</Typography>
							</Box>
							{/* Text field for email */}
							<Box className='s-cell'>
								<StyledTextBox
									key={index}
									fullWidth
									value={item.Email}
									onChange={(e) =>
										handleEmailChange(Number(item.RoleID), e.target.value)
									}
									InputProps={{
										endAdornment: item.Email && item.Email.length > 0 && (
											<InputAdornment position='end'>
												{Number(item.ChangeStatus) > 0 && (
													<Tooltip title='Revert changes'>
														<IconButton
															aria-label='Revert Email Address Changes'
															onClick={() =>
																handleRevertChange(Number(item.RoleID))
															}>
															{Number(item.ChangeStatus) === 1 ? (
																<ClearIcon />
															) : (
																<ReplayOutlinedIcon />
															)}
														</IconButton>
													</Tooltip>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box className='s-cell'>
								{item.Synced && (
									<Tooltip
										title={`Delete ${
											ContactDetailTypes[Number(item.RoleID)]
										} Email Address`}>
										<IconButton
											aria-label='Delete Role Email Address'
											onClick={() =>
												handleDeleteRoleEmail(Number(item.RoleID))
											}>
											<DeleteOutlineOutlinedIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						</Box>
						<Divider />
					</>
				))}
			</Box>
		</>
	)
}

export default RoleCommunicationSettings
