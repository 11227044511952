import React, { useEffect, useState } from 'react'
import { StyledTextBox } from '../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import {
	Alert,
	Box,
	FormControlLabel,
	Link,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PostAndRetrieveDataHook from '../../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	CustomerPartners,
	RadioValues,
	Roles,
} from '../../../../../../utils/enums/enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import { Customer } from '../../../../../../utils/interfaces/DBModels'
import { showInfoToast } from '../../../../../../utils/helperFunctions/helperFunctions'

function InviteMember({
	handleSendInvite,
}: {
	handleSendInvite: (email: string, customerID: string, role: string) => void
}) {
	//Flags
	const [btnLoading, setBtnLoading] = useState(false)
	const [apiCallDone, setAPICallDone] = useState(false)
	const [linkCustomer, setLinkCustomer] = useState(false)

	//Consts
	const [email, setEmail] = useState('')
	const [customerID, setCustomerID] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [radioValue, setRadioValue] = useState(RadioValues.PartnerAdmin)
	const [customersList, setCustomersList] = useState([] as Customer[])
	const customerRoleID = process.env.REACT_APP_CUSTOMER_ADMIN_ROLE_ID
	const partnerRoleID = process.env.REACT_APP_PARTNER_ADMIN_ROLE_ID

	//Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	//Redux
	const loggedInUserRole = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const isPartnerAdmin = loggedInUserRole.find(
		(role) => Number(role.RoleID) === Roles.PartnerAdmin
	)
	const isCustomerAdmin = loggedInUserRole.find(
		(role) => Number(role.RoleID) === Roles.CustomerAdmin
	)

	useEffect(() => {
		if (!apiCallDone && isPartnerAdmin) {
			getCustomers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiCallDone])

	const getCustomers = async () => {
		var dataResponse = await postAndRetrieveDataFromDB(
			'get Customer Details',
			`Customer.Where(Customer.CustomerPartnerID = '${CustomerPartners.Lumen}')`,
			false,
			true
		)
		if (dataResponse && dataResponse.Obj.CustomerList) {
			var _customerList = dataResponse.Obj.CustomerList as Customer[]
			_customerList.sort((a, b) => {
				const nameA = a.CustomerName + ''.toUpperCase()
				const nameB = b.CustomerName + ''.toUpperCase()
				if (nameA < nameB) {
					return -1
				}
				if (nameA > nameB) {
					return 1
				}
				return 0
			})
			setCustomerName(_customerList[0].CustomerName + '')
			setCustomersList(_customerList)
		}
		setAPICallDone(true)
	}

	const handleButton = async () => {
		setBtnLoading(true)
		if (
			isPartnerAdmin &&
			radioValue === RadioValues.CustomerAdmin &&
			!linkCustomer
		) {
			//Check if the customer email already has a customer ID
			var dataResponse = await postAndRetrieveDataFromDB(
				'get Customer ID',
				`ContactDetail.ContactDetailMap.Customer.Where(ContactDetail.ContactPersonEmail = '${email}' & Customer.CustomerPartnerID = '${CustomerPartners.Lumen}')`,
				false,
				true
			)
			var dataResponseCount = dataResponse?.Count ?? 0
			if (dataResponse?.Obj && dataResponseCount > 0) {
				var _customer = dataResponse.Obj.ContactDetailList[0]
					.ContactDetailMapList[0].Customer as Customer
				await handleSendInvite(
					email,
					_customer.CustomerID + '',
					customerRoleID + ''
				)
				setBtnLoading(false)
				return
			} else {
				setLinkCustomer(true)
				setBtnLoading(false)
				showInfoToast('Link email to a customer')
				return
			}
		}

		var role = ''
		if (isPartnerAdmin && radioValue === RadioValues.PartnerAdmin) {
			role = partnerRoleID + ''
		} else {
			role = customerRoleID + ''
		}

		var _customerID = customerID
		if (isCustomerAdmin) {
			_customerID = loggedInUser.customerID
		}

		await handleSendInvite(email, _customerID, role)
		setBtnLoading(false)
		setLinkCustomer(false)
	}

	return (
		<>
			<Box className='portal-um-form'>
				{isPartnerAdmin && (
					<>
						<RadioGroup
							row
							aria-labelledby='demo-row-radio-buttons-group-label'
							name='row-radio-buttons-group'
							value={radioValue}
							onChange={(e) =>
								setRadioValue(
									e.target.value === RadioValues.PartnerAdmin
										? RadioValues.PartnerAdmin
										: RadioValues.CustomerAdmin
								)
							}>
							<FormControlLabel
								disabled={btnLoading || linkCustomer}
								value={RadioValues.PartnerAdmin}
								control={<Radio />}
								label='Partner'
							/>
							<FormControlLabel
								disabled={btnLoading}
								value={RadioValues.CustomerAdmin}
								control={<Radio />}
								label='Customer'
							/>
						</RadioGroup>
						{radioValue === RadioValues.CustomerAdmin && (
							<>
								{linkCustomer && (
									<>
										<Alert severity='info'>
											We're sorry, but it looks like the email you provided is
											not associated with any of our customers. Please select a
											customer to link it with and then send the invitation.{' '}
											<Link
												component='button'
												variant='body2'
												onClick={() => setLinkCustomer(false)}>
												Cancel
											</Link>
										</Alert>
										<Select
											sx={{ padding: 0 }}
											variant='outlined'
											className='form-input-select'
											value={customerName}
											onChange={(e) => {
												setCustomerName(e.target.value)
											}}>
											{customersList.map((item) => {
												return (
													<MenuItem
														onClick={() => {
															setCustomerID(item.CustomerID + '')
														}}
														key={item.CustomerID}
														value={item.CustomerName}>
														{item.CustomerName}
													</MenuItem>
												)
											})}
										</Select>
									</>
								)}
							</>
						)}
					</>
				)}
				<StyledTextBox
					helperText='Enter the email to send an invite to'
					onChange={(e) => {
						setEmail(e.target.value)
					}}
					disabled={linkCustomer || btnLoading}
					fullWidth
					label='Email'
					variant='outlined'
					value={email}
					id='outlined-error-helper-text'
				/>
				<LoadingButton
					loading={btnLoading}
					onClick={handleButton}
					variant='contained'>
					Send Invite
				</LoadingButton>
			</Box>
		</>
	)
}
export default InviteMember
