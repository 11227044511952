import { useEffect, useRef, useState } from 'react'
import {
	AddressMap,
	Country,
} from '../../../../../../utils/interfaces/DBModels'
import {
	DDIAddressDisplayList,
	DDIListResponse,
} from '../../../../../../utils/interfaces/APIModels'
import PostAndRetrieveDataHook from '../../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Box,
	Divider,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
	CustomPagination,
	StyledDataGrid,
} from '../../../../../shared/datagrid/StyledDataGrid'
import './AssignTNAddresses.scss'
import TNAddressUpdate from './TNAddressUpdate/TNAddressUpdate'
import StyledModal from '../../../../../shared/styledComponents/StyledModal/StyledModal'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { InfoAlert } from '../../../../../../utils/constants/constants'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router'
import { GridColDef } from '@mui/x-data-grid-pro'

const AssignTNAddresses = ({
	customerID,
	orderSKUID,
	ddiRangeID,
	ddi,
	handleCloseMenuAndModal,
}: {
	customerID?: string
	orderSKUID?: number
	ddiRangeID?: number
	ddi?: string
	handleCloseMenuAndModal: (shouldReloadData: boolean) => void
}) => {
	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	//Assign TN Addresses
	const [assignTNAddressesRows, setAssignTNAdressesRows] = useState(
		[] as DDIAddressDisplayList[]
	)
	const [customerAddressList, setCustomerAddressList] = useState(
		[] as AddressMap[]
	)

	const [dataRetrieved, setDataRetrieved] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	})

	const selectedDDIAddress = useRef<DDIAddressDisplayList>({})
	const addressesInDDIRangeCountry = useRef<Set<AddressMap>>(new Set())

	// General
	const navigate = useNavigate()

	useEffect(() => {
		if ((orderSKUID && orderSKUID > 0) || (ddiRangeID && ddiRangeID > 0)) {
			fetchRangesforOrderSKUID()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const ddiColumns: GridColDef[] = [
		{
			field: 'DDIRangeID',
			headerName: '',
			width: 100,
			hideable: false,
			flex: 2,
		},
		{
			field: 'DDIRangeStart',
			headerName: 'Number Start',
			width: 100,
			hideable: false,
			flex: 1,
		},
		{
			field: 'DDIRangeEnd',
			headerName: 'Number End',
			width: 100,
			hideable: false,
			flex: 1,
		},
		{
			field: 'CountryName',
			headerName: 'Country',
			width: 100,
			hideable: false,
			flex: 1,
		},
		{
			field: 'Action',
			headerName: 'Action',
			width: 100,
			hideable: false,
			align: 'center',
			flex: 0.5,
			renderCell: (params) => (
				<>
					{hasAddresses(params.row) ? (
						<IconButton
							id='table-button'
							className='icon-button'
							onClick={(e: any) => {
								getAddresses(params.row)
							}}>
							<SettingsOutlinedIcon color='success' />
						</IconButton>
					) : (
						<Tooltip title='Please add an address where these DDIs were created in.'>
							<DoNotDisturbIcon />
						</Tooltip>
					)}
				</>
			),
		},
	]

	if (ddi) {
		ddiColumns.splice(1, 0, {
			field: 'DDI',
			headerName: 'Number',
			width: 150,
			hideable: false,
			flex: 1,
		})
	}

	//Make API call to get all the ddis for that specific order sku
	const fetchRangesforOrderSKUID = async () => {
		// Base URL
		var requestURL = `AddressMap.Address.State.Country.Includes(AddressMap.CustomerID='${customerID}'), DDIRange.DDI.Address.State.Country.Includes(DDIRange.CustomerID ='${customerID}'`
		if (orderSKUID && orderSKUID > 0) {
			requestURL += ` & DDIRange.OrderSKUID = '${orderSKUID}')`
		}

		if (ddiRangeID && ddiRangeID > 0) {
			requestURL += ` & DDIRange.DDIRangeID = '${ddiRangeID}')`
		}

		var dataResponse = await postAndRetrieveDataFromDB(
			'DDI Ranges List',
			requestURL,
			false,
			false
		)

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var ddirangeList = dataResponse.Obj.DDIRangeList as any
			//list of all addresses linked to customer
			var addressMapList = dataResponse.Obj.AddressMapList as AddressMap[]
			//list to store all address id's linked to that ddis in each range
			var addressIDsDDIRangeList = [] as any
			//empty list for display purposes
			var tnAddressDisplayList = [] as DDIAddressDisplayList[]
			//loop through list
			for (var i = 0; i < ddirangeList.length; i++) {
				var ddilist = ddirangeList[i].DDIList
				if (ddilist.length > 0) {
					// Find the first item in addressMapList that has a matching AddressID in ddirlist
					const _addressMap: AddressMap | undefined = addressMapList.find(
						(i: AddressMap) =>
							ddilist.some((e: DDIListResponse) => e.AddressID === i.AddressID)
					)

					addressIDsDDIRangeList.push({
						AddressID: _addressMap?.AddressID ?? undefined,
						AddressName:
							_addressMap?.Address?.AddressLine1 !== undefined
								? _addressMap?.AddressName !== null
									? `${_addressMap?.AddressName}, ${_addressMap?.Address?.AddressLine1}`
									: _addressMap?.Address?.AddressLine1
								: 'Awaiting Address',
					})
				}
			}
			//combine the lists
			tnAddressDisplayList = ddirangeList.map((item: any, index: any) => {
				var addressName = addressIDsDDIRangeList[index]
					? addressIDsDDIRangeList[index].AddressName
					: 'Awaiting Address'
				return {
					DDIRangeID: item.DDIRangeID,
					DDI: ddi,
					DDIRangeStart: item.DDIRangeStart,
					DDIRangeEnd: item.DDIRangeEnd,
					CountryName: item.DDIList[0]?.Address.State.Country.CountryName,
					TNLocation: addressName,
					DDIList: item.DDIList,
				}
			})

			setAssignTNAdressesRows(tnAddressDisplayList)
			setCustomerAddressList(addressMapList)
		}
		setDataRetrieved(true)
	}

	const handleOpenModal = () => {
		setOpenModal(true)
	}

	const handleCloseModal = (hasAnAddressBeenAssigned: boolean) => {
		addressesInDDIRangeCountry.current.clear()
		fetchRangesforOrderSKUID()
		handleCloseMenuAndModal(hasAnAddressBeenAssigned)
		setOpenModal(false)
	}

	const hasAddresses = (params: any): boolean => {
		const ddiRangeCountry: Country = params?.DDIList[0].Address.State.Country
		if (ddiRangeCountry !== null) {
			return customerAddressList.some(
				(address: AddressMap) =>
					address.Address?.State?.Country?.CountryName ===
					ddiRangeCountry.CountryName
			)
		}

		return false
	}

	const getAddresses = (params: any) => {
		const ddiRangeCountryID: number = Number(
			params?.DDIList[0].Address.State.Country.CountryID
		)
		if (ddiRangeCountryID) {
			customerAddressList.forEach((address: AddressMap) => {
				if (
					Number(address.Address?.State?.Country?.CountryID) ===
					ddiRangeCountryID
				) {
					if (!addressesInDDIRangeCountry.current.has(address)) {
						addressesInDDIRangeCountry.current.add(address)
					}
				}
			})
		}

		if (addressesInDDIRangeCountry.current.size > 0) {
			selectedDDIAddress.current = params
			handleOpenModal()
		}
	}

	return dataRetrieved ? (
		<>
			<Box className='tn-address-container'>
				{/* Heading */}
				<Box className='tn-address-header'>
					<img
						src={require('../../../../../../assets/icons/addTNs/AddTNsIcon.png')}
						alt='Add Numbers Icon'
					/>
					<Typography
						id='order-address-header'
						component='h1'
						style={{ fontSize: '25px' }}>
						Number Location Overview
						<Typography component='h4' style={{ fontWeight: '600' }}>
							{assignTNAddressesRows.length > 0
								? 'You can assign locations here:'
								: 'Cannot Update Number Location:'}
						</Typography>
					</Typography>
					<Box className='modal-close-button'>
						<IconButton onClick={() => handleCloseModal(false)}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				<Divider />
				<Box className='tn-address-content'>
					{assignTNAddressesRows.length > 0 ? (
						<StyledDataGrid
							sx={{
								'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
									display: 'none',
								},
							}}
							initialState={{
								columns: {
									columnVisibilityModel: {
										// Hide DDIRangeID
										DDIRangeID: false,
									},
								},
							}}
							columns={ddiColumns}
							rows={assignTNAddressesRows}
							getRowId={(row) => row.DDIRangeID}
							autoHeight
							pagination
							paginationModel={paginationModel}
							onPaginationModelChange={setPaginationModel}
							pageSizeOptions={[7]}
							slots={{
								pagination: CustomPagination,
							}}
							rowSelection={false}
						/>
					) : (
						<>
							{/* Center message */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: 2,
								}}>
								<InfoAlert severity='info'>
									No Number Addresses found. Please go to Addresses to add an
									Address.
								</InfoAlert>
							</Box>
							<Divider />
							<Box sx={{ marginTop: 3 }} className='address-form-footer'>
								<LoadingButton
									variant='outlined'
									onClick={() => {
										navigate('/AddressManagement')
									}}>
									Address
								</LoadingButton>
							</Box>
						</>
					)}
				</Box>
				{/* Modal to update tn location */}
				<Modal open={openModal}>
					<StyledModal width={1000}>
						<TNAddressUpdate
							customerAddressList={Array.from(
								addressesInDDIRangeCountry.current
							)}
							selectedDDIAddress={selectedDDIAddress.current}
							handleCloseModal={handleCloseModal}
						/>
					</StyledModal>
				</Modal>
			</Box>
		</>
	) : (
		<Typography component='p'>Loading... Please wait.</Typography>
	)
}

export default AssignTNAddresses
