import { Box, Typography } from '@mui/material'
import './ViewOrderNotes.scss'
import { StyledTextArea } from '../../../../../shared/styledComponents/StyledTextArea/StyledTextArea'

const ViewOrderNotes = ({ orderNotes }: { orderNotes: string }) => {
	return (
		<>
			<Box className='view-order-notes-container'>
				{/* Header */}
				<Box className='view-order-notes-header'>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Order Notes</Typography>
						{/* Description */}
						<Typography component='p'>Additional Order Information</Typography>
					</Box>
				</Box>
				{/* Order Notes */}
				{orderNotes && (
					<Box sx={{ display: 'flex', rowGap: '5px', flexDirection: 'column' }}>
						<Typography component='p'>Order Notes </Typography>
						<StyledTextArea
							maxRows={10}
							minRows={10}
							value={orderNotes}
							disabled
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default ViewOrderNotes
