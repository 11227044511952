import { useEffect, useRef, useState } from 'react'
import {
	DDIAddressDisplayList,
	DDIAddressUpdateModel,
	DDIListResponse,
} from '../../../../../../../utils/interfaces/APIModels'
import { AddressMap } from '../../../../../../../utils/interfaces/DBModels'
import {
	Alert,
	Box,
	Divider,
	IconButton,
	MenuItem,
	Tab,
	Tooltip,
	Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './TNAddressUpdate.scss'
import { StyledSelect } from '../../../../../../shared/styledComponents/StyledSelect/StyledSelect'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { StyledTextBox } from '../../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import UseCrud from '../../../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DDIStatuses } from '../../../../../../../utils/enums/enums'

const TNAddressUpdate = ({
	customerAddressList,
	selectedDDIAddress,
	handleCloseModal,
}: {
	customerAddressList: AddressMap[]
	selectedDDIAddress: DDIAddressDisplayList
	handleCloseModal: (hasAnAddressBeenAssigned: boolean) => void
}) => {
	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	// Hooks
	const { modifyData } = UseCrud()

	const [addressMapID, setAddressMapID] = useState(0)
	const [tabValue, setTabValue] = useState<string>('Number Ranges')
	const [subHeading, setSubHeading] = useState<string>('')
	const [disableAssignAddress, setDisableAssignAddress] = useState(true)
	const [disableNumberTab, setDisableNumberTab] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const originalLocation = useRef('')
	const addressID = useRef<number>(0)
	const ddiAddress = useRef({} as DDIAddressDisplayList)

	useEffect(() => {
		if (selectedDDIAddress) {
			ddiAddress.current = selectedDDIAddress
			setDisableNumberTab(selectedDDIAddress.DDI === undefined)
			setDDIPreAssignedLocation('Number Ranges')
			setSubHeading(
				'Assign an address that will be applied to the entire range'
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getDDILocation = () => {
		var ddiLocation: string | undefined = ''
		if (selectedDDIAddress) {
			ddiLocation = selectedDDIAddress.TNLocation?.includes(',')
				? selectedDDIAddress.TNLocation.split(',')[1].trim()
				: selectedDDIAddress.TNLocation
		}

		return ddiLocation
	}

	const setDDIPreAssignedLocation = (_tabValue: string) => {
		var addressMap: AddressMap | undefined = undefined

		if (_tabValue === 'Number') {
			const ddiList: DDIListResponse | undefined = getMatchingDDI()
			addressMap = getAddressMapByAddressID(Number(ddiList?.AddressID))
		}

		if (_tabValue === 'Number Ranges') {
			const _ddiLocation = getDDILocation()
			originalLocation.current = _ddiLocation ?? ''
			addressMap = getAddressMapByLocation(_ddiLocation ?? '')
		}

		handleAddressChange(addressMap?.AddressMapID + '', _tabValue)
	}

	const getAddressMapByAddressMapID = (
		_addressMapID: number
	): AddressMap | undefined => {
		return customerAddressList.find(
			(addressMap: AddressMap) => addressMap.AddressMapID === _addressMapID
		)
	}

	const getAddressMapByAddressID = (
		_addressID: number
	): AddressMap | undefined => {
		return customerAddressList.find(
			(addressMap: AddressMap) => addressMap.AddressID === _addressID
		)
	}

	const getAddressMapByLocation = (
		_ddiLocation: string
	): AddressMap | undefined => {
		return customerAddressList.find(
			(addressMap: AddressMap) =>
				addressMap.Address?.AddressLine1 === _ddiLocation
		)
	}

	const handleAddressChange = (_selectedAddress: string, _tabValue: string) => {
		const addressMapID = Number(_selectedAddress)
		const addressMap = getAddressMapByAddressMapID(addressMapID)

		setAddressMapID(addressMapID)

		if (addressMap !== undefined) {
			if (_tabValue === 'Number') {
				const ddiList: DDIListResponse | undefined = getMatchingDDI()

				if (addressMap.Address?.AddressID === ddiList?.AddressID) {
					setDisableAssignAddress(true)
					return
				}
			}

			if (_tabValue === 'Number Ranges') {
				const _ddiList: DDIListResponse[] | undefined =
					ddiAddress.current.DDIList

				if (_ddiList && _ddiList.length > 0) {
					// get first AddressID in the list
					const _firstDDIAddressID = _ddiList[0].AddressID
					// Check if all DDIs in the list have the same address
					const numbersShareSameAddress = _ddiList.every(
						(_ddi) => _ddi.AddressID === _firstDDIAddressID
					)

					if (numbersShareSameAddress) {
						if (addressMap.Address?.AddressLine1 === originalLocation.current) {
							setDisableAssignAddress(true)
							return
						}
					}
				}
			}
		}

		if (addressMapID === 0) {
			setDisableAssignAddress(true)
			return
		}

		setDisableAssignAddress(false)
	}

	const handleAssignAddress = () => {
		const addressMap = getAddressMapByAddressMapID(addressMapID)
		addressID.current = Number(addressMap?.AddressID)

		if (addressMap?.AddressID) {
			setIsLoading(true)
			assignAddressToDDIRange()
		}
	}

	const assignAddressToDDIRange = async () => {
		const ddis: DDIAddressUpdateModel[] = []

		if (ddiAddress.current.DDIList !== undefined) {
			const ddiList: DDIListResponse[] = ddiAddress.current.DDIList

			ddiList.forEach((ddi: DDIListResponse) => {
				ddis.push({
					ID: ddi.ID,
					AddressID: addressID.current,
					DDIStatusID: DDIStatuses.Validation,
				})
			})
		}

		var ddiReqObj = {
			DDIList: ddis,
		}

		const dataResponse = (await modifyData({
			FileAndFunctionName: 'TNAddressUpdate.tsx assignAddressToDDIRange()',
			QueryURL: `UpdateV2?Params=DDI:list`,
			QueryObj: ddiReqObj,
			ErrorMessage: 'Could not add address to DDIRange',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
			SuccessMessage: 'Address added to DDI Range successfully',
			LogErrorToDB: false,
			UserName: loggedInUser.email,
		})) as Boolean

		if (dataResponse) {
			handleCloseModal(true)
		}

		setIsLoading(false)
	}

	const getMatchingDDI = (): DDIListResponse | undefined => {
		const selectedDDI: string = selectedDDIAddress.DDI ?? ''
		return selectedDDIAddress.DDIList?.find((ddi) => ddi.DDI === selectedDDI)
	}

	const handleTabChange = (event: React.SyntheticEvent, _tabValue: string) => {
		setTabValue(_tabValue)
		var _ddiAddress: DDIAddressDisplayList = JSON.parse(
			JSON.stringify(selectedDDIAddress)
		)

		if (_tabValue === 'Number') {
			const ddiList: DDIListResponse | undefined = getMatchingDDI()
			_ddiAddress.DDIList?.splice(0, _ddiAddress.DDIList.length)
			if (ddiList) {
				_ddiAddress.DDIList?.push(ddiList)
			}
			setSubHeading('Assign an address that will be applied to the number')
		} else {
			setSubHeading(
				'Assign an address that will be applied to the entire range'
			)
		}

		ddiAddress.current = _ddiAddress
		setDDIPreAssignedLocation(_tabValue)
	}

	return (
		<>
			<Box className='tn-address-update-container'>
				{/* Heading */}
				<Box className='tn-address-update-header'>
					<img
						src={require('../../../../../../../assets/icons/addTNs/AddTNsIcon.png')}
						alt='Add TNs Icon'
					/>
					<Typography
						id='order-address-header'
						component='h1'
						style={{ fontSize: '25px' }}>
						Add Number Location
						<Typography component='h4'>{subHeading}</Typography>
					</Typography>
					<Box className='modal-close-button'>
						<IconButton onClick={() => handleCloseModal(false)}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				<TabContext value={tabValue}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: 'divider',
						}}>
						<TabList
							onChange={handleTabChange}
							aria-label='lab API tabs example'>
							<Tab label='Number' value='Number' disabled={disableNumberTab} />
							<Tab label='Number Ranges' value='Number Ranges' />
						</TabList>
					</Box>
					<TabPanel value='Number' sx={{ margin: 0, padding: 0 }}>
						<Box className='tn-address-update-content'>
							{/* TN Start */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>Number:</Typography>
								<StyledTextBox
									fullWidth
									disabled
									className='input-field'
									variant='outlined'
									value={selectedDDIAddress.DDI}
									type='text'
									style={{ backgroundColor: 'white' }}
								/>
							</Box>

							{/* Country */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>Country:</Typography>
								<StyledTextBox
									fullWidth
									disabled
									className='input-field'
									variant='outlined'
									value={selectedDDIAddress.CountryName}
									type='text'
									style={{ backgroundColor: 'white' }}
								/>
							</Box>
							{/* Location */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>
									Location: <span style={{ color: 'red' }}>*</span>
								</Typography>
								<StyledSelect
									fullWidth
									required
									variant='outlined'
									className='input-field'
									type='text'
									onChange={(e) =>
										handleAddressChange(e.target.value as string, tabValue)
									}
									value={addressMapID}>
									<MenuItem disabled value={0}>
										Choose a TN location
									</MenuItem>
									{customerAddressList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.AddressMapID}>
												{item.AddressName
													? item.AddressName
													: item.Address?.AddressLine1}
											</MenuItem>
										)
									})}
								</StyledSelect>
							</Box>
						</Box>
					</TabPanel>
					{/* Number Ranges Tab */}
					<TabPanel value='Number Ranges' sx={{ margin: 0, padding: 0 }}>
						<Box className='tn-address-update-content'>
							{/* TN Start */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>Range Start:</Typography>
								<StyledTextBox
									fullWidth
									disabled
									className='input-field'
									variant='outlined'
									value={selectedDDIAddress.DDIRangeStart}
									type='text'
									style={{ backgroundColor: 'white' }}
								/>
							</Box>
							{/* TN End */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>Range End:</Typography>
								<StyledTextBox
									fullWidth
									disabled
									className='input-field'
									variant='outlined'
									value={selectedDDIAddress.DDIRangeEnd}
									type='text'
									style={{ backgroundColor: 'white' }}
								/>
							</Box>
							{/* Country */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>Country:</Typography>
								<StyledTextBox
									fullWidth
									disabled
									className='input-field'
									variant='outlined'
									value={selectedDDIAddress.CountryName}
									type='text'
									style={{ backgroundColor: 'white' }}
								/>
							</Box>
							{/* Location */}
							<Box className='tn-address-update-input'>
								<Typography component='p'>
									Location: <span style={{ color: 'red' }}>*</span>
								</Typography>
								<StyledSelect
									fullWidth
									required
									variant='outlined'
									className='input-field'
									type='text'
									onChange={(e) =>
										handleAddressChange(e.target.value as string, tabValue)
									}
									value={addressMapID}>
									<MenuItem disabled value={0}>
										Choose a TN location
									</MenuItem>
									{customerAddressList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.AddressMapID}>
												{item.AddressName
													? item.AddressName
													: item.Address?.AddressLine1}
											</MenuItem>
										)
									})}
								</StyledSelect>
							</Box>
						</Box>
					</TabPanel>
				</TabContext>

				{/* Footer */}
				<Divider />

				<Box className='tn-address-update-footer'>
					<>
						{!disableAssignAddress && (
							<Alert
								severity='info'
								className='alert-info'
								sx={{ width: 500, marginBottom: '10px' }}>
								{subHeading}
							</Alert>
						)}

						<Tooltip
							title={
								disableAssignAddress
									? 'Select a location to assign'
									: 'Assign Address'
							}>
							<span>
								<LoadingButton
									disabled={disableAssignAddress}
									loadingPosition='center'
									loading={isLoading}
									startIcon={<SaveIcon />}
									onClick={handleAssignAddress}>
									Assign Address
								</LoadingButton>
							</span>
						</Tooltip>
					</>
				</Box>
			</Box>
		</>
	)
}

export default TNAddressUpdate
