import { useEffect, useState } from 'react'
import PortalUserManagementDisplay from './PortalUserManagementDisplay/PortalUserManagementDisplay'
import {
	BetaObject,
	GetByIDRequestModel,
	Member,
} from '../../../../utils/interfaces/APIModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useGetUserManagementDataByIDMutation } from '../../../../services/proxyAPIData'
import {
	toAlphaString,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import Preloader from '../../../shared/loading/preloader/Preloader'
import { UserManagementType } from '../../../../utils/enums/enums'

const PortalUserManagement = () => {
	// Flags
	const [apiCallDone, setAPICallDone] = useState(false)

	// Hooks
	const [getMembersByOrgID] = useGetUserManagementDataByIDMutation()

	// Permissions
	const accessToken = useSelector(
		(state: RootState) => state.RootReducer.accessTokenReducer.value
	)

	const [memberList, setMemberList] = useState([] as Member[])

	// Functions
	const getMembers = async () => {
		var getMembersByOrgIDRequestModel: GetByIDRequestModel = {
			Type: UserManagementType.Members,
			ID: process.env.REACT_APP_ORGANIZATION_ID + '',
			AuthorizationToken: accessToken,
		}
		var betaObject: BetaObject = {
			Content: toBetaString(JSON.stringify(getMembersByOrgIDRequestModel)),
		}
		await getMembersByOrgID(betaObject)
			.unwrap()
			.then(async (usersDataResponse) => {
				if (usersDataResponse && usersDataResponse.Content) {
					var alphaText = await toAlphaString(usersDataResponse.Content + '')
					var members = JSON.parse(alphaText) as Member[]
					setMemberList(members)
				}
			})
		setAPICallDone(true)
	}

	useEffect(() => {
		if (!apiCallDone) {
			getMembers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiCallDone])

	return apiCallDone ? (
		<PortalUserManagementDisplay
			memberList={memberList}
			getMembers={getMembers}
		/>
	) : (
		<Preloader />
	)
}

export default PortalUserManagement
