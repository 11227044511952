import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
} from '@mui/x-data-grid'
import { Box, Button } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
	GridToolbarDensitySelector,
	GridToolbarExport,
} from '@mui/x-data-grid-pro'

const CustomToolbar = ({
	showFilter,
	onFilter,
	loading,
}: {
	showFilter: boolean
	onFilter?: any
	loading?: boolean
}) => {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarDensitySelector />
			{showFilter && (
				<Button
					disabled={loading}
					startIcon={<FilterListIcon />}
					onClick={onFilter}>
					Filters
				</Button>
			)}
			<GridToolbarExport />
			<Box sx={{ flexGrow: 1 }} />
		</GridToolbarContainer>
	)
}

export default CustomToolbar
