import { Alert, styled } from '@mui/material'

export const LumenAddressLine1 = 'Lumen'
export const LumenHouseNumber = '10'
export const LumenCity = ' '
export const LumenPostalCode = '1111'
export const USPositionInCountryList = '207'

export const GraphScopes = ['.default']
export const TeamsScopes = [
	'48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
	'offline_access',
	'openid',
	'profile',
	'Domain.ReadWrite.All',
]

export const LumenPartnerID = 2

// Action for BYOC - Manual one used for service bus
export const BYOCActionForServiceBus = 'AD-SER-INFO-NO-SBC'

export const TeamsScopesForTeamsUserMGT = [
	'48ac35b8-9aa8-4d74-927d-1f4a14a0b239/.default',
]

// Teams User MGT - Link to retrieve users
export const TeamsUsersBaseLink =
	'https://graph.microsoft.com/v1.0/users?$select=userPrincipalName,id,mail,assignedLicenses,displayName&$count=true&$top=10'

//info styling
export const InfoAlert = styled(Alert)({
	'& .MuiAlert-icon': {
		color: '#0075c9',
	},
	backgroundColor: '#f5f5f5',
	color: 'black',
	fontSize: '14px',
})

// Action management
export const BaseOCServiceProduct = 'BAS-MSTOC'
export const OCServiceInformationProduct = 'OC-SER'
export const InProgressProductActionStatus = 'IN-PROG'
export const CompletedProductActionStatus = 'COMP'
export const FailedProductActionStatus = 'FAIL'
export const DDIProduct = 'DDI'
