import { Box, Divider, Typography } from '@mui/material'
import { Modals } from '../../../../../../utils/enums/enums'
import { StyledTextBox } from '../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { OrderSKU } from '../../../../../../utils/interfaces/DBModels'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import './OrderSKUPriceUpdate.scss'

const OrderSKUPriceUpdate = ({
	formType,
	orderID,
	orderSKUID,
	skuTypeID,
	currentPriceValue,
	updateSKUPrice,
}: {
	formType: number
	orderID: string
	orderSKUID: number
	skuTypeID: string
	currentPriceValue: number
	updateSKUPrice: any
}) => {
	// Display constants
	const [price, setPrice] = useState<number>(currentPriceValue)

	// Flags
	const [loading, setLoading] = useState(false)

	// Handle functions
	// Handle update of price value
	const handleUpdateSKUPrice = async () => {
		// Start loading
		setLoading(true)

		// Check which price to be updated and build relevant object
		var updateOrderSKUObj: OrderSKU = {
			OrderSKUID: orderSKUID,
			IsPriceBookPricing: false,
		}
		switch (formType) {
			// Price/MRC
			case Modals.SKUPriceUpdate:
				updateOrderSKUObj.Price = Number(price)
				break
			//
			case Modals.SKUNRCUpdate:
				updateOrderSKUObj.NRC = Number(price)
				break
		}

		// Send object to be updated
		await updateSKUPrice(updateOrderSKUObj)

		// End loading
		setLoading(false)
	}

	// Handle change of price value
	const handlePriceChange = (e: { target: any }) => {
		var newValue = e.target.value
		if (newValue && newValue < 0) {
			return
		}
		setPrice(newValue)
	}

	return (
		<>
			<Box className='sku-price-container'>
				{/* Header */}
				<Box className='sku-price-header'>
					{/* Icon */}
					<Box className='header-icon'>
						<Box className='icon'>
							<PriceChangeIcon />
						</Box>
					</Box>
					{/* Text */}
					<Box className='header-text'>
						{/* Heading */}
						<Typography component='h2'>Update Order SKU Price</Typography>
						{/* Description */}
						<Typography component='p'>
							Manage and update your{' '}
							{formType === Modals.SKUPriceUpdate ? 'price' : 'NRC'} for this
							SKU.
						</Typography>
					</Box>
				</Box>
				{/* Display for Order ID and SKU Type ID */}
				<Box className='sku-price-order-details'>
					<Box className='sku-text'>
						{/* Order ID */}
						<Typography component='p'>
							<strong>Order ID:</strong> {orderID}
						</Typography>
						{/* SKU Type ID */}
						<Typography component='p'>
							<strong>Order SKU:</strong> {skuTypeID}
						</Typography>
					</Box>
				</Box>
				{/* Divider */}
				<Divider />
				{/* Price Update */}
				<Box className='sku-price-form'>
					{/* Label */}
					<Typography component='p'>
						{formType === Modals.SKUPriceUpdate ? 'Price' : 'NRC'}
					</Typography>
					{/* Textfield */}
					<StyledTextBox
						fullWidth
						onChange={handlePriceChange}
						type='number'
						value={price}
					/>
				</Box>
				{/* Confirm button */}
				<Box className='sku-price-btn'>
					<LoadingButton
						disabled={price < 0 || price === currentPriceValue}
						variant='contained'
						loading={loading}
						onClick={handleUpdateSKUPrice}>
						Update SKU {formType === Modals.SKUPriceUpdate ? 'Price' : 'NRC'}
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default OrderSKUPriceUpdate
