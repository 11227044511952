import { Box, Typography } from '@mui/material'
import './AddTN.scss'

const AddTNDocumentation = () => {
	return (
		<>
			<Box className='documentation-info-container'>
				<Typography component='h4'>
					How to Add Number Ranges to Trunk(s)
				</Typography>
				<Typography component='p'>
					Here we explain how to add Number Ranges.
				</Typography>
				<Typography component='p'>
					This process documents how to add Number range(s) from the "Orders
					Overview" table on the dashboard. The customers displayed on the table
					are all the customers of that partner. Please note that only Partner
					Admins can add trunks and numbers to a user permitted that it is not a
					trial order - must be a Teams or Voice Catch order.
				</Typography>
				<Typography component='p'>
					Each customer entry has an <i>"Update Order"</i> cog to the right
					which you can select to perform <br />
					three actions for that customer:
				</Typography>
				<Typography component='ol'>
					<Typography component='li'>add Number Range(s)</Typography>
					<Typography component='li'>add Trunk Group(s) and</Typography>
					<Typography component='li'>E911 Configuration</Typography>
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/addDDICog.png')}
					alt='Options cog selection'
					className='portrait'
					width='200px'
				/>
				<Typography component='p'>
					If you select to add a Number Range for a customer, you will be
					redirected to another page. There are two Number provisionings you can
					do, namely:
				</Typography>
				<Typography component='ol'>
					<Typography component='li'>CSV import; or</Typography>
					<Typography component='li'>manual bulk add</Typography>
				</Typography>
				<Typography component='p'>
					The CSV Import addition is set to be the default provisioning type.
					You have to change the settings as described below to follow the
					manual bulk add process.
				</Typography>
				<Typography component='h4'>How do I know which to use?</Typography>
				<Typography component='ul'>
					<Typography component='li'>
						The CSV import provisioning type is great for bulk adding numbers to
						one trunk.
					</Typography>
					<Typography component='li'>
						The manual bulk add provisioning type is great for bulk adding
						numbers to multiple trunks.
					</Typography>
				</Typography>
				<Typography component='p'>
					<u>CSV Import</u>
				</Typography>
				<Typography component='p'>
					Select the cog to the right of a user to add a number range.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/image1.png')}
					alt='Options cog selection'
					className='landscape'
					width='650px'
				/>
				<Typography component='p'>
					Once you have selected "Add TN", you will be redirected to another
					page.
				</Typography>
				<Typography component='p'>
					<b>First</b>, you have to make sure that the "CSV Import" switch is
					enabled at the top of the page.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/image3.png')}
					alt='Enable CSV Import'
					className='landscape'
					width='500px'
				/>
				<Typography component='p'>
					The CSV import will override all of the numbers that you currently
					have, depending on the selected "Carrier Description". <br />
					<b>Second</b>, you have to select a unique "Carrier Description" for
					the Number range to be created. The "Carrier Description" is in effect
					the name of a Trunk.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/image4.png')}
					alt='Carrier Description Dropdown'
					className='landscape'
					width='600px'
				/>
				<Typography component='p'>
					<b>Third</b>, you can choose a country for the numbers to operate in.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/image5.png')}
					alt='Country Dropdown'
					className='landscape'
					width='500px'
				/>
				<Typography component='p'>
					<b>Fourth</b>, you can upload a CSV file by clicking on the "Choose
					File" button.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/fourth.png')}
					alt='Upload CSV Sample File'
					className='landscape'
					width='600px'
				/>
				<Typography component='p'>
					If you are unsure of the format the CSV file should have, there is a
					link to a "CSV Sample File" above the "Choose File" button that you
					can download and view.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/AddDDI/image6.png')}
						alt='CSV Sample File'
						width='200px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/AddDDI/image7.png')}
						alt='View of CSV Sample File'
					/>
				</Box>
				<Typography component='p'>
					A confirmation message will appear in the top right corner of the
					screen to indicate whether the CSV file has been imported
					successfully.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/DDIaddedSuccessfully.png')}
					alt='Number success message'
					className='landscape'
					width='400px'
				/>
				<Typography component='p'>
					Once you have imported a CSV file, a button will appear below the file
					which you have to click to validate that CSV file.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/validateCSV.png')}
					alt='Validate CSV file button'
					className='landscape'
					width='500px'
				/>
				<Typography component='p'>
					When you click on "Validate CSV File", it runs a few background checks
					to ensure that the numbers are valid, no more than 15 numbers are
					entered without a dialling code , and that the numbers in this range
					are not already being used by another customer. Error messages will
					appear to indicate how the problem can be fixed.
				</Typography>
				<Box className='two-image-layout'>
					<img
						src={require('../../../../../assets/images/documentation/AddDDI/invalidFileType.png')}
						alt='Invalid file type error message'
						width='350px'
					/>
					<img
						src={require('../../../../../assets/images/documentation/AddDDI/validateFailure.png')}
						alt='Validation error message'
						width='350px'
					/>
				</Box>
				<Typography component='p'>
					A table will appear to display the ranges that have been validated.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/tableNoMsg.png')}
					alt='Ranges table overview'
					className='landscape'
					width='650px'
				/>
				<Typography component='p'>
					Once the CSV file has been validated, a message will appear to
					stipulate what will happen and if previous numbers of the selected
					carrier description will be replaced with the newly imported numbers
					from the CSV file. If there are no existing numbers under the selected
					carrier description, it will proceed to add the Number range as a
					normal import.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/DDITable.png')}
					alt='Number Table'
					className='landscape'
					width='650px'
				/>
				<Typography component='p'>
					Once you are satisfied with the range that you have imported, you can
					select "Confirm and Create Ranges" to officially add them to the
					database.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/confirmbuttons.png')}
					alt='Confirm and Create Ranges Buttons'
					className='portrait'
				/>
				<Typography component='p'>
					You will be redirected to the Numbers page. A pop-up message will
					appear on the top-right corner of the page indicating whether the
					Number Range(s) has been added successfully or not. If not, an
					explanation for such failure will be given in the pop-up error
					message.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/DDIaddedSuccessfully.png')}
					alt='Number success message'
					className='landscape'
					width='400px'
				/>
				<Typography component='p'>
					If the Number Range has been added successfully, it will be present in
					the Numbers table, where you can scroll to find it, or search for the
					specific number(s) using the search bar above the top-right corner of
					the table.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/DDIManagement.png')}
					alt='Numbers page'
					className='landscape'
				/>
				<Typography component='u'>Manual bulk addition</Typography>
				<Typography component='p'>
					This type of provisioning allows you to bulk add Number ranges to
					different trunks. To manually bulk add numbers to a range, flip the
					"CSV Export" switch to disabled, and select the "OK" button at the top
					asking to confirm whether you want to change the Number provisioning
					type.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/manualBulk1.png')}
					alt='Number Provisioning page'
					width='300px'
				/>
				<Typography component='p'>
					The screen display will change once the "CSV Export" switch has been
					disabled. To add a Number range, click on the "Add Number Range"
					button at the top of the screen.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/manualBulk2.png')}
					alt='Add Number Range button'
					className='landscape'
				/>
				<Typography component='p'>
					A pop-up will appear to the right of the page.{' '}
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/addDDIRangePage.png')}
					alt='Add Number Range page'
					className='portrait'
				/>
				<Typography component='p'>
					<b>First</b>, you have to select a unique "Carrier Description" for
					the Number range. In effect, the "Carrier Description" is the name of
					a Trunk.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/First1.png')}
					alt='Carrier Description textbox'
					className='landscape'
					width='350px'
				/>
				<Typography component='p'>
					<b>Second</b>, you have to enter the country to which the Number range
					has to be assigned to.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/Second1.png')}
					alt='Country dropdown selection'
					className='landscape'
					width='320px'
				/>
				<Typography component='p'>
					Based on the country that you have selected, the dialling code of said
					country will appear above the Number Range Start and -End entry
					fields.
				</Typography>
				<Typography component='p'>
					<b>Third</b>, you have to enter the Number Range Start number
					containing 9 digits - this number is excluding the dialling code. The
					same has to be done for the Number Range End field.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/Third.png')}
					alt='Range Start and End textboxes'
					className='portrait'
				/>
				<Typography component='p'>
					The Range will include both the Start and the End number. For example,
					if the range starts with ****50 ends with ****52, the range consists
					of three numbers (****50; ****51; ****52). Once you click on “Validate
					Range”, the Number range will be added to the table on the screen.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/validateBtns.png')}
					alt='Validate range buttons'
					className='portrait'
				/>
				<Typography component='p'>
					Although the range has been validated, it has not yet been added to
					the database when presented on the table. Thus far, if the validation
					was successful, the range has merely been created preliminarily.
				</Typography>
				<Typography component='p'>
					This process can be repeated to add as many ranges as you'd like.
				</Typography>
				<Typography component='p'>
					If a mistake was made during the addition of a new range(s), you can
					delete the numbers in the table presented once the range has been
					validated. A confirmation message will appear in the top right corner
					of the page to indicate whether the range has been validated
					successfully.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/deleteDDI.png')}
					alt='Delete Number Icon in Number provisioning table'
					className='landscape'
				/>
				<Typography component='p'>
					There is a message at the bottom of the table indicating that the
					numbers in the table will be added to the carrier descriptions.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/createConfirmRangeMsg.png')}
					alt='Confirmation Range message'
					className='landscape'
				/>
				<Typography component='p'>
					Once you are satisfied with the range that you have created, you can
					select “Confirm and Create Ranges” to officially add them to the
					database.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/confirmRanges.png')}
					alt='Confirm and Create Ranges button'
					className='landscape'
					width='400px'
				/>
				<Typography component='p'>
					You will be redirected to the Numbers page. A pop-up message will
					appear on the top-right corner of the page indicating whether the TN
					Range has been added successfully or not. If not, an explanation for
					such failure will be given in the pop-up error message.
				</Typography>
				<img
					src={require('../../../../../assets/images/documentation/AddDDI/DDIManagement.png')}
					alt='Numbers page'
					className='landscape'
				/>
				<Typography component='p'>
					If the Number Range has been added successfully, it will be present in
					the Numbers table, where you can scroll to find it, or search for the
					specific number(s) using the search bar above the top-right corner of
					the table.
				</Typography>
			</Box>
		</>
	)
}

export default AddTNDocumentation
