import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
	padding: '6px 16px',
	color: '#fff',
	background: theme.palette.primary.main,
	transition: '0.7s',
	':hover': {
		background: theme.palette.primary.main,
		opacity: '0.7',
	},
	'&.Mui-disabled': {
		color: 'grey',
		background: 'rgba(0, 0, 0, 0.18)',
		opacity: '0.7',
		cursor: 'not-allowed',
	},
}))
