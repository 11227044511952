import { createContext, FC, ReactNode, useContext, useState } from 'react'
import {
	TUMDDIAssignmentRequest,
	TUMDDIInformation,
	TUMDDIsForAssignment,
	TUMLoadStatus,
	TUMPageLink,
	TUMUpdateList,
	TUMUserDDIRangeList,
	TUMVRPList,
} from '../interfaces/ComponentModels'
import {
	ReturnTypes,
	TokenType,
	TUMBuildStatuses,
	TUMLoadTypes,
	TUMPagination,
	TUMPhoneNumberTypes,
	TUMUpdateStatuses,
} from '../enums/enums'
import {
	CheckAdminRoleRequest,
	DataResponse,
	TeamsUserManagementConfigurationRequest,
	TeamsUserManagementFilter,
	TeamsUserManagementLicenseRequest,
	TeamsUserManagementLicenseResponse,
	TeamsUserManagementPrecheckResponse,
	TeamsUserManagementRequest,
	TeamsUserManagementResponse,
	TeamsUsersResponse,
	TenantConfigurationInfo,
	TokenRequest,
	TUMRangeRequest,
	TUMRangeResponse,
} from '../interfaces/APIModels'
import UseCrud from '../customHooks/APICalls/UseCrud'
import {
	DDI,
	DDIRange,
	MSTeamsLicenseSKU,
	MSTeamsUser,
} from '../interfaces/DBModels'
import UseCustomBackendCall from '../customHooks/APICalls/UseCustomBackendCall'
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser'
import {
	useGetTenantProvisioningConfigMutation,
	usePerformTeamsUserManagementPrechecksMutation,
} from '../../services/proxyAPIData'
import {
	getLocalStorage,
	hasGraphScopes,
	onCheckPopup,
	setLocalStorage,
	showPopUpError,
	timeout,
	toAlphaString,
	toBetaString,
} from '../helperFunctions/helperFunctions'
import { GraphScopes, TeamsScopesForTeamsUserMGT } from '../constants/constants'
import { ErrorHandling } from '../customHooks/ErrorHandling/ErrorHandling'
import UseLocalStorage from '../customHooks/LocalStorage/UseLocalStorage'

// Define the shape of your context
interface TUMContextProps {
	// ** Flags
	// Call Flags
	loadingStatus: TUMLoadStatus[]
	adminCallDone: boolean
	precheckCallDone: boolean
	retrievalCallDone: boolean
	retrievalLogicSetup: boolean
	isLastPage: boolean
	// Display Flags
	removeBase: boolean
	setRemoveBase: (remove: boolean) => void
	removeAddon: boolean
	setRemoveAddon: (remove: boolean) => void
	removePhone: boolean
	setRemovePhone: (remove: boolean) => void
	internationalCalling: boolean
	setInternationalCalling: (international: boolean) => void
	// ** Constants **
	// Values
	initialGraphToken: string
	customerID: string
	serviceID: string
	currentPageNo: number
	assignedNumberString: string
	// Objects
	loggedInAccount: AccountInfo
	// Lists
	tumRangeList: TUMUserDDIRangeList[]
	teamsUserDisplay: TeamsUsersResponse[]
	teamsLicenseDisplay: TeamsUserManagementLicenseResponse
	teamsPhoneList: TUMDDIsForAssignment[]
	tumVRPList: TUMVRPList[]
	msTeamsLicenseSKUList: MSTeamsLicenseSKU[]
	// MSAL Functions
	handleLogin: () => Promise<void>
	// ** API Functions **
	// GET
	validateAdminRole: () => Promise<void>
	doPrechecks: () => Promise<void>
	buildRetrievalConstants: () => Promise<void>
	retrieveTeamsUserMGTInfo: () => Promise<void>
	getTeamsUsers: (
		requestURL?: string,
		filter?: TeamsUserManagementFilter
	) => Promise<boolean>
	getTeamsLicenses: () => Promise<boolean>
	getDDIRanges: () => Promise<void>
	getUserAssignedNumbers: (ddiRangeID: number) => Promise<TUMRangeResponse[]>
	getDDIsForAssignment: (
		ddiAssignmentRequest: TUMDDIAssignmentRequest
	) => Promise<void>
	getDDIServiceTypeID: (ddi: string) => Promise<number>
	handlePageNavigation: (navType: number) => Promise<boolean>
	handleRefresh: () => Promise<boolean>
	buildPhoneAssignmentFromRangeID: (ddiRangeID: number) => Promise<void>
	validateDDI: (ddi: string) => Promise<TUMDDIInformation>
	// POST
	configureTeamsUser: (
		configurationList: TeamsUserManagementConfigurationRequest[]
	) => void
}

// Create TUM Context
const TUMContext = createContext<TUMContextProps | undefined>(undefined)

// Define the provider props
interface ProviderProps {
	children: ReactNode
}

// Provider
export const TUMProvider: FC<ProviderProps> = ({ children }) => {
	// Hooks
	const { fetchData } = UseCrud()
	const { fetchCustomData } = UseCustomBackendCall()

	// API Calls
	const [performPrechecks] = usePerformTeamsUserManagementPrechecksMutation()
	const [getTenantConfigInfo] = useGetTenantProvisioningConfigMutation()

	// Error handling
	const addErrorLog = ErrorHandling()

	// MSAL
	const clientId = process.env.REACT_APP_TUM_CLIENT_ID || ''
	const msalInstance = new PublicClientApplication({
		auth: {
			clientId: clientId,
		},
		system: {
			allowRedirectInIframe: true,
		},
	})

	// Context flags
	// Values
	const [adminCallDone, setAdminCallDone] = useState(false)
	const [precheckCallDone, setPrecheckCallDone] = useState(false)
	const [retrievalLogicSetup, setRetrievalLogicSetup] = useState(false)
	const [retrievalCallDone, setRetrievalCallDone] = useState(false)
	const [isLastPage, setIsLastPage] = useState(false)
	// Lists
	const [loadingStatus, setLoadingStatus] = useState<TUMLoadStatus[]>([
		{
			LoadStatus: TUMBuildStatuses.NotYetStarted,
			LoadStatusType: TUMLoadTypes.Admin,
			LoadStatusDesc: 'Check for valid admin role.',
		},
		{
			LoadStatus: TUMBuildStatuses.NotYetStarted,
			LoadStatusType: TUMLoadTypes.Precheck,
			LoadStatusDesc:
				'Perform relevant checks to ensure everything is setup correctly.',
		},
		{
			LoadStatus: TUMBuildStatuses.NotYetStarted,
			LoadStatusType: TUMLoadTypes.Retrieval,
			LoadStatusDesc:
				'Retrieve information from Microsoft such as users and licenses to perform actions on page.',
		},
	])

	// Context constants
	// Values
	const [customerID, setCustomerID] = useState('')
	const [serviceID, setServiceID] = useState('')
	const [nextLink, setNextLink] = useState('')
	const [pageLinks, setPageLinks] = useState([] as TUMPageLink[])
	const [currentPageNo, setCurrentPageNo] = useState(0)
	const [assignedNumberString, setAssignedNumberString] = useState('')
	// Objects
	const [loggedInAccount, setLoggedInAccount] = useState({} as AccountInfo)
	// Lists
	const [tumRangeList, setTUMRangeList] = useState([] as TUMUserDDIRangeList[])
	const [msTeamsLicenseSKUList, setMSTeamsLicenseSKUList] = useState(
		[] as MSTeamsLicenseSKU[]
	)
	const [tumVRPList, setTUMVRPList] = useState([] as TUMVRPList[])
	const [teamsUserDisplay, setTeamsUserDisplay] = useState(
		[] as TeamsUsersResponse[]
	)
	const [teamsLicenseDisplay, setTeamsLicenseDisplay] = useState(
		{} as TeamsUserManagementLicenseResponse
	)
	const [teamsPhoneList, setTeamsPhoneList] = useState(
		[] as TUMDDIsForAssignment[]
	)

	// Context Token Logic
	// Admin Token
	const [initialGraphToken, setInitialGraphToken] = useState('')
	// Retrieval
	const [retrievalGraphToken, setRetrievalGraphToken] = useState('')
	const [retrievalTeamsToken, setRetrievalTeamsToken] = useState('')
	// Post
	const [postGraphToken, setPostGraphToken] = useState('')
	const [postTeamsToken, setPostTeamsToken] = useState('')

	// Context local storage hook
	const tumArrayKey = `tum-${customerID}`
	const { addItem } = UseLocalStorage(tumArrayKey)

	// Context Display Flags
	const [removeBase, setRemoveBase] = useState(false)
	const [removeAddon, setRemoveAddon] = useState(false)
	const [removePhone, setRemovePhone] = useState(false)
	const [internationalCalling, setInternationalCalling] = useState(false)

	// ****** API Calls ****** //

	// GET: Check admin role
	const validateAdminRole = async () => {
		// Loading
		setLoadingStatus((prev) =>
			prev.map((status) =>
				status.LoadStatusType === TUMLoadTypes.Admin
					? {
							...status,
							LoadStatus: TUMBuildStatuses.Loading,
							LoadStatusDesc: 'Validating Role, please wait...',
					  }
					: status
			)
		)

		// Make call to backend to check admin role
		var requestObj: CheckAdminRoleRequest = {
			GraphToken: initialGraphToken,
		}

		var isValidAdminRole = await fetchCustomData<boolean>({
			QueryURL: 'ValidateAdminRole',
			QueryObj: requestObj,
			ErrorMessage: 'An error occurred when validating role for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: validateAdminRole()',
		})
		if (isValidAdminRole && isValidAdminRole === true) {
			// Success
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Admin
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Success,
								LoadStatusDesc: 'Account Role Validated Successfully.',
						  }
						: status
				)
			)
		} else {
			// Failure
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Admin
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Failure,
								LoadStatusDesc: 'Invalid role found on your account.',
						  }
						: status
				)
			)
		}

		// Call done
		setAdminCallDone(true)
	}

	// GET: Prechecks
	const doPrechecks = async () => {
		// Placeholder
		setLoadingStatus((prev) =>
			prev.map((status) =>
				status.LoadStatusType === TUMLoadTypes.Precheck
					? {
							...status,
							LoadStatus: TUMBuildStatuses.Loading,
							LoadStatusDesc: 'Performing prechecks, please wait...',
					  }
					: status
			)
		)

		// Check for tenantID first
		if (loggedInAccount.tenantId && loggedInAccount.tenantId.length > 0) {
			// Set constant
			setLoggedInAccount(loggedInAccount)
			// First encrypt the tenantID and make call
			var performPrechecksResponse = await performPrechecks(
				toBetaString(loggedInAccount.tenantId)
			)
				.unwrap()
				.catch(() => {
					// Error response
					setLoadingStatus((prev) =>
						prev.map((status) =>
							status.LoadStatusType === TUMLoadTypes.Precheck
								? {
										...status,
										LoadStatus: TUMBuildStatuses.Failure,
										LoadStatusDesc: 'Prechecks failed to complete.',
								  }
								: status
						)
					)
				})
			if (performPrechecksResponse && performPrechecksResponse.Content) {
				// Decrypt
				var decryptedResponse = await toAlphaString(
					performPrechecksResponse?.Content + ''
				)

				// Convert to JSON response
				var prechecksResponseObj = JSON.parse(
					decryptedResponse
				) as TeamsUserManagementPrecheckResponse

				// Check the response: AllPrechecksPassed needs to be true then assign the other variables else return error
				if (
					prechecksResponseObj.allPrechecksPassed &&
					prechecksResponseObj.allPrechecksPassed === true
				) {
					// Assign all relevant information
					// Customer ID
					if (prechecksResponseObj.customerID) {
						setCustomerID(prechecksResponseObj.customerID)

						// Get service ID
						await retrieveServiceIDUsingTenantID(
							prechecksResponseObj.customerID,
							loggedInAccount.tenantId
						)
					}

					// Success response
					setLoadingStatus((prev) =>
						prev.map((status) =>
							status.LoadStatusType === TUMLoadTypes.Precheck
								? {
										...status,
										LoadStatus: TUMBuildStatuses.Success,
										LoadStatusDesc:
											'All prechecks have been passed successfully.',
								  }
								: status
						)
					)
				} else {
					// Failure
					setLoadingStatus((prev) =>
						prev.map((status) =>
							status.LoadStatusType === TUMLoadTypes.Precheck
								? {
										...status,
										LoadStatus: TUMBuildStatuses.Failure,
										LoadStatusDesc: `An error occurred: ${
											prechecksResponseObj.message + ''
										}.`,
								  }
								: status
						)
					)
				}
			}
		} else {
			// Failure
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Precheck
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Failure,
								LoadStatusDesc:
									'There was no tenant ID found in your account to perform prechecks.',
						  }
						: status
				)
			)
		}

		// Call done
		setPrecheckCallDone(true)
	}

	// GET: Service ID
	const retrieveServiceIDUsingTenantID = async (
		customerID: string,
		tenantID: string
	) => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName:
				'TeamsUserManagement.tsx: retrieveServiceIDUsingTenantID()',
			QueryURL: `MSTeamsUser.First(MSTeamsUser.CustomerID = '${customerID}' & MSTeamsUser.TenantID = '${tenantID}')`,
			ErrorMessage: 'An error occurred when service ID for customer',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign the object
			var msTeamsUserObj = dataResponse.Obj.MSTeamsUser as MSTeamsUser

			if (msTeamsUserObj && msTeamsUserObj.ServiceID) {
				setServiceID(msTeamsUserObj.ServiceID)
			}
		}
	}

	// GET: MS Licenses SKUs from DB
	const getMSTeamsLicenseSKUs = async () => {
		// Return
		var msTeamsLicenseSKUList = [] as MSTeamsLicenseSKU[]

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getMSTeamsLicenseSKUs()',
			QueryURL: `MSTeamsLicenseSKU.All()`,
			ErrorMessage:
				'An error occurred when getting licenses from the DB for teams User MGT',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign the list
			msTeamsLicenseSKUList = dataResponse.Obj
				.MSTeamsLicenseSKUList as MSTeamsLicenseSKU[]

			if (msTeamsLicenseSKUList && msTeamsLicenseSKUList.length > 0) {
				setMSTeamsLicenseSKUList(msTeamsLicenseSKUList)
			}
		}
	}

	// GET: Graph and Teams Token
	const generateRetrievalToken = async (tokenType: number) => {
		// Make call to backend to check admin role
		var requestObj: TokenRequest = {
			TokenType: tokenType,
			TenantID: loggedInAccount.tenantId,
		}

		// Retrieve token
		var token = await fetchCustomData<string>({
			QueryURL: 'GetTokenForTeamsUserManagement',
			QueryObj: requestObj,
			ErrorMessage: 'An error occurred when validating role for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: generateRetrievalToken()',
		})
		if (token && token.length > 0) {
			return token
		} else {
			return null
		}
	}

	// GET: Return tenant config for VRP
	const getTenantVoiceRouteConfig = async () => {
		// List to build
		var vrpList = [] as TUMVRPList[]

		// Encrypt customerID and make call
		await getTenantConfigInfo(toBetaString(customerID))
			.unwrap()
			.then(async (response) => {
				// Decrypt response and assign to object
				if (response && response.Content) {
					var decryptedResponse = await toAlphaString(response.Content + '')
					var responseObj = JSON.parse(
						decryptedResponse
					) as TenantConfigurationInfo

					// Check response to get regions and then get the vrp
					if (
						responseObj &&
						responseObj.Regions &&
						responseObj.Regions.length > 0
					) {
						// Loop through and buildVRP list
						for (let reg = 0; reg < responseObj.Regions.length; reg++) {
							// Add object
							vrpList.push({
								CountryISO2Code: responseObj.Regions[reg].CountryISO2,
								DomVoiceRoute: responseObj.Regions[reg].DomVoiceRoute,
								DomVoiceRoutingPolicy:
									responseObj.Regions[reg].DomVoiceRoutingPolicy,
								IntVoiceRoute: responseObj.Regions[reg].IntVoiceRoute,
								IntVoiceRoutingPolicy:
									responseObj.Regions[reg].IntVoiceRoutingPolicy,
							})
						}
					}
				}
			})
			.catch((error) => {
				// Error
				if (error) {
					// Failure
					setLoadingStatus((prev) =>
						prev.map((status) =>
							status.LoadStatusType === TUMLoadTypes.Retrieval
								? {
										...status,
										LoadStatus: TUMBuildStatuses.Failure,
										LoadStatusDesc:
											'An error occurred when getting your voice routing policies.',
								  }
								: status
						)
					)
				}
			})

		// Set list built
		setTUMVRPList(vrpList)
	}

	// GET: Build all the values then make the main call
	const buildRetrievalConstants = async () => {
		// Loading
		setLoadingStatus((prev) =>
			prev.map((status) =>
				status.LoadStatusType === TUMLoadTypes.Retrieval
					? {
							...status,
							LoadStatus: TUMBuildStatuses.Loading,
							LoadStatusDesc: 'Getting everything ready, please wait...',
					  }
					: status
			)
		)

		// Make relevant calls
		await getMSTeamsLicenseSKUs()
		await getTenantVoiceRouteConfig()

		// Generate retrieval tokens
		var graphToken = await generateRetrievalToken(TokenType.GraphToken)
		var teamsToken = await generateRetrievalToken(TokenType.TeamsToken)
		if (
			graphToken &&
			graphToken.length > 0 &&
			teamsToken &&
			teamsToken.length > 0
		) {
			setRetrievalGraphToken(graphToken)
			setRetrievalTeamsToken(teamsToken)

			// Update Loading
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Retrieval
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Loading,
								LoadStatusDesc: 'Retrieving data, please wait...',
						  }
						: status
				)
			)
		} else {
			// Failure
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Retrieval
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Failure,
								LoadStatusDesc:
									'An error occurred when retrieving your tokens.',
						  }
						: status
				)
			)
		}

		// Call done
		setRetrievalLogicSetup(true)
	}

	// GET: Retrieve all the teams user MGT information for user
	const retrieveTeamsUserMGTInfo = async () => {
		// Make calls
		var licenseSuccess = await getTeamsLicenses()
		var userSuccess = await getTeamsUsers()

		if (!userSuccess) {
			// Error
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Retrieval
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Failure,
								LoadStatusDesc:
									'An error occurred when trying to retrieve users from Microsoft.',
						  }
						: status
				)
			)
			return
		}

		if (!licenseSuccess) {
			// Error
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Retrieval
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Failure,
								LoadStatusDesc:
									'An error occurred when trying to retrieve licenses from Microsoft.',
						  }
						: status
				)
			)
			return
		}

		// After the calls, if no errors set success
		if (licenseSuccess && userSuccess) {
			// Success
			setLoadingStatus((prev) =>
				prev.map((status) =>
					status.LoadStatusType === TUMLoadTypes.Retrieval
						? {
								...status,
								LoadStatus: TUMBuildStatuses.Success,
								LoadStatusDesc:
									'All information retrieved successfully. Page will load shortly.',
						  }
						: status
				)
			)
		}

		// Call done
		setRetrievalCallDone(true)
		await timeout(3000)
	}

	// GET: Users from MS Call
	const getTeamsUsers = async (
		requestURL?: string,
		filter?: TeamsUserManagementFilter
	): Promise<boolean> => {
		// Make call to backend to check admin role
		var requestObj: TeamsUserManagementRequest = {
			GraphToken: retrievalGraphToken,
			TeamsToken: retrievalTeamsToken,
			MSTeamsLicenseSKUs: msTeamsLicenseSKUList,
			RequestURL: requestURL && requestURL.length > 0 ? requestURL : '',
			Filter: filter,
		}

		var teamsUserResponse = await fetchCustomData<TeamsUserManagementResponse>({
			QueryURL: 'GetUsersFromMicrosoft',
			QueryObj: requestObj,
			ErrorMessage:
				'An error occurred when retrieving users from Microsoft for Teams User MGT',
			ShowErrorToast: false,
			ShowSuccessToast: false,
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: getTeamsUsers()',
		})
		if (teamsUserResponse) {
			// Check for users
			if (
				teamsUserResponse.usersResponse &&
				teamsUserResponse.usersResponse.length > 0
			) {
				// Set the display - Order it first
				teamsUserResponse.usersResponse.sort((a, b) =>
					(a.name + '').toLowerCase().localeCompare(b.name + '')
				)
				setTeamsUserDisplay(teamsUserResponse.usersResponse)
			}

			// Next and Previous Link
			if (teamsUserResponse.nextLink && teamsUserResponse.nextLink.length > 0) {
				setNextLink(teamsUserResponse.nextLink)
				setIsLastPage(false)
			} else {
				setIsLastPage(true)
			}
			if (
				teamsUserResponse.previousLink &&
				teamsUserResponse.previousLink.length > 0
			) {
				var currentLink = teamsUserResponse.previousLink

				// Set the page link if not existing
				if (!pageLinks.find((l) => l.PageLink === currentLink)) {
					setPageLinks((prevPageLinks) => [
						...prevPageLinks,
						{ PageLink: currentLink, PageNo: Number(prevPageLinks.length) + 1 },
					])
				}
			}

			return true
		} else {
			// Error
			return false
		}
	}

	// GET: Licenses from MS Call
	const getTeamsLicenses = async (): Promise<boolean> => {
		// Make call to backend to check admin role
		var requestObj: TeamsUserManagementLicenseRequest = {
			GraphToken: retrievalGraphToken,
			MSTeamsLicenseSKUs: msTeamsLicenseSKUList,
		}

		var teamsLicenseResponse =
			await fetchCustomData<TeamsUserManagementLicenseResponse>({
				QueryURL: 'GetLicensesFromMicrosoft',
				QueryObj: requestObj,
				ErrorMessage:
					'An error occurred when validating role for Teams User MGT',
				ShowErrorToast: false,
				ShowSuccessToast: false,
				LogErrorToDB: true,
				FileAndFunctionName: 'TeamsUserManagement.tsx: getTeamsLicenses()',
			})
		if (teamsLicenseResponse) {
			// Check for users
			setTeamsLicenseDisplay(teamsLicenseResponse)

			return true
		} else {
			// Error
			return false
		}
	}

	// GET: Retrieve ranges from the DB based on customerID
	const getDDIRanges = async () => {
		// Return
		var tumRangeResponseList = [] as TUMUserDDIRangeList[]

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIRanges()',
			QueryURL: `DDIRange.Where(DDIRange.CustomerID = '${customerID}')`,
			ErrorMessage: `An error occurred when retrieving DDI ranges for customer ${customerID}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign response
			var ddiRangeResponse = dataResponse.Obj.DDIRangeList as DDIRange[]

			if (ddiRangeResponse && ddiRangeResponse.length > 0) {
				// Loop through and build display
				var ddiRangeList = [] as TUMUserDDIRangeList[]
				for (let range = 0; range < ddiRangeResponse.length; range++) {
					// Push to list
					ddiRangeList.push({
						DDIRangeID: ddiRangeResponse[range].DDIRangeID,
						DDIRangeStart: ddiRangeResponse[range].DDIRangeStart,
						DDIRangeEnd: ddiRangeResponse[range].DDIRangeEnd,
						Total: getPhoneNumberDifference(
							ddiRangeResponse[range].DDIRangeStart + '',
							ddiRangeResponse[range].DDIRangeEnd + ''
						),
					})
				}

				tumRangeResponseList = ddiRangeList
			}
		}

		// Set value and return
		setTUMRangeList(tumRangeResponseList)
	}

	// GET: Retrieve list of numbers that are already assigned based on range selected
	const getUserAssignedNumbers = async (ddiRangeID: number) => {
		// Return
		var tumRangeResponse = [] as TUMRangeResponse[]

		// Get the current range
		var currentRange = tumRangeList.find(
			(range) => Number(range.DDIRangeID) === ddiRangeID
		)
		if (currentRange) {
			// Build object for MS API Call
			var requestObj: TUMRangeRequest = {
				TeamsToken: retrievalTeamsToken,
				RangeStart: currentRange.DDIRangeStart,
				RangeEnd: currentRange.DDIRangeEnd,
			}

			// Make call
			var apiResponse = await fetchCustomData<TUMRangeResponse[]>({
				QueryURL: 'GetUsersWithAssignedNumbers',
				QueryObj: requestObj,
				ErrorMessage:
					'An error occurred when retrieving users with assigned numbers for Teams User MGT',
				ShowErrorToast: false,
				ShowSuccessToast: false,
				LogErrorToDB: true,
				FileAndFunctionName:
					'TeamsUserManagement.tsx: getUserAssignedNumbers()',
			})
			if (apiResponse) {
				tumRangeResponse = apiResponse
			}
		}

		return tumRangeResponse
	}

	// GET: Retrieve list of DDIs for assignment based on range selected - filter out the numbers already assigned
	const getDDIsForAssignment = async (
		ddiAssignmentRequest: TUMDDIAssignmentRequest
	) => {
		// Return
		var ddisForAssignmentList = [] as TUMDDIsForAssignment[]

		// Filters
		var queryFilters = `DDIRange.CustomerID = '${customerID}' & DDIRange.DDIRangeID = '${ddiAssignmentRequest.DDIRangeID}'`
		// 1 - DDI Range filter
		if (ddiAssignmentRequest.AssignedNumbersFilter) {
			queryFilters += ` & DDI.DDI !^ '${ddiAssignmentRequest.AssignedNumbersFilter}'`
		}
		// 2 - Search DDI filter (If the user searches)
		if (ddiAssignmentRequest.DDISearchValue) {
			queryFilters += ` & DDI.DDI ~ '${ddiAssignmentRequest.DDISearchValue}'`
		}

		// Build query URL
		var queryURL = `DDIRange.DDI.Address.State.Country.Where(${queryFilters}).Pagination(NumberOfRows = '10' & PageNumber = '1' & Column = 'DDI.DDI' & SortType = 'ASC')`

		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIsForAssignment()',
			QueryURL: queryURL,
			ErrorMessage: `An error occurred when retrieving DDI ranges for customer ${customerID}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign
			var ddiRangeResponse = dataResponse.Obj.DDIRangeList as DDIRange[]

			if (ddiRangeResponse && ddiRangeResponse.length > 0) {
				// Loop through response to build list to be assigned
				for (let range = 0; range < ddiRangeResponse.length; range++) {
					// Assign the DDI list and adjust
					var ddiList = ddiRangeResponse[range].DDIList as DDI[]

					if (ddiList && ddiList.length > 0) {
						// Loop through DDI List and build the display list
						for (let ddi = 0; ddi < ddiList.length; ddi++) {
							// Add DDI and country code to list
							ddisForAssignmentList.push({
								DDI: ddiList[ddi].DDI + '',
								CountryISO2Code:
									ddiList[ddi].Address?.State?.Country?.CountryISO2Code + '',
								NumberType: ddiList[ddi].ServiceTypeOutID,
							})
						}
					}
				}
			}
		}

		setTeamsPhoneList(ddisForAssignmentList)
	}

	// GET: Service Type ID for DDI (TDR/OC)
	const getDDIServiceTypeID = async (ddi: string) => {
		// Make hook call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'TeamsUserManagement.tsx: getDDIServiceTypeID()',
			QueryURL: `DDI.First(DDI.DDI = '${ddi}')`,
			ErrorMessage: `An error occurred when retrieving DDI information using ${ddi}`,
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && dataResponse.Obj) {
			// Assign response
			var ddiResponse = dataResponse.Obj.DDI as DDI
			if (ddiResponse && ddiResponse.ServiceTypeOutID) {
				// Return ID
				return ddiResponse.ServiceTypeOutID
			}
		}

		// Empty return
		return TUMPhoneNumberTypes.TDR
	}

	// GET: Validate DDI
	const validateDDI = async (ddi: string) => {
		// Build the user DDI list that can be assigned
		var ddiInformation: TUMDDIInformation = {
			ddi: '',
			countryISO2Code: '',
			serviceTypeID: 1,
		}

		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PhoneAutocomplete.tsx: validateDDI()',
			QueryURL: `DDIRange.DDI.Address.State.Country.First(DDIRange.CustomerID = '${customerID}' & DDI.DDI = '${ddi}')`,
			ErrorMessage: 'An error occurred when validating DDI',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Check response and filter out the list
			var ddiRangeResponse = dataResponse.Obj.DDIRange as DDIRange

			if (ddiRangeResponse) {
				// Assign the DDI list and adjust
				var ddiList = ddiRangeResponse.DDIList as DDI[]

				if (ddiList && ddiList[0]) {
					ddiInformation.ddi = ddiList[0].DDI + ''
					ddiInformation.countryISO2Code =
						ddiList[0].Address?.State?.Country?.CountryISO2Code + ''
					ddiInformation.serviceTypeID = Number(ddiList[0].ServiceTypeOutID)
				}
			}
		}

		// Set list
		return ddiInformation
	}

	// POST: Teams User MGT Configuration
	const configureTeamsUser = async (
		configurationList: TeamsUserManagementConfigurationRequest[]
	) => {
		// Generate tokens if not already generated
		var graphToken: string | null | void = null
		var teamsToken: string | null | void = null
		if (postGraphToken.length > 0) {
			// Validate
			graphToken = postGraphToken
		} else {
			// Generate graph token
			graphToken = await generateToken(TokenType.GraphToken)

			if (graphToken) {
				setPostGraphToken(graphToken)
			} else {
				return false
			}
		}
		if (postTeamsToken.length > 0) {
			// Validate
			teamsToken = postTeamsToken
		} else {
			// Generate teams token
			teamsToken = await generateToken(TokenType.TeamsToken)

			if (teamsToken) {
				setPostTeamsToken(teamsToken)
			} else {
				return false
			}
		}

		// Amend the list to add relevant information
		var amendedConfigList = configurationList.map((config) => ({
			...config,
			CustomerID: customerID,
			GraphToken: graphToken,
			TeamsToken: teamsToken,
			ServiceID: serviceID,
		}))

		// Make call
		var response = await fetchCustomData<boolean>({
			QueryURL: `PostTeamsUserManagementConfig`,
			QueryObj: amendedConfigList,
			ErrorMessage: 'An error occurred when attempting to configure user',
			ShowErrorToast: false,
			ShowSuccessToast: true,
			SuccessMessage: 'Configuration successfully sent to be completed',
			LogErrorToDB: true,
			FileAndFunctionName: 'TeamsUserManagement.tsx: configureTeamsUser()',
		})

		if (response && response === true) {
			// Add to the TUM status array
			// First check if the TUM status array is created for this specific customer (using customerID in the key name of array)

			// Variables
			var localTUMUpdateList = getLocalStorage(tumArrayKey)
			var tumUpdateObj: TUMUpdateList = {}

			// If not found in local storage, we need to create the array first
			if (!localTUMUpdateList) {
				// Create empty array and add to local storage
				var emptyArray: TUMUpdateList[] = []
				setLocalStorage(tumArrayKey, emptyArray)
			}

			// Get current date and time
			var logTime = getCurrentDateTime()

			// Loop through config list and add to local storage
			for (let config = 0; config < amendedConfigList.length; config++) {
				// Create obj to add to list
				tumUpdateObj = {
					JobID: amendedConfigList[config].JobID,
					UPN: amendedConfigList[config].UPN,
					ActionPerformed: amendedConfigList[config].ActionTypeID,
					Status: TUMUpdateStatuses.Pending,
					LogTime: logTime,
				}
				addItem(tumUpdateObj)
			}
		}
	}

	// *** MSAL Functions *** //
	// Handle login using MSAL
	const handleLogin = async () => {
		var hasPopUpsEnabled = onCheckPopup()

		if (hasPopUpsEnabled) {
			// Initialize instance
			await msalInstance.initialize()

			// Login Request
			const logInRequest = {
				scopes: GraphScopes,
				prompt: 'select_account',
			}

			await msalInstance
				.loginPopup(logInRequest)
				.then(async (response) => {
					if (response) {
						// Use access token to check for admin role
						if (response.accessToken) {
							setInitialGraphToken(response.accessToken)
						}
						if (response.account) {
							setLoggedInAccount(response.account)
						}
					} else {
						// No token retrieved
						setLoadingStatus((prev) =>
							prev.map((status) =>
								status.LoadStatusType === TUMLoadTypes.Admin
									? {
											...status,
											LoadStatus: TUMBuildStatuses.Failure,
											LoadStatusDesc:
												'An error occurred when retrieving your account token to perform this check.',
									  }
									: status
							)
						)
					}
				})
				.catch(async (error) => {
					if ((error?.errorCode + '').includes('popup_window_error')) {
						showPopUpError()
					} else {
						// Log error to DB - If user does not cancel the process
						if (!(error?.errorCode + '').includes('user_cancelled')) {
							await addErrorLog({
								ActionPerformed: 'MSAL Login Error on Teams User MGT',
								ErrorInfo: `Error occurred when logging in to Teams User MGT: ${JSON.stringify(
									error
								)}`,
							})
						}
					}
				})
		}
	}

	// Generate token from MSAL using logged in account (confirmed account)
	const generateToken = async (tokenType: number) => {
		// Error handling
		var isError = false
		var errorMessage = ''
		var token = ''

		var scopes = [] as string[]
		switch (tokenType) {
			// Graph
			case TokenType.GraphToken:
				scopes = GraphScopes
				break
			// Teams
			case TokenType.TeamsToken:
				scopes = TeamsScopesForTeamsUserMGT
				break
		}

		// Initialize instance
		await msalInstance.initialize()

		return await msalInstance
			.acquireTokenSilent({
				scopes: scopes,
				account: loggedInAccount,
			})
			.then(async (response) => {
				// Response token
				if (response) {
					if (tokenType === TokenType.GraphToken) {
						// Check if it has graph scopes
						if (hasGraphScopes(response.scopes)) {
							token = response.accessToken
						}
					} else {
						token = response.accessToken
					}
				}

				// Return true
				return token
			})
			.catch(async (error) => {
				if (error) {
					isError = true
					errorMessage = JSON.stringify(error)
				}
			})
			.finally(() => {
				if (isError) {
					// Log Error to DB
					addErrorLog({
						ActionPerformed: 'Teams User MGT: Token Generation',
						ErrorInfo: `An error occurred when retrieving ${TokenType[tokenType]}: ${errorMessage}`,
					})
				}
			})
	}

	// Other functions
	// Handle navigation between pages
	const handlePageNavigation = async (navType: number) => {
		// Request URL
		var requestURL = ''

		// First check whether it is next or prev
		switch (navType) {
			case TUMPagination.Next:
				// Set Request URL
				requestURL = nextLink
				// Current page
				setCurrentPageNo((prev) => prev + 1)

				break

			case TUMPagination.Previous:
				// Get the request URL from the array
				var getPageLink = pageLinks.find((l) => l.PageNo === currentPageNo)
				if (getPageLink) {
					requestURL = getPageLink.PageLink
				}
				setCurrentPageNo((prev) => prev - 1)

				break
		}

		// Make call
		var userSuccess = await getTeamsUsers(requestURL)

		return userSuccess
	}

	// Handle refresh of page
	const handleRefresh = async () => {
		// Make calls
		var userStatus = await getTeamsUsers('')
		var licenseStatus = await getTeamsLicenses()

		// Check for errors
		if (!userStatus || !licenseStatus) {
			return false
		} else {
			// Refresh to page 1
			setCurrentPageNo(0)
		}

		// If no errors
		return true
	}

	// Retrieve the DDIs that are already assigned based on DDI Range
	const createConcatenatedForAssigned = async (ddiRangeID: number) => {
		// Variables
		var concatenatedAssignedNumbers: string = ''

		// Get assigned DDIs
		var assignedDDIList: TUMRangeResponse[] = await getUserAssignedNumbers(
			ddiRangeID
		)
		if (assignedDDIList) {
			// Concatenate string of assigned numbers
			concatenatedAssignedNumbers = assignedDDIList
				.map((item) => item.assignedNumber) // Extract assignedNumber
				.filter((num): num is string => num !== undefined) // Filter out undefined
				.join(', ') // Concatenate with commas
		}

		return concatenatedAssignedNumbers
	}

	// Get the latest 10 numbers of assignment
	const buildPhoneAssignmentFromRangeID = async (ddiRangeID: number) => {
		// Get concatenated numbers
		var concatenatedAssignedNumbers = await createConcatenatedForAssigned(
			ddiRangeID
		)
		setAssignedNumberString(concatenatedAssignedNumbers)

		// Get latest 10 numbers for assigning
		await getDDIsForAssignment({
			DDIRangeID: ddiRangeID,
			AssignedNumbersFilter: concatenatedAssignedNumbers,
		})
	}

	// Function to get the difference between DDI range start and end
	const getPhoneNumberDifference = (start: string, end: string): number => {
		const startNumber = parseInt(start, 10)
		const endNumber = parseInt(end, 10)
		return endNumber - startNumber
	}

	// Get date
	const getCurrentDateTime = (): Date => {
		return new Date()
	}

	return (
		<TUMContext.Provider
			value={{
				loadingStatus,
				adminCallDone,
				precheckCallDone,
				retrievalCallDone,
				retrievalLogicSetup,
				isLastPage,
				removeBase,
				setRemoveBase,
				removeAddon,
				setRemoveAddon,
				removePhone,
				setRemovePhone,
				internationalCalling,
				setInternationalCalling,
				customerID,
				serviceID,
				initialGraphToken,
				currentPageNo,
				assignedNumberString,
				loggedInAccount,
				tumRangeList,
				teamsUserDisplay,
				teamsLicenseDisplay,
				teamsPhoneList,
				tumVRPList,
				msTeamsLicenseSKUList,
				handleLogin,
				validateAdminRole,
				doPrechecks,
				buildRetrievalConstants,
				retrieveTeamsUserMGTInfo,
				getTeamsUsers,
				getTeamsLicenses,
				handlePageNavigation,
				handleRefresh,
				getDDIRanges,
				getUserAssignedNumbers,
				getDDIsForAssignment,
				getDDIServiceTypeID,
				buildPhoneAssignmentFromRangeID,
				validateDDI,
				configureTeamsUser,
			}}>
			{children}
		</TUMContext.Provider>
	)
}

// Create a custom hook for easier usage
export const useTUMContext = () => {
	const context = useContext(TUMContext)
	if (!context) {
		throw new Error('useAppContext must be used within an AppProvider')
	}
	return context
}
