import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	PaletteMode,
	Tooltip,
	Typography,
} from '@mui/material'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import './Header.scss'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
	setConfirmedAccount,
	setGlobalAdminUser,
} from '../../../../store/reducers/reducers'
import { GetEmptyAccountInfo } from '../../../../utils/helperFunctions/helperFunctions'
import { stringAvatar } from '../../../../utils/helperFunctions/helperFunctions'

const Header = ({
	handleModeChange,
	mode,
}: {
	handleModeChange: any
	mode: PaletteMode
}) => {
	// Need to send through partner
	const navigate = useNavigate()
	const { isAuthenticated, user } = useAuth0()
	const partner = useSelector(
		(state: RootState) => state.RootReducer.partnerReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)
	const hideTheme = true
	// Get location to check if active
	const location = useLocation()
	const dispatch = useAppDispatch()

	// Variables for avatar menu
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const { logout } = useAuth0()

	useEffect(() => {}, [
		loggedInRoleAndPermissions,
		location,
		isAuthenticated,
		user,
	])

	// Handle avatar menu
	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleAvatarClose = () => {
		setAnchorEl(null)
	}

	const handleLogout = () => {
		dispatch(setConfirmedAccount(GetEmptyAccountInfo()))
		dispatch(setGlobalAdminUser(''))
		logout({ returnTo: window.location.origin })
	}

	const handleNavToSettings = () => {
		setAnchorEl(null)
		navigate('/settings')
	}

	return (
		<>
			<Box id='header' className='header-container'>
				{/* Box below shows logo, user info and mode change */}
				<Box className='header-content flex-box sb'>
					<Box className='header-logo'>
						<img
							src={require(`../../../../assets/images/logos/${partner}-logo.png`)}
							alt='Header Logo'
							onClick={() => navigate('/')}
						/>
					</Box>
					<Box className='header-user flex-box row'>
						{/* Avatar and user details */}
						{isAuthenticated && loggedInUser && loggedInRoleAndPermissions ? (
							<>
								{/* Avatar */}
								<Tooltip title='Account settings'>
									<IconButton
										onClick={handleAvatarClick}
										size='small'
										aria-controls={open ? 'account-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}>
										<Avatar {...stringAvatar(user?.email + '')} />
										<ArrowDropDownIcon sx={{ color: 'text.secondary' }} />
									</IconButton>
								</Tooltip>
								{/* Avatar menu */}
								<Menu
									anchorEl={anchorEl}
									id='account-menu'
									open={open}
									onClose={handleAvatarClose}
									PaperProps={{
										elevation: 0,
										sx: {
											overflow: 'visible',
											filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
											mt: 1.5,
											'& .MuiAvatar-root': {
												width: 40,
												height: 40,
												ml: -0.5,
												mr: 1,
											},
											'&:before': {
												content: '""',
												display: 'block',
												position: 'absolute',
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: 'background.paper',
												transform: 'translateY(-50%) rotate(45deg)',
												zIndex: 0,
											},
										},
									}}
									transformOrigin={{ horizontal: 'right', vertical: 'top' }}
									anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
									<MenuItem disableTouchRipple disableRipple>
										<Box className='flex-box row'>
											<Avatar {...stringAvatar(user?.email + '')} />
											<Box className='flex-box col'>
												<Typography component='p' color='text.header'>
													{user?.email}
												</Typography>
												<Typography component='p' color='text.header'>
													{loggedInRoleAndPermissions[0]?.Role?.Description}
												</Typography>
											</Box>
										</Box>
									</MenuItem>
									{!hideTheme ? (
										<>
											<Divider />
											<MenuItem onClick={handleModeChange}>
												<Box className='flex-box row'>
													<Typography component='p' color='text.header'>
														Theme
													</Typography>
													<Box display='flex' columnGap='5px'>
														<IconButton className='icon' sx={{ padding: 0 }}>
															{mode === 'dark' ? (
																<Brightness7Icon />
															) : (
																<Brightness4Icon />
															)}
														</IconButton>
														<Typography component='p' color='text.header'>
															{mode === 'dark'
																? 'Dark Mode (Beta)'
																: 'Light Mode'}
														</Typography>
													</Box>
												</Box>
											</MenuItem>
										</>
									) : null}
									<Divider />
									<MenuItem onClick={handleNavToSettings}>
										<ListItemIcon>
											<SettingsIcon
												sx={{ color: 'primary.main' }}
												fontSize='small'
											/>
										</ListItemIcon>
										<ListItemText
											sx={{
												color: 'text.header',
												paddingTop: '8px',
												paddingBottom: '8px',
											}}>
											Settings
										</ListItemText>
									</MenuItem>
									<Divider />
									<MenuItem onClick={handleLogout}>
										<ListItemIcon>
											<LogoutIcon
												sx={{ color: 'primary.main' }}
												fontSize='small'
											/>
										</ListItemIcon>
										<ListItemText
											sx={{
												color: 'text.header',
												paddingTop: '8px',
												paddingBottom: '8px',
											}}>
											Log out
										</ListItemText>
									</MenuItem>
								</Menu>
							</>
						) : null}
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Header
