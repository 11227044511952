import React, { useEffect, useState } from 'react'
import { OCServiceInformationRequest } from '../../../../../../utils/interfaces/ComponentModels'
import PostAndRetrieveDataHook from '../../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { ActionLog } from '../../../../../../utils/interfaces/DBModels'
import { Box, Divider, Typography } from '@mui/material'
import { StyledTextBox } from '../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { LoadingButton } from '@mui/lab'
import { validateField } from '../../../../../../utils/helperFunctions/helperFunctions'
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import './OCServiceInformation.scss'

const OCServiceInformation = ({
	orderSKUID,
	serviceID,
	handleOCServiceInformationAction,
}: {
	orderSKUID: number
	serviceID: string
	handleOCServiceInformationAction: (
		ocServiceInfoRequest: OCServiceInformationRequest
	) => void
}) => {
	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()
	const [tenantID, setTenantID] = useState<string>('')
	const [tenantIDErrorMessage, setTenantIDErrorMessage] = useState<string>('')
	const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
	const [actionLog, setActionLog] = useState<ActionLog | null>(null)
	const [dataRetrieved, setDataRetrieved] = useState(false)
	const displayServiceID = false

	useEffect(() => {
		fetchDataForAddingTenantID()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleTenantIDChange = (tenantIDValue: string) => {
		setTenantID(tenantIDValue)
		setTenantIDErrorMessage(validateField('Tenant ID', tenantIDValue, true))
	}

	const handleAddTenant = async () => {
		if (tenantIDErrorMessage.trim().length > 0) {
			return
		}
		setIsBtnLoading(true)
		// API Call to Add TenantID
		var _actionLog = actionLog as ActionLog
		var ocServiceInfoRequest: OCServiceInformationRequest = {
			TenantID: tenantID,
			ServiceID: serviceID,
			ActionLogID: _actionLog.ActionLogID ?? 0,
		}
		await handleOCServiceInformationAction(ocServiceInfoRequest)
		setIsBtnLoading(false)
	}

	// GET: ActionLog
	const fetchDataForAddingTenantID = async () => {
		var dataResponse = await postAndRetrieveDataFromDB(
			'ActionLog',
			`ActionLog.First(ActionLog.OrderSKUID= '${orderSKUID}' & ActionLog.ProductID = 'OC-SER' & ActionLog.ActionID = 'OC-SER-INFO')`,
			true,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var actionLog = dataResponse.Obj.ActionLog as ActionLog
			if (actionLog) {
				setActionLog(actionLog)
			}
		}
		setDataRetrieved(true)
	}

	return (
		<>
			{dataRetrieved ? (
				<Box className=''>
					{/* Heading */}
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<Box display='flex' alignItems='center'>
							<Typography
								id='order-form-header'
								component='h1'
								style={{ fontSize: '25px' }}>
								OC Service Information
							</Typography>
						</Box>
					</Box>
					<Divider />
					<Box display='flex' flexDirection='column' style={{ width: '100%' }}>
						{/* Probably need to hide */}
						{displayServiceID && (
							<Box
								display='flex'
								alignItems='center'
								style={{ marginBottom: '8px' }}>
								<Typography style={{ flex: 1, fontWeight: '600' }}>
									Service ID
								</Typography>
								<StyledTextBox
									variant='outlined'
									value={serviceID}
									disabled
									fullWidth
									margin='normal'
									style={{ flex: 2 }}
								/>
							</Box>
						)}
						<Box display='flex' flexDirection='column'>
							<Box
								display='flex'
								alignItems='center'
								style={{ marginBottom: '8px' }}>
								<Typography style={{ flex: 1, fontWeight: '600' }}>
									Tenant ID
								</Typography>
								<StyledTextBox
									placeholder='xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
									value={tenantID}
									onChange={(e) => handleTenantIDChange(e.target.value)}
									variant='outlined'
									fullWidth
									margin='normal'
									error={tenantIDErrorMessage.trim().length > 0}
									style={{ flex: 2 }}
								/>
							</Box>
							{tenantIDErrorMessage.trim().length > 0 && (
								<Box display='flex' style={{ marginTop: '4px' }}>
									<Box style={{ flex: 1 }} />
									<Typography
										component='p'
										style={{ color: 'red', fontSize: '13px', flex: 2 }}>
										{tenantIDErrorMessage}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
					<Divider />
					<Box sx={{ marginTop: 3 }} className='oc-tenant-form-footer'>
						{/* Clear filters */}
						<LoadingButton
							disabled={
								tenantIDErrorMessage.trim().length > 0 ||
								tenantID.trim().length === 0
							}
							loading={isBtnLoading}
							startIcon={<SaveAsOutlinedIcon />}
							variant='outlined'
							onClick={() => {
								handleAddTenant()
							}}>
							Confirm
						</LoadingButton>
					</Box>
				</Box>
			) : (
				<Typography>Loading... Please wait.</Typography>
			)}
		</>
	)
}
export default OCServiceInformation
