/* eslint-disable no-loop-func */
import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material'
import { StyledTextBox } from '../../../components/shared/styledComponents/StyledTextBox/StyledTextBox'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import {
	numberToChar,
	showErrorToast,
	showSuccessToast,
	toBeta,
	toBetaString,
	toSREServicesAlpha,
} from '../../../utils/helperFunctions/helperFunctions'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Customer,
	CustomerTrunkGroupFQDNMap,
	CustomerTrunkGroupIP,
	CustomerTrunkGroupRegionMap,
	DDIRangeTrunkMap,
	SBCVirtualIPDataCentre,
	SBCVirtualIP,
	TrunkType,
	FQDN,
	InternalTrunkGroupIP,
	MSTeamsUser,
	RegionDataCentre,
	TrunkGroup,
	DataCentre,
	Order,
	OrderSKU,
	ActionLog,
	Service,
} from '../../../utils/interfaces/DBModels'

import {
	CallDeliveryMethods,
	InternetProtocols,
	RegionOptions,
	StrategyOption,
	TrunkTypes,
	Services,
	ServiceTypes,
} from '../../../utils/enums/enums'
import './AddTrunk.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/hooks'
import { setPageTitle } from '../../../store/reducers/reducers'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PublicIcon from '@mui/icons-material/Public'
import InfoIcon from '@mui/icons-material/Info'
import { isIPAddress } from 'ip-address-validator'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import { StyledChip } from '../../../components/shared/styledComponents/StyledChip/StyledChip'
import {
	RegionTrunkIPList,
	TrunkIPListDisplay,
} from '../../../utils/interfaces/ComponentModels'
import LoadingCard from '../../../components/shared/loading/loadingCard/LoadingCard'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PostUpdateDataHook from '../../../utils/customHooks/APICalls/PostUpdateDataHook'
import {
	BetaObject,
	BetaSREMutation,
	BYOCSBCRequestModel,
	CustomerTrunkIPIDList,
	DDIRangeIDList,
	FQDNIDList,
	ParentService,
	PostHookObj,
	SREConfiguration,
	SRECustomer,
	SREServiceConfiguration,
	SBCTrunkIPModel,
} from '../../../utils/interfaces/APIModels'
import {
	usePostCustomerAndReturnSREDataMutation,
	usePostSREConfigurationMutation,
	useQueueSBCRequestMutation,
	useIncrementSBCVirtualIPRealmCountMutation,
	useDecrementSBCVirtualIPRealmCountMutation,
} from '../../../services/proxyAPIData'
import ErrorLogging from '../../../utils/customHooks/ErrorLogging'
import DeleteIcon from '@mui/icons-material/Delete'
import TrunkActionMGT from '../../../utils/customHooks/TrunkActionMGT/TrunkActionMGT'

function AddTrunk() {
	// Params
	const { customerID } = useParams()

	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const orderSKUIDForTrunk = useSelector(
		(state: RootState) => state.RootReducer.orderSKUIDForTrunkReducer.value
	)

	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()
	const {
		postUpdateDataWithoutRefetch,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
	} = PostUpdateDataHook()

	// New hook - Action MGT for trunks
	const { handleTrunkActionMGT } = TrunkActionMGT()

	const [
		postIncrementSBCVirtualIPRealmCount,
		{ isLoading: isLoadingIncrementSBCVirtualIPRealmCount },
	] = useIncrementSBCVirtualIPRealmCountMutation()

	const [
		postDecrementSBCVirtualIPRealmCount,
		{ isLoading: isLoadingDecrementSBCVirtualIPRealmCount },
	] = useDecrementSBCVirtualIPRealmCountMutation()

	// API Calls

	// SRE
	const [
		postCustomerAndReturnSREInformation,
		{ isLoading: postSREDataLoading },
	] = usePostCustomerAndReturnSREDataMutation()
	const [postSREConfiguration, { isLoading: postSREConfigurationLoading }] =
		usePostSREConfigurationMutation()
	// SBC
	const [
		postQueueSBCRequest,
		{ isLoading: postQueueConfigureSBCRequestLoading },
	] = useQueueSBCRequestMutation()

	// Error handling
	const { addErrorLog } = ErrorLogging()

	// ********************************* Display constants ********************************* //
	// General
	const [alertMessage, setAlertMessage] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [bizOrg, setBizOrg] = useState('')
	const [regionDataCentreList, setRegionDataCentreList] = useState(
		[] as RegionDataCentre[]
	)
	const [trunkTypeList, setTrunkTypeList] = useState([] as TrunkType[])
	const [customerTrunkGroupIPs, setCustomerTrunkGroupIPs] = useState(
		[] as CustomerTrunkGroupIP[]
	)

	// AMER
	const [amerExists, setAMERExists] = useState(false)
	const [assignAMER, setAssignAMER] = useState(false)
	const [amerCarrierDescription, setAMERCarrierDescription] = useState('')
	const [amerCarrierDescriptionError, setAMERCarrierDescriptionError] =
		useState('')
	const [amerIP, setAMERIP] = useState('')
	const [amerIPError, setAMERIPError] = useState('')
	const [amerTrunkIPList, setAMERTrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingAMER, setLoadingAMER] = useState(false)

	// APAC
	const [apacExists, setAPACExists] = useState(false)
	const [assignAPAC, setAssignAPAC] = useState(false)
	const [apacCarrierDescription, setAPACCarrierDescription] = useState('')
	const [apacCarrierDescriptionError, setAPACCarrierDescriptionError] =
		useState('')
	const [apacIP, setAPACIP] = useState('')
	const [apacIPError, setAPACIPError] = useState('')
	const [apacTrunkIPList, setAPACTrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingAPAC, setLoadingAPAC] = useState(false)

	// EMEA
	const [emeaExists, setEMEAExists] = useState(false)
	const [assignEMEA, setAssignEMEA] = useState(false)
	const [emeaCarrierDescription, setEMEACarrierDescription] = useState('')
	const [emeaCarrierDescriptionError, setEMEACarrierDescriptionError] =
		useState('')
	const [emeaIP, setEMEAIP] = useState('')
	const [emeaIPError, setEMEAIPError] = useState('')
	const [emeaTrunkIPList, setEMEATrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingEMEA, setLoadingEMEA] = useState(false)

	// Dialog
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogDescription, setDialogDescription] = useState('')

	// ********************************* END Display constants ********************************* //

	// Flags
	const [trunkCallMade, setTrunkCallMade] = useState(false)
	const [existingBYOC, setExistingBYOC] = useState(false)
	const [trunkGroupID, setTrunkGroupID] = useState('')
	const [trunkGroup, setTrunkGroup] = useState({} as TrunkGroup)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [regionToDelete, setRegionToDelete] = useState('')
	const [addLoading, setAddLoading] = useState(false)
	const [deleteAllLoading, setDeleteAllLoading] = useState(false)
	const [deleteAMERLoading, setDeleteAMERLoading] = useState(false)
	const [deleteAPACLoading, setDeleteAPACLoading] = useState(false)
	const [deleteEMEALoading, setDeleteEMEALoading] = useState(false)
	const [trunkGroupQuantity, setTrunkGroupQuantity] = useState(0)

	// General
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		// Assign page title
		if (pageTitle !== 'Add Trunk(s)') {
			dispatch(setPageTitle('Add Trunk(s)'))
		}

		if (customerID && customerID.length > 0) {
			// Make API call
			if (!postAndRetrieveDataLoading) {
				if (!trunkCallMade) {
					setTrunkCallMade(true)
					checkForCustomerAndExistingTrunkGroup()
				}
			}
		} else {
			navigate('/')
			showErrorToast('There was no customerID found. Please try again.')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		customerID,
		postAndRetrieveDataLoading,
		postAndRetrieveDataError,
		apiPostWithoutRefetchError,
		postWithoutRefetchLoading,
		postSREDataLoading,
		postSREConfigurationLoading,
		postQueueConfigureSBCRequestLoading,
		isLoadingDecrementSBCVirtualIPRealmCount,
		// Flags
		trunkCallMade,
		amerExists,
		apacExists,
		emeaExists,
		isLoadingIncrementSBCVirtualIPRealmCount,
	])

	// *************************************** API Calls *************************************** //

	// GET: Check for existing BYOC trunk group
	const checkForCustomerAndExistingTrunkGroup = async () => {
		// Make API Call to return trunks and check if a BYOC exists
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer and Trunk Groups',
			`Customer.Order.OrderSKU.Where(Customer.CustomerID = '${customerID}' & OrderSKU.SKUTypeID ^ 'VC-DR-SIP-SER-BAS,VC-TEAMS-MANG-DEP-UC'), TrunkGroup.CustomerTrunkGroupIP.CustomerTrunkGroupRegionMap.Includes(TrunkGroup.CustomerID = '${customerID}'), RegionDataCentre.DataCentre.SBCVirtualIPDataCentre.SBCVirtualIP.All(), TrunkType.All(), ActionLog.First(ActionLog.CustomerID = '${customerID}' & ActionLog.OrderSKUID = '${orderSKUIDForTrunk}' & ActionLog.ProductID ^ 'BAS-BYOC, BYOC'))`,
			false,
			true
		)

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign customer
			var customerResponse = dataResponse.Obj.CustomerList[0] as Customer

			if (customerResponse && customerResponse.CustomerName) {
				setCustomerName(customerResponse.CustomerName)

				if (customerResponse.CustomerPartnerReference) {
					setBizOrg(customerResponse.CustomerPartnerReference)
				}

				// Get total number of seats for VC SKUS to use as trunk Quantity if creating new trunkGroup
				var customerOrdersRespone = customerResponse.OrderList as Order[]
				if (customerOrdersRespone && customerOrdersRespone.length > 0) {
					var vcOrderSKUS: OrderSKU[] = []
					for (let i = 0; i < customerOrdersRespone.length; i++) {
						if (customerOrdersRespone[i].OrderSKUList) {
							vcOrderSKUS = vcOrderSKUS.concat(
								customerOrdersRespone[i].OrderSKUList as OrderSKU[]
							)
						}
					}
					var totalNumOfSeats = 0
					for (let i = 0; i < vcOrderSKUS.length; i++) {
						totalNumOfSeats += Number(vcOrderSKUS[i].Quantity)
					}
					setTrunkGroupQuantity(totalNumOfSeats)
				}
			}

			// Assign region data centre list
			var regionDataCentreResponse = dataResponse.Obj
				.RegionDataCentreList as RegionDataCentre[]

			if (regionDataCentreResponse && regionDataCentreResponse.length > 0) {
				setRegionDataCentreList(regionDataCentreResponse)
			}

			//  Assign Trunk Type list
			var trunkTypeResponse = dataResponse.Obj.TrunkTypeList as TrunkType[]
			if (trunkTypeResponse && trunkTypeResponse.length > 0) {
				setTrunkTypeList(trunkTypeResponse)
			}

			// Assign array to check for BYOC and get the latest index if there is no BYOC
			var trunkGroupResponse = dataResponse.Obj.TrunkGroupList as TrunkGroup[]

			if (trunkGroupResponse && trunkGroupResponse.length > 0) {
				// Check for BYOC
				var trunkGroupIDToUse = ''
				var existingBYOC = false

				var actionLogResponse = dataResponse.Obj.ActionLog as ActionLog
				if (
					actionLogResponse &&
					actionLogResponse.ActionLogID &&
					(actionLogResponse.Data ?? '').length > 0
				) {
					// If BYOC exists, get the trunk group ID to use
					trunkGroupIDToUse = actionLogResponse.Data + ''
				}

				var byoc
				if (trunkGroupIDToUse.length > 0) {
					// Existing BYOC
					byoc = trunkGroupResponse.find(
						(t) => t.TrunkGroupID === trunkGroupIDToUse
					)
				} else {
					// need to check this logic -
					//if trunkGroupIDToUse is empty, then we need to find the BYOC trunkGroupID
					byoc = trunkGroupResponse.find(
						(t) => Number(t.TrunkTypeID) === TrunkTypes.BYOC
					)
				}
				if (byoc && byoc.TrunkGroupID) {
					// Existing BYOC
					setTrunkGroup(byoc)
					existingBYOC = true
					trunkGroupIDToUse = byoc.TrunkGroupID

					// Then loop through the trunk IPs and regions, check which ones exist already
					var customerTrunkGroupIPList = byoc.CustomerTrunkGroupIPList

					if (customerTrunkGroupIPList && customerTrunkGroupIPList.length > 0) {
						setCustomerTrunkGroupIPs(customerTrunkGroupIPList)
						// Check which regions exists for the alert
						var amerAlert = ''
						var apacAlert = ''
						var emeaAlert = ''
						var amerExists = false
						var apacExists = false
						var emeaExists = false
						// Trunk IP IDs
						var amerIPIDList = [] as string[]
						var apacIPIDList = [] as string[]
						var emeaIPIDList = [] as string[]
						// Loop through the IP list
						for (let cti = 0; cti < customerTrunkGroupIPList.length; cti++) {
							// Region Map List - Check which region the IP belongs to
							var regionMapList =
								customerTrunkGroupIPList[cti].CustomerTrunkGroupRegionMapList

							if (regionMapList && regionMapList.length > 0) {
								// AMER
								var isAMERIP = regionMapList.find(
									(amerIP) => Number(amerIP.RegionID) === RegionOptions.AMER
								)
								if (isAMERIP) {
									// Push to ID list
									amerIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									amerAlert = 'AMER'
									amerExists = true
								}
								// APAC
								var isAPACIP = regionMapList.find(
									(apacIP) => Number(apacIP.RegionID) === RegionOptions.APAC
								)
								if (isAPACIP) {
									// Push to ID list
									apacIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									apacAlert = 'APAC'
									apacExists = true
								}
								// EMEA
								var isEMEAIP = regionMapList.find(
									(emeaIP) => Number(emeaIP.RegionID) === RegionOptions.EMEA
								)
								if (isEMEAIP) {
									// Push to ID list
									emeaIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									emeaAlert = 'EMEA'
									emeaExists = true
								}
							}
						}
						// Set Exists
						setAMERExists(amerExists)
						setAPACExists(apacExists)
						setEMEAExists(emeaExists)

						// Alert message
						var regions = [amerAlert, apacAlert, emeaAlert]
							.filter(Boolean)
							.join(', ')
						var alert = `Please note the ${regions} region(s) have been assigned already and cannot be configured again. If you wish to do this, you will need to remove the existing region assigned trunk IPs for this customer.`
						setAlertMessage(alert)

						// Loop through the ID Lists built and build the display list
						// Trunk IP List to build
						var amerTrunkIPList = [] as TrunkIPListDisplay[]
						var apacTrunkIPList = [] as TrunkIPListDisplay[]
						var emeaTrunkIPList = [] as TrunkIPListDisplay[]
						var regionIPToFind = {} as CustomerTrunkGroupIP | undefined
						var amerCarrierDescription = ''
						var apacCarrierDescription = ''
						var emeaCarrierDescription = ''
						// AMER
						if (amerAlert.length > 0 && amerIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let amer = 0; amer < amerIPIDList.length; amer++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === amerIPIDList[amer]
								)
								if (regionIPToFind) {
									amerTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									amerCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}
						// APAC
						if (apacAlert.length > 0 && apacIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let apac = 0; apac < apacIPIDList.length; apac++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === apacIPIDList[apac]
								)
								if (regionIPToFind) {
									apacTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									apacCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}
						// EMEA
						if (emeaAlert.length > 0 && emeaIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let emea = 0; emea < emeaIPIDList.length; emea++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === emeaIPIDList[emea]
								)
								if (regionIPToFind) {
									emeaTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									emeaCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}

						// Set Lists and Objects
						setAMERTrunkIPList(amerTrunkIPList)
						setAPACTrunkIPList(apacTrunkIPList)
						setEMEATrunkIPList(emeaTrunkIPList)
						setAMERCarrierDescription(amerCarrierDescription)
						setAPACCarrierDescription(apacCarrierDescription)
						setEMEACarrierDescription(emeaCarrierDescription)
					} else {
						setAlertMessage('')
						setTrunkGroupID(trunkGroupIDToUse)
						clearArrays()
					}
				} else {
					// If no BYOC found, loop through to get the latest index
					var trunkIndexToUse = 1
					var maxTrunkIndex = 0
					for (let tg = 0; tg < trunkGroupResponse.length; tg++) {
						// If BYPT, then ignore
						if (
							Number(trunkGroupResponse[tg].TrunkTypeID) !== TrunkTypes.BYPT
						) {
							// Split trunk group ID by -
							var trunkGroupIDSplit =
								trunkGroupResponse[tg].TrunkGroupID?.split('-')
							if (trunkGroupIDSplit) {
								if (Number(trunkGroupIDSplit[1]) > maxTrunkIndex) {
									maxTrunkIndex = Number(trunkGroupIDSplit[1])
								}
							}
						}
					}
					// Indent new index
					trunkIndexToUse += maxTrunkIndex
					trunkGroupIDToUse = `SPT${customerID}-${trunkIndexToUse}`
					setAlertMessage('')
					clearArrays()
				}

				// Set values
				setExistingBYOC(existingBYOC)
				setTrunkGroupID(trunkGroupIDToUse)
			} else {
				// If no trunk groups were found, assign a new trunk group ID to use
				setExistingBYOC(false)
				setAlertMessage('')
				setTrunkGroupID(`SPT${customerID}-1`)
				clearArrays()
			}
		}

		// API Call Done
		setAPICallsDone(true)
	}

	// GET: Check for Duplicate IP Address in the DB
	const checkForDuplicateIPAddress = async (ip: string) => {
		// Make API Call to check DB if duplicate
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk IP',
			`CustomerTrunkGroupIP.Exists(CustomerTrunkGroupIP.CustomerTrunkIP = '${ip}')`,
			false,
			true
		)
		if (dataResponse && dataResponse.Obj && dataResponse.Obj === true) {
			return false
		} else {
			return true
		}
	}

	// GET: Check for Duplicate IP Address in the DB
	const checkForDuplicateCarrierDescription = async () => {
		// Make API Call to check DB if duplicate
		var valid = true
		if (assignAMER) {
			// API Call
			var amerResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${amerCarrierDescription}')`,
				false,
				true
			)
			if (amerResponse && amerResponse.Obj && amerResponse.Obj === true) {
				valid = false
				setAMERCarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}
		if (assignAPAC) {
			// API Call
			var apacResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${apacCarrierDescription}')`,
				false,
				true
			)
			if (apacResponse && apacResponse.Obj && apacResponse.Obj === true) {
				valid = false
				setAPACCarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}
		if (assignEMEA) {
			// API Call
			var emeaResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${emeaCarrierDescription}')`,
				false,
				true
			)
			if (emeaResponse && emeaResponse.Obj && emeaResponse.Obj === true) {
				valid = false
				setEMEACarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}

		return valid
	}

	// GET: DDI Range and Trunk Mapping
	const getDDIRangeTrunkMapping = async (region: string) => {
		// List to return
		var ddiRangeTrunkMapList = [] as DDIRangeTrunkMap[]

		// Variables
		var trunkExists = {} as DDIRangeTrunkMap | undefined
		var ddiRangeTrunkMapObj = {} as DDIRangeTrunkMap

		// Make call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk IP',
			`DDIRangeTrunkMap.Where(DDIRangeTrunkMap.CustomerTrunkGroupIPID ~ '${trunkGroupID}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var ddiRangeTrunkMappingResponse = dataResponse.Obj
				.DDIRangeTrunkMapList as DDIRangeTrunkMap[]

			if (
				ddiRangeTrunkMappingResponse &&
				ddiRangeTrunkMappingResponse.length > 0
			) {
				// Check which regions
				switch (region) {
					case 'ALL':
						ddiRangeTrunkMapList = ddiRangeTrunkMappingResponse
						break

					case 'AMER':
						if (amerExists) {
							// Loop through the amer trunk IP List
							for (
								let amer = 0;
								amer < ddiRangeTrunkMappingResponse.length;
								amer++
							) {
								// Check if ID exists
								trunkExists = amerTrunkIPList.find(
									(a) =>
										a.CustomerTrunkGroupIPID + '' ===
										ddiRangeTrunkMappingResponse[amer].CustomerTrunkGroupIPID +
											''
								)

								if (trunkExists) {
									// Object to build
									ddiRangeTrunkMapObj = {
										DDIRangeTrunkMapID:
											ddiRangeTrunkMappingResponse[amer].DDIRangeTrunkMapID,
										CustomerTrunkGroupIPID:
											ddiRangeTrunkMappingResponse[amer].CustomerTrunkGroupIPID,
										DDIRangeID: ddiRangeTrunkMappingResponse[amer].DDIRangeID,
									}
									ddiRangeTrunkMapList.push(ddiRangeTrunkMapObj)
								}
							}
						}
						break

					case 'APAC':
						if (apacExists) {
							// Loop through the amer trunk IP List
							for (
								let apac = 0;
								apac < ddiRangeTrunkMappingResponse.length;
								apac++
							) {
								// Check if ID exists
								trunkExists = apacTrunkIPList.find(
									(a) =>
										a.CustomerTrunkGroupIPID + '' ===
										ddiRangeTrunkMappingResponse[apac].CustomerTrunkGroupIPID +
											''
								)

								if (trunkExists) {
									// Object to build
									ddiRangeTrunkMapObj = {
										DDIRangeTrunkMapID:
											ddiRangeTrunkMappingResponse[apac].DDIRangeTrunkMapID,
										CustomerTrunkGroupIPID:
											ddiRangeTrunkMappingResponse[apac].CustomerTrunkGroupIPID,
										DDIRangeID: ddiRangeTrunkMappingResponse[apac].DDIRangeID,
									}
									ddiRangeTrunkMapList.push(ddiRangeTrunkMapObj)
								}
							}
						}
						break

					case 'EMEA':
						if (emeaExists) {
							// Loop through the amer trunk IP List
							for (
								let emea = 0;
								emea < ddiRangeTrunkMappingResponse.length;
								emea++
							) {
								// Check if ID exists
								trunkExists = emeaTrunkIPList.find(
									(e) =>
										e.CustomerTrunkGroupIPID + '' ===
										ddiRangeTrunkMappingResponse[emea].CustomerTrunkGroupIPID +
											''
								)

								if (trunkExists) {
									// Object to build
									ddiRangeTrunkMapObj = {
										DDIRangeTrunkMapID:
											ddiRangeTrunkMappingResponse[emea].DDIRangeTrunkMapID,
										CustomerTrunkGroupIPID:
											ddiRangeTrunkMappingResponse[emea].CustomerTrunkGroupIPID,
										DDIRangeID: ddiRangeTrunkMappingResponse[emea].DDIRangeID,
									}
									ddiRangeTrunkMapList.push(ddiRangeTrunkMapObj)
								}
							}
						}
						break
				}
			}
		}

		return ddiRangeTrunkMapList
	}

	// GET: FQDN Mappings to delete
	const getFQDNMapping = async (region: string) => {
		// List to return
		var customerTrunkGroupFQDNMapList = [] as CustomerTrunkGroupFQDNMap[]

		// Variables
		var fqdnMapExists = {} as CustomerTrunkGroupFQDNMap | undefined
		var customerTrunkGroupFQDNMapObj = {} as CustomerTrunkGroupFQDNMap

		// Make call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk FQDN Mappings',
			`CustomerTrunkGroupFQDNMap.Where(CustomerTrunkGroupFQDNMap.CustomerTrunkGroupIPID ~ '${trunkGroupID}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerTrunkGroupFQDNMapResponse = dataResponse.Obj
				.CustomerTrunkGroupFQDNMapList as CustomerTrunkGroupFQDNMap[]

			if (
				customerTrunkGroupFQDNMapResponse &&
				customerTrunkGroupFQDNMapResponse.length > 0
			) {
				// Check which region
				switch (region) {
					case 'ALL':
						customerTrunkGroupFQDNMapList = customerTrunkGroupFQDNMapResponse
						break

					case 'AMER':
						if (amerExists) {
							// Loop through the amer trunk IP List
							for (
								let amer = 0;
								amer < customerTrunkGroupFQDNMapResponse.length;
								amer++
							) {
								// Check if ID exists
								fqdnMapExists = amerTrunkIPList.find(
									(a) =>
										a.CustomerTrunkGroupIPID + '' ===
										customerTrunkGroupFQDNMapResponse[amer]
											.CustomerTrunkGroupIPID +
											''
								)

								if (fqdnMapExists) {
									// Object to build
									customerTrunkGroupFQDNMapObj = {
										CustomerTrunkGroupFQDNMapID:
											customerTrunkGroupFQDNMapResponse[amer]
												.CustomerTrunkGroupFQDNMapID,
										CustomerTrunkGroupIPID:
											customerTrunkGroupFQDNMapResponse[amer]
												.CustomerTrunkGroupIPID + '',
										FQDNID: customerTrunkGroupFQDNMapResponse[amer].FQDNID,
									}
									customerTrunkGroupFQDNMapList.push(
										customerTrunkGroupFQDNMapObj
									)
								}
							}
						}
						break

					case 'APAC':
						if (apacExists) {
							// Loop through the amer trunk IP List
							for (
								let apac = 0;
								apac < customerTrunkGroupFQDNMapResponse.length;
								apac++
							) {
								// Check if ID exists
								fqdnMapExists = apacTrunkIPList.find(
									(a) =>
										a.CustomerTrunkGroupIPID + '' ===
										customerTrunkGroupFQDNMapResponse[apac]
											.CustomerTrunkGroupIPID +
											''
								)

								if (fqdnMapExists) {
									// Object to build
									customerTrunkGroupFQDNMapObj = {
										CustomerTrunkGroupFQDNMapID:
											customerTrunkGroupFQDNMapResponse[apac]
												.CustomerTrunkGroupFQDNMapID,
										CustomerTrunkGroupIPID:
											customerTrunkGroupFQDNMapResponse[apac]
												.CustomerTrunkGroupIPID + '',
										FQDNID: customerTrunkGroupFQDNMapResponse[apac].FQDNID,
									}
									customerTrunkGroupFQDNMapList.push(
										customerTrunkGroupFQDNMapObj
									)
								}
							}
						}
						break

					case 'EMEA':
						if (emeaExists) {
							// Loop through the amer trunk IP List
							for (
								let emea = 0;
								emea < customerTrunkGroupFQDNMapResponse.length;
								emea++
							) {
								// Check if ID exists
								fqdnMapExists = emeaTrunkIPList.find(
									(e) =>
										e.CustomerTrunkGroupIPID + '' ===
										customerTrunkGroupFQDNMapResponse[emea]
											.CustomerTrunkGroupIPID +
											''
								)

								if (fqdnMapExists) {
									// Object to build
									customerTrunkGroupFQDNMapObj = {
										CustomerTrunkGroupFQDNMapID:
											customerTrunkGroupFQDNMapResponse[emea]
												.CustomerTrunkGroupFQDNMapID,
										CustomerTrunkGroupIPID:
											customerTrunkGroupFQDNMapResponse[emea]
												.CustomerTrunkGroupIPID + '',
										FQDNID: customerTrunkGroupFQDNMapResponse[emea].FQDNID,
									}
									customerTrunkGroupFQDNMapList.push(
										customerTrunkGroupFQDNMapObj
									)
								}
							}
						}
						break
				}
			}
		}

		return customerTrunkGroupFQDNMapList
	}

	// POST: Add new Trunk Group/Add Trunk IPs to existing BYOC
	const addTrunks = async () => {
		// Loading true for add button
		setAddLoading(true)
		var error = false

		// Make API call first to ensure the carrier description does not exist
		var isValidCarrierDescriptions = await checkForDuplicateCarrierDescription()

		if (isValidCarrierDescriptions) {
			// Ensure the trunk group ID is populated
			if (trunkGroupID && trunkGroupID.length > 0) {
				// Check which region(s) are being assigned
				if (assignAMER || assignAPAC || assignEMEA) {
					// IP and Region List to build
					var regionTrunkIPList = [] as RegionTrunkIPList[]
					var regionTrunkIPObj = {} as RegionTrunkIPList

					// AMER
					if (assignAMER) {
						// Generate the IP List with the regions
						for (let amer = 0; amer < amerTrunkIPList.length; amer++) {
							// AMER IP Object
							regionTrunkIPObj = {
								CustomerTrunkIP: amerTrunkIPList[amer].CustomerTrunkIP,
								CarrierDescription: amerCarrierDescription,
								RegionID: Number(RegionOptions.AMER),
							}
							regionTrunkIPList.push(regionTrunkIPObj)
						}
					}
					// APAC
					if (assignAPAC) {
						// Generate the IP List with the regions
						for (let apac = 0; apac < apacTrunkIPList.length; apac++) {
							// AMER IP Object
							regionTrunkIPObj = {
								CustomerTrunkIP: apacTrunkIPList[apac].CustomerTrunkIP,
								CarrierDescription: apacCarrierDescription,
								RegionID: Number(RegionOptions.APAC),
							}
							regionTrunkIPList.push(regionTrunkIPObj)
						}
					}
					// EMEA
					if (assignEMEA) {
						// Generate the IP List with the regions
						for (let emea = 0; emea < emeaTrunkIPList.length; emea++) {
							// AMER IP Object
							regionTrunkIPObj = {
								CustomerTrunkIP: emeaTrunkIPList[emea].CustomerTrunkIP,
								CarrierDescription: emeaCarrierDescription,
								RegionID: Number(RegionOptions.EMEA),
							}
							regionTrunkIPList.push(regionTrunkIPObj)
						}
					}

					// Lists to build for adding
					var customerTrunkGroupIPList = [] as CustomerTrunkGroupIP[]
					var internalTrunkGroupIPList = [] as InternalTrunkGroupIP[]
					var customerTrunkGroupRegionMapList =
						[] as CustomerTrunkGroupRegionMap[]
					var fqdnList = [] as FQDN[]
					var sbcVirtualIPs: SBCVirtualIP[] = []

					// Loop through region IP list and generate the add lists and objects
					if (regionTrunkIPList && regionTrunkIPList.length > 0) {
						// First check for any existing IPs, then get the latest letter to use in the ID
						var indexToStartAt = getLatestTrunkIPIndex()
						for (let ip = 0; ip < regionTrunkIPList.length; ip++) {
							// Customer IPs
							var letterToUse = numberToChar(indexToStartAt + ip)
							var customerTrunkGroupIPID = `${trunkGroupID}-${letterToUse}`

							// Customer IP Object
							var customerTrunkGroupIPObj: CustomerTrunkGroupIP = {
								CustomerTrunkGroupIPID: customerTrunkGroupIPID,
								TrunkGroupID: trunkGroupID,
								CustomerTrunkIP: regionTrunkIPList[ip].CustomerTrunkIP,
								CustomerTrunkIPName: regionTrunkIPList[ip].CarrierDescription,
								InternetProtocolID: InternetProtocols.UDP,
								Port: '5060',
							}
							customerTrunkGroupIPList.push(customerTrunkGroupIPObj)

							// Internal IPs
							//get region of IPLists added and BYOC trunktype object
							var regionDataCentres = regionDataCentreList.filter(
								(r) =>
									Number(r.RegionID) === Number(regionTrunkIPList[ip].RegionID)
							)
							var trunkTypeObject = trunkTypeList.find(
								(i) => Number(i.TrunkTypeID) === TrunkTypes.BYOC
							)

							// 2: region data centre IDs and trunk type to get SBC Virtual IPs
							if (
								regionDataCentres &&
								regionDataCentres.length > 0 &&
								trunkTypeObject
							) {
								// Loop through data centres to add to internal IP list
								for (let rdc = 0; rdc < regionDataCentres.length; rdc++) {
									var _dataCentre = regionDataCentres[rdc].DataCentre

									//Check 2 - Check for SBC type and realmcount
									if (_dataCentre) {
										var _sbcVirtualIPDataCentreList =
											_dataCentre.SBCVirtualIPDataCentreList?.filter(
												(i) =>
													Number(i.SBCVirtualIP?.ServiceTypeID) ===
														Number(trunkTypeObject?.ServiceTypeID) &&
													Number(i.SBCVirtualIP?.RealmCount) <=
														Number(process.env.REACT_APP_SBC_REALM_LIMIT)
											)
										if (
											_sbcVirtualIPDataCentreList &&
											_sbcVirtualIPDataCentreList.length > 0
										) {
											var sbcVirtualIPDataCentre: SBCVirtualIPDataCentre = {}
											var sbcDataCentreListSortedByRealmCount =
												_sbcVirtualIPDataCentreList.sort(
													(
														sbcVirtualIPDataCentre1,
														sbcVirtualIPDataCentre2
													) => {
														if (
															Number(
																sbcVirtualIPDataCentre1.SBCVirtualIP?.RealmCount
															) >
															Number(
																sbcVirtualIPDataCentre2.SBCVirtualIP?.RealmCount
															)
														) {
															return -1
														}
														if (
															Number(
																sbcVirtualIPDataCentre1.SBCVirtualIP?.RealmCount
															) <
															Number(
																sbcVirtualIPDataCentre2.SBCVirtualIP?.RealmCount
															)
														) {
															return 1
														}

														return 0
													}
												)

											//Using the first SBCVirtualIP as currently only one in the database.
											//set sbcVirtualIPDataCentre
											sbcVirtualIPDataCentre =
												sbcDataCentreListSortedByRealmCount[0]

											if (
												Number(
													sbcVirtualIPDataCentre?.SBCVirtualIPDataCentreID
												) > 0
											) {
												if (
													//check it is not there already
													!internalTrunkGroupIPList.find(
														(i) =>
															Number(i.SBCVirtualIPDataCentreID) ===
															Number(
																sbcVirtualIPDataCentre.SBCVirtualIPDataCentreID
															)
													)
												) {
													// Push to list
													internalTrunkGroupIPList.push({
														CustomerTrunkGroupIPID: customerTrunkGroupIPID,
														SBCVirtualIPDataCentreID: Number(
															sbcVirtualIPDataCentre.SBCVirtualIPDataCentreID
														),
														CustomIP: '',
													})
													if (sbcVirtualIPDataCentre.SBCVirtualIP) {
														sbcVirtualIPs.push(
															sbcVirtualIPDataCentre.SBCVirtualIP
														)
													}
												}
											}
										} else {
											var sbcVirtualIPRealmCountErrorMessage =
												'An error occurred when adding trunk group. No SBC Virtual IP with realm count less than limit found.'
											showErrorToast(sbcVirtualIPRealmCountErrorMessage)
											// Log error to DB
											await addErrorLog(
												'Adding Trunk Group',
												sbcVirtualIPRealmCountErrorMessage
											)
											// Stop loading for add button
											setAddLoading(false)
											return
										}
									}
								}
							}

							// Region Mapping Object
							var customerTrunkGroupRegionMapObj: CustomerTrunkGroupRegionMap =
								{
									CustomerTrunkGroupIPID: customerTrunkGroupIPID,
									RegionID: regionTrunkIPList[ip].RegionID,
									SBCConfigurationStatusID: 1,
								}
							customerTrunkGroupRegionMapList.push(
								customerTrunkGroupRegionMapObj
							)

							// FQDN
							var fqdn1 = `${trunkGroupID}.${RegionOptions[
								Number(regionTrunkIPList[ip].RegionID)
							].toLowerCase()}1drms.halo.sipcom.cloud`
							var fqdn2 = `${trunkGroupID}.${RegionOptions[
								Number(regionTrunkIPList[ip].RegionID)
							].toLowerCase()}2drms.halo.sipcom.cloud`

							var fqdnExists =
								fqdnList.find((f) => f.FQDN + '' === fqdn1) ||
								fqdnList.find((f) => f.FQDN + '' === fqdn2)

							if (!fqdnExists) {
								// FQDN Objects
								var fqdn1Obj: FQDN = {
									FQDN: fqdn1,
									CustomerID: customerID,
								}
								var fqdn2Obj: FQDN = {
									FQDN: fqdn2,
									CustomerID: customerID,
								}
								fqdnList.push(fqdn1Obj, fqdn2Obj)
							}
						}
					}

					// Object and request URL to build
					var addTrunkObj = {}
					var requestURL = ''
					// After all lists are generated, check if we creating a new trunk group and adding to an existing one
					var trunkGroupObj = {} as TrunkGroup
					if (existingBYOC) {
						// Add to existing trunk group
						requestURL =
							'AddV2?Params=CustomerTrunkGroupIP:list,InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,FQDN:list'
						addTrunkObj = {
							CustomerTrunkGroupIPList: customerTrunkGroupIPList,
							InternalTrunkGroupIPList: internalTrunkGroupIPList,
							CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
							FQDNList: fqdnList,
						}
					} else {
						var serviceToAdd: Service = {
							ServiceID: trunkGroupID,
							ServiceTypeID: ServiceTypes.BYOC,
						}
						// Add new Trunk Group Object
						trunkGroupObj = {
							CustomerID: customerID,
							TrunkGroupID: trunkGroupID,
							TrunkTypeID: TrunkTypes.BYOC,
							Quantity: trunkGroupQuantity,
						}

						requestURL =
							'AddV2?Params=TrunkGroup,CustomerTrunkGroupIP:list,InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,FQDN:list,Service'
						addTrunkObj = {
							TrunkGroup: trunkGroupObj,
							CustomerTrunkGroupIPList: customerTrunkGroupIPList,
							InternalTrunkGroupIPList: internalTrunkGroupIPList,
							CustomerTrunkGroupRegionMapList: customerTrunkGroupRegionMapList,
							FQDNList: fqdnList,
							Service: serviceToAdd,
						}
					}

					// Post to DB
					var postHookObj: PostHookObj = {
						Action: 'Add Trunks',
						LoggedInUser: loggedInUser,
						RequestURL: requestURL,
						RequestObj: addTrunkObj,
						ShowSuccessMessage: false,
						ShowErrorMessage: true,
						ErrorMessage: 'An error occurred when adding trunks',
						LogErrorToDB: true,
					}

					await postUpdateDataWithoutRefetch(postHookObj)

					// Ensure no error occurred
					if (!apiPostWithoutRefetchError) {
						// Call to return the FQDNs that were generated and map accordingly
						await addFQDNMapping(customerTrunkGroupRegionMapList)

						// Call to increment SBCVirtualIP RealCount
						// Call to increment Trunks
						// Only increment realm count for first Trunk in data centre
						var uniqueSBCVirtualIPs: SBCVirtualIP[] = []

						for (let i = 0; i < sbcVirtualIPs.length; i++) {
							var sbcVirtualIP = sbcVirtualIPs[i]
							if (
								!uniqueSBCVirtualIPs.find(
									(x) =>
										Number(sbcVirtualIP.SBCVirtualIPID) ===
										Number(x.SBCVirtualIPID)
								)
							) {
								if (sbcVirtualIP) {
									uniqueSBCVirtualIPs.push(sbcVirtualIP)
								}
							}
						}
						var sbcVirtualIPsToIncrement = ''

						uniqueSBCVirtualIPs.forEach((uniqueSBCVirtualIP) => {
							sbcVirtualIPsToIncrement += `,${uniqueSBCVirtualIP.SBCVirtualIPID}`
						})

						if (sbcVirtualIPsToIncrement.length > 1) {
							// Update Realm Count for relevant sbcVirtualIPs
							await postIncrementSBCVirtualIPRealmCount(
								toBetaString(sbcVirtualIPsToIncrement.substring(1))
							)
								.unwrap()
								.catch(async (errorResponse) => {
									// Log to DB
									await addErrorLog(
										'An error occurred when trying to increment the SBC Realm Count',
										errorResponse?.data + ''
									)
									showErrorToast(
										'An error occurred when trying to increment the SBC Realm Count'
									)
								})
						} else {
							showErrorToast(
								'An error occurred when attempting to increment the SBC Realm Count'
							)
						}

						// If all is finalized, do action MGT logic
						await handleTrunkActionMGT(
							customerID + '',
							trunkGroupID,
							orderSKUIDForTrunk
						)
					} else {
						error = true
					}
				}
			}

			// Make call to get latest data and show success message if no errors occurred
			if (!error) {
				checkForCustomerAndExistingTrunkGroup()
				resetVariables()
				showSuccessToast('Trunks added successfully')
			}
		}

		// Stop loading for add button
		setAddLoading(false)
	}

	const GetSBCVirtualIPDataCentresByService = async (regionIDs: string) => {
		var sbcVirtualIPs = ''

		var dataResponse = await postAndRetrieveDataFromDB(
			'SBC Virtual IP Data Centres By Service ',
			`SBCVirtualIPDataCentre.SBCVirtualIP.Where(SBCVirtualIP.ServiceTypeID = '${Services.BYOC}'), DataCentre.RegionDataCentre.Where(RegionDataCentre.RegionID ^ '${regionIDs}')`,
			false,
			false
		)

		var sbcVirtualIPList = [] as number[]
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var sbcVirtualIPDataCentreList = dataResponse.Obj
				.SBCVirtualIPDataCentreList as SBCVirtualIPDataCentre[]
			var dataCentreList = dataResponse.Obj.DataCentreList as DataCentre[]
			dataCentreList.forEach((dc) => {
				var sbcVirtualIPDataCentreListForDc = sbcVirtualIPDataCentreList.find(
					(s) => Number(s.DataCentreID) === Number(dc.DataCentreID)
				)
				if (sbcVirtualIPDataCentreListForDc) {
					if (
						!sbcVirtualIPList.includes(
							Number(sbcVirtualIPDataCentreListForDc?.SBCVirtualIPID)
						)
					) {
						sbcVirtualIPList.push(
							Number(sbcVirtualIPDataCentreListForDc?.SBCVirtualIPID)
						)
					}
				}
			})
			if (sbcVirtualIPList.length > 0) {
				sbcVirtualIPList.forEach((x) => {
					sbcVirtualIPs += `,${x}`
				})
				if (sbcVirtualIPs.length > 1) {
					sbcVirtualIPs = sbcVirtualIPs.substring(1)
				}
			}
		}

		return sbcVirtualIPs
	}

	// POST: Delete the trunk group/trunk IPs
	const deleteTrunks = async () => {
		// Change loading display
		handleDeleteDisplay()
		// Check what is being deleted
		var deleteAll = false
		var deleteAMER = false
		var deleteAPAC = false
		var deleteEMEA = false
		var trunkRegions = []

		switch (regionToDelete) {
			case 'ALL':
				deleteAll = true
				if (amerExists) {
					trunkRegions.push(1)
				}
				if (apacExists) {
					trunkRegions.push(2)
				}
				if (emeaExists) {
					trunkRegions.push(3)
				}

				break

			case 'AMER':
				trunkRegions.push(1)
				if (amerExists && !apacExists && !emeaExists) {
					deleteAll = true
				} else {
					deleteAMER = true
				}
				break

			case 'APAC':
				trunkRegions.push(2)
				if (!amerExists && apacExists && !emeaExists) {
					deleteAll = true
				} else {
					deleteAPAC = true
				}
				break

			case 'EMEA':
				trunkRegions.push(3)
				if (!amerExists && !apacExists && emeaExists) {
					deleteAll = true
				} else {
					deleteEMEA = true
				}
				break
		}

		var trunkGroupRegionIDs = ''
		var sbcVirtualIpsToDecrement = ''

		if (trunkRegions.length > 0) {
			trunkGroupRegionIDs = trunkRegions.join(',')
			sbcVirtualIpsToDecrement = await GetSBCVirtualIPDataCentresByService(
				trunkGroupRegionIDs
			)

			if (sbcVirtualIpsToDecrement.length < 1) {
				showErrorToast('Unable to get SBC Virtual IPs for selected Trunk Group')
				return
			}
		}

		// Check what to delete
		var ddiTrunkMapURL = ''
		var ipURL = ''
		var trunkURL = ''

		// Flags
		var containsDDIRangeMappings = false

		// Logic:
		// 1 - First check if there is an existing DDI Trunk Mapping
		// 2 - If there is an existing mapping, delete the mapping first
		// 3 - After the mapping is removed, delete the DDIs, then the DDI Range and then finally the trunk
		// 4 - If all is deleted, clear up SBC
		// NOTE: Tables to delete from: DDIRangeTrunkMap, DDI, DDIRange, FQDN, FQDNMap, RegionMap, Internal IPs, Customer IPs

		// Lists and Objects to build for deleting
		var ddiRangeTrunkMapList = [] as DDIRangeTrunkMap[]
		var customerTrunkGroupFQDNMapList = [] as CustomerTrunkGroupFQDNMap[]
		var ddiRangeIDList = [] as DDIRangeIDList[]
		var customerTrunkIPIDList = [] as CustomerTrunkIPIDList[]
		var fqdnIDList = [] as FQDNIDList[]

		if (deleteAll) {
			// Delete everything: Trunk Group, Trunk IPs, Regions, FQDNs and call SBC
			// Get the DDI Range Trunk Mapping
			ddiRangeTrunkMapList = await getDDIRangeTrunkMapping('ALL')

			// Get the FQDN Mappings
			customerTrunkGroupFQDNMapList = await getFQDNMapping('ALL')
		} else {
			// Delete only the Trunk IPs, Regions and FQDNs and call SBC for each region
			if (deleteAMER) {
				// Get the DDI Range Trunk Mapping
				ddiRangeTrunkMapList = await getDDIRangeTrunkMapping('AMER')

				// Get the FQDN Mappings
				customerTrunkGroupFQDNMapList = await getFQDNMapping('AMER')
			}
			if (deleteAPAC) {
				// Get the DDI Range Trunk Mapping
				ddiRangeTrunkMapList = await getDDIRangeTrunkMapping('APAC')

				// Get the FQDN Mappings
				customerTrunkGroupFQDNMapList = await getFQDNMapping('APAC')
			}
			if (deleteEMEA) {
				// Get the DDI Range Trunk Mapping
				ddiRangeTrunkMapList = await getDDIRangeTrunkMapping('EMEA')

				// Get the FQDN Mappings
				customerTrunkGroupFQDNMapList = await getFQDNMapping('EMEA')
			}
		}

		if (ddiRangeTrunkMapList && ddiRangeTrunkMapList.length > 0) {
			// If there is values, then delete the DDIRangeTrunkMaps, DDIs and DDIRanges
			containsDDIRangeMappings = true
			ddiTrunkMapURL = 'DDIRangeTrunkMap:list,DDI:list,DDIRange:list'

			for (let ddi = 0; ddi < ddiRangeTrunkMapList.length; ddi++) {
				// Check if not existing
				var ddiRangeIDExists = ddiRangeIDList.find(
					(d) => d.DDIRangeID + '' === ddiRangeTrunkMapList[ddi].DDIRangeID + ''
				)

				if (!ddiRangeIDExists) {
					ddiRangeIDList.push({
						DDIRangeID: ddiRangeTrunkMapList[ddi].DDIRangeID + '',
					})
				}
			}
		}

		// Check the FQDN Map list
		if (
			customerTrunkGroupFQDNMapList &&
			customerTrunkGroupFQDNMapList.length > 0
		) {
			// Loop through and build the lists to delete
			for (let map = 0; map < customerTrunkGroupFQDNMapList.length; map++) {
				// Customer Trunk IP ID List
				customerTrunkIPIDList.push({
					CustomerTrunkGroupIPID:
						customerTrunkGroupFQDNMapList[map].CustomerTrunkGroupIPID,
				})

				// FQDN ID List
				fqdnIDList.push({
					FQDNID: customerTrunkGroupFQDNMapList[map].FQDNID,
				})
			}
			// URL
			ipURL +=
				'InternalTrunkGroupIP:list,CustomerTrunkGroupRegionMap:list,CustomerTrunkGroupFQDNMap:list,FQDN:list,CustomerTrunkGroupIP:list'
		}

		var deleteTrunkObj = {}
		// If delete all, only remove IPs, not trunk group
		if (deleteAll) {
			if (containsDDIRangeMappings) {
				// If there is a DDI Mapping
				deleteTrunkObj = {
					DDIRangeTrunkMapList: ddiRangeTrunkMapList,
					DDIList: ddiRangeIDList,
					DDIRangeList: ddiRangeIDList,
					InternalTrunkGroupIPList: customerTrunkIPIDList,
					CustomerTrunkGroupRegionMapList: customerTrunkIPIDList,
					CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
					FQDNList: fqdnIDList,
					CustomerTrunkGroupIPList: customerTrunkIPIDList,
				}
			} else {
				// If no DDI mapping, only remove IPs
				deleteTrunkObj = {
					InternalTrunkGroupIPList: customerTrunkIPIDList,
					CustomerTrunkGroupRegionMapList: customerTrunkIPIDList,
					CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
					FQDNList: fqdnIDList,
					CustomerTrunkGroupIPList: customerTrunkIPIDList,
				}
			}
		} else {
			// If only a region is deleted with the IPs
			if (containsDDIRangeMappings) {
				// If there is a DDI Mapping
				deleteTrunkObj = {
					DDIRangeTrunkMapList: ddiRangeTrunkMapList,
					DDIList: ddiRangeIDList,
					DDIRangeList: ddiRangeIDList,
					InternalTrunkGroupIPList: customerTrunkIPIDList,
					CustomerTrunkGroupRegionMapList: customerTrunkIPIDList,
					CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
					FQDNList: fqdnIDList,
					CustomerTrunkGroupIPList: customerTrunkIPIDList,
				}
			} else {
				// If no DDI mapping, but with trunk group
				deleteTrunkObj = {
					InternalTrunkGroupIPList: customerTrunkIPIDList,
					CustomerTrunkGroupRegionMapList: customerTrunkIPIDList,
					CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
					FQDNList: fqdnIDList,
					CustomerTrunkGroupIPList: customerTrunkIPIDList,
				}
			}
		}
		// Request URL
		var combinedURL = [ddiTrunkMapURL, ipURL, trunkURL]
			.filter(Boolean)
			.join(',')
		var requestURL = `DeleteV2?Params=${combinedURL}`

		// Call to delete now
		// Post to DB
		var postHookObj: PostHookObj = {
			Action: 'Delete Trunks',
			LoggedInUser: loggedInUser,
			RequestURL: requestURL,
			RequestObj: deleteTrunkObj,
			ShowSuccessMessage: false,
			ShowErrorMessage: true,
			ErrorMessage: 'An error occurred when deleting trunks',
			LogErrorToDB: true,
		}

		var deleteSuccess = true

		await postUpdateDataWithoutRefetch(postHookObj).catch(async (error) => {
			if (error) {
				deleteSuccess = false
			}
		})

		if (deleteSuccess) {
			// Update Realm Count for relevant sbcVirtualIPs
			await postDecrementSBCVirtualIPRealmCount(
				toBetaString(sbcVirtualIpsToDecrement)
			)
				.unwrap()
				.then(() => {
					showSuccessToast('Trunk Group deleted successfully')
				})
				.catch(async (errorResponse) => {
					// Log to DB
					await addErrorLog(
						'An error occurred when trying to decrement the SBC Realm Count ',
						errorResponse?.data + ''
					)
					showErrorToast(
						'An error occurred when trying to decrement the SBC Realm Count'
					)
				})
		}
		if (!apiPostWithoutRefetchError && deleteSuccess) {
			// Once deleted, clean up the SBCs
			var sbcRemoveSuccess = false
			var message = ''
			var customerTrunkGroupIPsToDelete = customerTrunkGroupIPs.filter(
				(trunkGroupIP) =>
					customerTrunkIPIDList.filter(
						(trunkGroupIPID) =>
							trunkGroupIPID.CustomerTrunkGroupIPID + '' ===
							trunkGroupIP.CustomerTrunkGroupIPID + ''
					).length > 0
			)

			// Generate model for SBCs
			var sbcTrunkIPModels: SBCTrunkIPModel[] = []
			customerTrunkGroupIPsToDelete.forEach((customerTrunkGroupIP) => {
				sbcTrunkIPModels.push({
					CustomerTrunkIP: customerTrunkGroupIP.CustomerTrunkIP + '',
					InternetProtocolID: Number(customerTrunkGroupIP.InternetProtocolID),
					Port: customerTrunkGroupIP.Port + '',
				})
			})

			if (deleteAll) {
				// Remove all SBCs
				sbcRemoveSuccess = await removeSBCConfiguration('ALL', sbcTrunkIPModels)
				message = `The trunks for this customer have been removed successfully`
			} else {
				if (deleteAMER) {
					sbcRemoveSuccess = await removeSBCConfiguration(
						'AMER',
						sbcTrunkIPModels
					)
					message = `The AMER trunks for this customer have been removed successfully`
				}
				if (deleteAPAC) {
					sbcRemoveSuccess = await removeSBCConfiguration(
						'APAC',
						sbcTrunkIPModels
					)
					message = `The APAC trunks for this customer have been removed successfully`
				}
				if (deleteEMEA) {
					sbcRemoveSuccess = await removeSBCConfiguration(
						'EMEA',
						sbcTrunkIPModels
					)
					message = `The EMEA trunks for this customer have been removed successfully`
				}
			}

			if (!sbcRemoveSuccess) {
				// Add to error log
				await addErrorLog(
					'Deleting SBCs',
					message + 'but the removal of the configuration failed to initialize'
				)
			}
			checkForCustomerAndExistingTrunkGroup()
			resetVariables()
		}
	}

	// POST: Remove existing SBCs configuration for each region
	const removeSBCConfiguration = async (
		region: string,
		sbcTrunkIPModels: SBCTrunkIPModel[]
	) => {
		// Check which region to remove
		var removeALL = false
		var removeAMER = false
		var removeAPAC = false
		var removeEMEA = false
		var sbcRemoveSuccess = true
		var errorMessage = ''
		switch (region) {
			case 'ALL':
				removeALL = true
				break

			case 'AMER':
				removeAMER = true
				break

			case 'APAC':
				removeAPAC = false
				break

			case 'EMEA':
				removeEMEA = true
				break
		}

		// Make calls to remove
		// Remove ALL
		if (removeALL && (emeaExists || amerExists || apacExists)) {
			var globalRemoveSBCRequest: BYOCSBCRequestModel = {
				CustomerID: customerID + '',
				ServiceOrTrunkID: trunkGroupID,
				SBCTrunkIPs: sbcTrunkIPModels,
				CallDeliveryMethodID: Number(CallDeliveryMethods.Hunt),
				TrunkQuantity: trunkGroup
					? Number(trunkGroup.Quantity)
					: trunkGroupQuantity,
				Region: 'Global',
				Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
				IsConfigCreation: false,
				ServiceTypeID: Number(Services.BYOC),
			}
			var betaObj: BetaObject = {
				Content: await toBetaString(JSON.stringify(globalRemoveSBCRequest)),
			}
			await postQueueSBCRequest(betaObj)
				.unwrap()
				.then(() => {})
				.catch(async (error) => {
					if (error) {
						sbcRemoveSuccess = false
						errorMessage =
							'An error occurred when initialising SBC config removal. Request body: ' +
							JSON.stringify(globalRemoveSBCRequest) +
							`Error ` +
							error?.data
					}
				})
				.finally(() => {
					// Log error to db
					if (sbcRemoveSuccess === false) {
						addErrorLog('Remove SBC Config', errorMessage)
					}
				})
		} else {
			// Remove AMER
			if (removeAMER && amerExists) {
				var amerRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID + '',
					ServiceOrTrunkID: trunkGroupID,
					SBCTrunkIPs: sbcTrunkIPModels,
					CallDeliveryMethodID: Number(CallDeliveryMethods.Hunt),
					TrunkQuantity: trunkGroup
						? Number(trunkGroup.Quantity)
						: trunkGroupQuantity,
					Region: RegionOptions[RegionOptions.AMER],
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					IsConfigCreation: false,
					ServiceTypeID: Number(Services.BYOC),
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(amerRemoveSBCRequest)),
				}
				await postQueueSBCRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(amerRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
			// Remove APAC
			if (removeAPAC && apacExists) {
				var apacRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID + '',
					ServiceOrTrunkID: trunkGroupID,
					SBCTrunkIPs: sbcTrunkIPModels,
					CallDeliveryMethodID: Number(CallDeliveryMethods.Hunt),
					TrunkQuantity: trunkGroup
						? Number(trunkGroup.Quantity)
						: trunkGroupQuantity,
					Region: RegionOptions[RegionOptions.APAC],
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					IsConfigCreation: false,
					ServiceTypeID: Number(Services.BYOC),
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(apacRemoveSBCRequest)),
				}
				await postQueueSBCRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(apacRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
			// Remove EMEA
			if (removeEMEA && emeaExists) {
				var emeaRemoveSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID + '',
					ServiceOrTrunkID: trunkGroupID,
					SBCTrunkIPs: sbcTrunkIPModels,
					CallDeliveryMethodID: Number(CallDeliveryMethods.Hunt),
					TrunkQuantity: trunkGroup
						? Number(trunkGroup.Quantity)
						: trunkGroupQuantity,
					Region: RegionOptions[RegionOptions.EMEA],
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					IsConfigCreation: false,
					ServiceTypeID: Number(Services.BYOC),
				}
				var betaObj: BetaObject = {
					Content: await toBetaString(JSON.stringify(emeaRemoveSBCRequest)),
				}
				await postQueueSBCRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcRemoveSuccess = false
							errorMessage =
								'An error occurred when initialising SBC config removal. Request body: ' +
								JSON.stringify(emeaRemoveSBCRequest) +
								`Error ` +
								error?.data
						}
					})
					.finally(() => {
						// Log error to db
						if (sbcRemoveSuccess === false) {
							addErrorLog('Remove SBC Config', errorMessage)
						}
					})
			}
		}

		return sbcRemoveSuccess
	}

	// #region Removed Code
	// POST: Return the FQDNs and add the mapping
	const addFQDNMapping = async (
		customerTrunkGroupRegionMapList: CustomerTrunkGroupRegionMap[]
	) => {
		var fqdnRequestURL = `FQDN.Where(FQDN.CustomerID = '${customerID}' & FQDN.FQDN ~ '${trunkGroupID}')`

		// Make call
		var dataResponse = await postAndRetrieveDataFromDB(
			'Trunk FQDNs',
			fqdnRequestURL,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var fqdnList = dataResponse.Obj.FQDNList as FQDN[]

			if (fqdnList && fqdnList.length > 0) {
				// Loop through list and build inserts
				var customerTrunkGroupFQDNMapList = [] as CustomerTrunkGroupFQDNMap[]
				for (let fqdn = 0; fqdn < fqdnList.length; fqdn++) {
					// AMER
					if (assignAMER && fqdnList[fqdn].FQDN?.includes('amer')) {
						// Filter the region list first
						var amerRegionIPs = customerTrunkGroupRegionMapList.filter(
							(amer) => Number(amer.RegionID) === RegionOptions.AMER
						)
						if (amerRegionIPs && amerRegionIPs.length > 0) {
							// Push to list
							for (let amer = 0; amer < amerRegionIPs.length; amer++) {
								// AMER Object
								var amerFQDNMap: CustomerTrunkGroupFQDNMap = {
									CustomerTrunkGroupIPID:
										amerRegionIPs[amer].CustomerTrunkGroupIPID,
									FQDNID: fqdnList[fqdn].FQDNID,
								}
								customerTrunkGroupFQDNMapList.push(amerFQDNMap)
							}
						}
					}
					// APAC
					if (assignAPAC && fqdnList[fqdn].FQDN?.includes('apac')) {
						// Filter the region list first
						var apacRegionIPs = customerTrunkGroupRegionMapList.filter(
							(apac) => Number(apac.RegionID) === RegionOptions.APAC
						)
						if (apacRegionIPs && apacRegionIPs.length > 0) {
							// Push to list
							for (let apac = 0; apac < apacRegionIPs.length; apac++) {
								// AMER Object
								var apacFQDNMap: CustomerTrunkGroupFQDNMap = {
									CustomerTrunkGroupIPID:
										apacRegionIPs[apac].CustomerTrunkGroupIPID,
									FQDNID: fqdnList[fqdn].FQDNID,
								}
								customerTrunkGroupFQDNMapList.push(apacFQDNMap)
							}
						}
					}
					// EMEA
					if (assignEMEA && fqdnList[fqdn].FQDN?.includes('emea')) {
						// Filter the region list first
						var emeaRegionIPs = customerTrunkGroupRegionMapList.filter(
							(emea) => Number(emea.RegionID) === RegionOptions.EMEA
						)
						if (emeaRegionIPs && emeaRegionIPs.length > 0) {
							// Push to list
							for (let emea = 0; emea < emeaRegionIPs.length; emea++) {
								// AMER Object
								var emeaFQDNMap: CustomerTrunkGroupFQDNMap = {
									CustomerTrunkGroupIPID:
										emeaRegionIPs[emea].CustomerTrunkGroupIPID,
									FQDNID: fqdnList[fqdn].FQDNID,
								}
								customerTrunkGroupFQDNMapList.push(emeaFQDNMap)
							}
						}
					}
				}

				// Add to DB
				if (
					customerTrunkGroupFQDNMapList &&
					customerTrunkGroupFQDNMapList.length > 0
				) {
					// Make post call
					var addFQDNMap = {
						CustomerTrunkGroupFQDNMapList: customerTrunkGroupFQDNMapList,
					}
					// Post to DB
					var postHookObj: PostHookObj = {
						Action: 'Add Trunk FQDN Map',
						LoggedInUser: loggedInUser,
						RequestURL: 'AddV2?Params=CustomerTrunkGroupFQDNMap:list',
						RequestObj: addFQDNMap,
						ShowSuccessMessage: false,
						ShowErrorMessage: false,
						ErrorMessage: 'An error occurred when adding trunks FQDN mapping',
						LogErrorToDB: true,
					}

					await postUpdateDataWithoutRefetch(postHookObj)
				}
			}
		}
	}

	// POST: Call to SRE to create customer if not existing already
	const postAndRetrieveSREConfig = async () => {
		// Return variables
		var sreObj = {
			SRECustomerID: 0,
			TrunkGroupExists: false,
		}

		if (customerID && customerID.length > 0) {
			// Error handling
			var isError = false

			// Request params
			var requestURL =
				process.env.REACT_APP_SRE_CREATE_CUSTOMER_IF_NOT_EXISTS_ENDPOINT + ''
			var requestObj: SRECustomer = {
				CustomerID: customerID,
				CustomerName: customerName,
			}

			var betaObj: BetaObject = {
				Content: await toBetaString(JSON.stringify(requestObj)),
			}

			var betaSREMutation: BetaSREMutation = {
				URL: requestURL,
				BetaObject: betaObj,
			}

			await postCustomerAndReturnSREInformation(betaSREMutation)
				.unwrap()
				.then(async (response) => {
					if (response) {
						if (response.Content) {
							// Decrypt
							var sreResponse = await toSREServicesAlpha(response.Content)

							if (sreResponse && sreResponse.SRE_CustomerID) {
								sreObj.SRECustomerID = sreResponse.SRE_CustomerID

								// Parent services
								var parentServicesList =
									sreResponse.ParentServices as ParentService[]

								if (parentServicesList && parentServicesList.length > 0) {
									// Check if the trunk group already exists
									var trunkGroupExistsInSRE = parentServicesList.find(
										(p) => p.CDS_ServiceID + '' === trunkGroupID
									)

									if (trunkGroupExistsInSRE) {
										sreObj.TrunkGroupExists = true
									}
								}
							}
						}
					}
				})
				.catch(async (error) => {
					if (error) {
						isError = true
					}
				})
				.finally(() => {
					if (isError) {
						addErrorLog(
							'Call SRE to create customer',
							'An error occurred when trying to call SRE to create customer if not existing'
						)
					}
				})
		}

		return sreObj
	}

	// POST: Call to return MS Teams and then do SRE Configuration
	const addSREConfiguration = async (sreCustomerID: number) => {
		// Return variable
		var sreConfigSuccess = true
		// Make call to return MS Teams Service
		var dataResponse = await postAndRetrieveDataFromDB(
			'MS Teams',
			`MSTeamsUser.First(MSTeamsUser.CustomerID = '${customerID}')`,
			false,
			true
		)
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var msTeamsResponse = dataResponse.Obj.MSTeamsUser as MSTeamsUser

			if (msTeamsResponse && msTeamsResponse.ServiceID) {
				// Create objects to send to SRE to be configured

				// Teams Capacity
				var msTeamsCapacity = Math.trunc(Number(msTeamsResponse.Quantity) / 7)
				if (msTeamsCapacity < 20) {
					msTeamsCapacity = 20
				}

				// Connectivity Inbound - Trunk Group
				var connectivityInboundObj: SREServiceConfiguration = {
					CDS_ServiceID: trunkGroupID,
					SRE_ServiceTypeName: TrunkTypes[Number(TrunkTypes.BYOC)],
					Capacity: 20,
				}

				// Application Outbound - Teams
				var applicationOutboundObj: SREServiceConfiguration = {
					CDS_ServiceID: msTeamsResponse.ServiceID,
					SRE_ServiceTypeName: 'TEAMS_DR',
					Capacity: msTeamsCapacity,
				}

				// Application Inbound - Teams
				var applicationInboundObj = applicationOutboundObj
				// Connectivity Outbound - Trunk Group
				var connectivityOutboundObj = connectivityInboundObj

				// Object to be configured
				var sreConfiguration: SREConfiguration = {
					SRECustomerID: sreCustomerID,
					IsConnectivityOutboundSameAsConnectivityInbound: true,
					ConnectivityInbound: connectivityInboundObj,
					ApplicationOutboundService: applicationOutboundObj,
					ApplicationIncomingService: applicationInboundObj,
					ConnectivityOutbound: connectivityOutboundObj,
					RoutingPlanStrategyOption: StrategyOption.Priority,
					IsLumenVoiceCatch: true,
				}

				// Encrypt
				var betaDataObj = await toBeta(sreConfiguration)

				var betaObj: BetaObject = {
					Content: betaDataObj,
				}

				var betaSREMutation: BetaSREMutation = {
					URL: process.env.REACT_APP_SRE_CREATE_CONFIG_ENDPOINT + '',
					BetaObject: betaObj,
				}

				await postSREConfiguration(betaSREMutation)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sreConfigSuccess = false
						}
					})
					.finally(() => {
						if (!sreConfigSuccess) {
							addErrorLog(
								'SRE Configuration',
								`An error occurred when configuring SRE for Trunk Group ${trunkGroupID} and MS Teams ${msTeamsResponse.ServiceID} for customer ${customerID}`
							)
						}
					})
			}
		}

		return sreConfigSuccess
	}

	// POST: Call SBC to configure new region(s)
	const configureSBCs = async (
		customerTrunkGroupIPList: CustomerTrunkGroupIP[],
		customerTrunkGroupRegionMapList: CustomerTrunkGroupRegionMap[]
	) => {
		// Flag to return
		var sbcConfigSuccess = true
		var errorMessage = ''
		// Ensure you have the external order ID
		if (customerTrunkGroupIPList && customerTrunkGroupIPList.length > 0) {
			// Lists and flags to build
			var amer = false
			var apac = false
			var emea = false
			var sbcTrunkIPModels = [] as SBCTrunkIPModel[]
			// Loop through and generate endpoint config
			for (let ip = 0; ip < customerTrunkGroupIPList.length; ip++) {
				// Check which region
				var region = customerTrunkGroupRegionMapList.find(
					(r) =>
						r.CustomerTrunkGroupIPID + '' ===
						customerTrunkGroupIPList[ip].CustomerTrunkGroupIPID + ''
				)

				if (region && region.RegionID) {
					var sbcTrunkIPModelObj: SBCTrunkIPModel = {
						CustomerTrunkIP: customerTrunkGroupIPList[ip].CustomerTrunkIP + '',
						InternetProtocolID: Number(
							customerTrunkGroupIPList[ip].InternetProtocolID
						),
						Port: customerTrunkGroupIPList[ip].Port + '',
					}
					sbcTrunkIPModels.push(sbcTrunkIPModelObj)
					switch (region.RegionID) {
						case RegionOptions.AMER:
							amer = true
							break

						case RegionOptions.APAC:
							apac = true
							break

						case RegionOptions.EMEA:
							emea = true
							break
					}
				}
			}
			// Generate the SBC Object to configure for each region
			// Global
			if (amer && apac && emea) {
				var globalConfigureSBCRequest: BYOCSBCRequestModel = {
					CustomerID: customerID + '',
					ServiceOrTrunkID: trunkGroupID,
					Region: 'Global',
					SBCTrunkIPs: sbcTrunkIPModels,
					Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
					CallDeliveryMethodID: CallDeliveryMethods.Hunt,
					IsConfigCreation: true,
					ServiceTypeID: Services.BYOC,
					TrunkQuantity: trunkGroup.Quantity,
				}
				// Make call
				var betaObj: BetaObject = {
					Content: await toBetaString(
						JSON.stringify(globalConfigureSBCRequest)
					),
				}
				await postQueueSBCRequest(betaObj)
					.unwrap()
					.then(() => {})
					.catch(async (error) => {
						if (error) {
							sbcConfigSuccess = false
							errorMessage =
								`An error occurred when initiliasing SBC config creation. Request body: ` +
								JSON.stringify(globalConfigureSBCRequest) +
								'Error: ' +
								error?.data
						}
					})
					.finally(() => {
						// Add to log
						if (!sbcConfigSuccess) {
							addErrorLog('Configure SBC', errorMessage)
						}
					})
			} else {
				// AMER
				if (amer) {
					var amerConfigureSBCRequest: BYOCSBCRequestModel = {
						CustomerID: customerID + '',
						ServiceOrTrunkID: trunkGroupID,
						Region: RegionOptions[RegionOptions.AMER],
						SBCTrunkIPs: sbcTrunkIPModels,
						Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
						CallDeliveryMethodID: CallDeliveryMethods.Hunt,
						IsConfigCreation: true,
						ServiceTypeID: Services.BYOC,
						TrunkQuantity: trunkGroup.Quantity,
					}
					// Make call
					var betaObj: BetaObject = {
						Content: await toBetaString(
							JSON.stringify(amerConfigureSBCRequest)
						),
					}
					await postQueueSBCRequest(betaObj)
						.unwrap()
						.then(() => {})
						.catch(async (error) => {
							if (error) {
								sbcConfigSuccess = false
								errorMessage =
									`An error occurred when initialising SBC config creation. Request body: ` +
									JSON.stringify(amerConfigureSBCRequest) +
									`Error: ` +
									error?.data
							}
						})
						.finally(() => {
							// Add to log
							if (!sbcConfigSuccess) {
								addErrorLog('Configure SBC', errorMessage)
							}
						})
				}
				// APAC
				if (apac) {
					var apacConfigureSBCRequest: BYOCSBCRequestModel = {
						CustomerID: customerID + '',
						ServiceOrTrunkID: trunkGroupID,
						Region: RegionOptions[RegionOptions.APAC],
						SBCTrunkIPs: sbcTrunkIPModels,
						Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
						CallDeliveryMethodID: CallDeliveryMethods.Hunt,
						IsConfigCreation: true,
						ServiceTypeID: Services.BYOC,
						TrunkQuantity: trunkGroup.Quantity,
					}
					// Make call
					var betaObj: BetaObject = {
						Content: await toBetaString(
							JSON.stringify(apacConfigureSBCRequest)
						),
					}
					await postQueueSBCRequest(betaObj)
						.unwrap()
						.then(() => {})
						.catch(async (error) => {
							if (error) {
								sbcConfigSuccess = false
								errorMessage =
									`An error occurred when initialising SBC config creation. Request body: ` +
									JSON.stringify(apacConfigureSBCRequest) +
									`Error: ` +
									error?.data
							}
						})
						.finally(() => {
							// Add to log
							if (!sbcConfigSuccess) {
								addErrorLog('Configure SBC', errorMessage)
							}
						})
				}
				// EMEA
				if (emea) {
					var emeaConfigureSBCRequest: BYOCSBCRequestModel = {
						CustomerID: customerID + '',
						ServiceOrTrunkID: trunkGroupID,
						Region: RegionOptions[RegionOptions.EMEA],
						SBCTrunkIPs: sbcTrunkIPModels,
						Environment: process.env.REACT_APP_SBC_ENVIRONMENT + '',
						CallDeliveryMethodID: CallDeliveryMethods.Hunt,
						IsConfigCreation: true,
						ServiceTypeID: Services.BYOC,
						TrunkQuantity: trunkGroup.Quantity,
					}
					// Make call
					var betaObj: BetaObject = {
						Content: await toBetaString(
							JSON.stringify(emeaConfigureSBCRequest)
						),
					}
					await postQueueSBCRequest(betaObj)
						.unwrap()
						.then(() => {})
						.catch(async (error) => {
							if (error) {
								sbcConfigSuccess = false
								errorMessage =
									`An error occurred when initialising SBC creation. Request body: ` +
									JSON.stringify(emeaConfigureSBCRequest) +
									`Error:` +
									error?.data
							}
						})
						.finally(() => {
							// Add to log
							if (!sbcConfigSuccess) {
								addErrorLog('Configure SBC', errorMessage)
							}
						})
				}
			}
		}

		return sbcConfigSuccess
	}

	// POST: Remove SRE configured BYOC service
	const removeSRETrunkServices = async () => {
		// Error handling
		var isError = false

		// Build object
		var deleteSREServicesObj = {
			CustomerID: customerID,
			ServiceIds: [trunkGroupID],
		}

		// Encrypt and send to SRE to be removed
		var betaDataObj = await toBeta(deleteSREServicesObj)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		var betaSREMutation: BetaSREMutation = {
			URL: process.env.REACT_APP_SRE_DELETE_SERVICES_ENDPOINT + '',
			BetaObject: betaObj,
		}

		await postSREConfiguration(betaSREMutation)
			.unwrap()
			.then(() => {})
			.catch(() => {
				isError = true
			})
			.finally(() => {
				if (isError) {
					// Log to DB
					addErrorLog(
						'Remove SRE Trunk Service',
						`An error occurred when removing trunk group ${trunkGroupID} for customer ${customerID}`
					)
				}
			})
	}
	// #endregion

	// *************************************** END API Calls *************************************** //

	// Return latest index based on the letter for trunk IPs
	const getLatestTrunkIPIndex = () => {
		// Variables to build
		var index = 0
		var currentIndex = 0

		if (amerExists || apacExists || emeaExists) {
			// AMER
			if (amerExists) {
				// Loop through the trunk IP list and get the latest index
				for (let amer = 0; amer < amerTrunkIPList.length; amer++) {
					var amerTrunkIPIndex =
						amerTrunkIPList[amer].CustomerTrunkGroupIPID?.split('-')
					if (amerTrunkIPIndex) {
						currentIndex = amerTrunkIPIndex[2].charCodeAt(0)

						if (currentIndex > index) {
							index = currentIndex
						}
					}
				}
			}
			// APAC
			if (apacExists) {
				// Loop through the trunk IP list and get the latest index
				for (let apac = 0; apac < apacTrunkIPList.length; apac++) {
					var apacTrunkIPIndex =
						apacTrunkIPList[apac].CustomerTrunkGroupIPID?.split('-')
					if (apacTrunkIPIndex) {
						currentIndex = apacTrunkIPIndex[2].charCodeAt(0)

						if (currentIndex > index) {
							index = currentIndex
						}
					}
				}
			}
			// EMEA
			if (emeaExists) {
				// Loop through the trunk IP list and get the latest index
				for (let emea = 0; emea < emeaTrunkIPList.length; emea++) {
					var emeaTrunkIPIndex =
						emeaTrunkIPList[emea].CustomerTrunkGroupIPID?.split('-')
					if (emeaTrunkIPIndex) {
						currentIndex = emeaTrunkIPIndex[2].charCodeAt(0)

						if (currentIndex > index) {
							index = currentIndex
						}
					}
				}
			}

			// If there is a value then increment it
			if (index > 0) {
				index += 1
				var aIndex = 'A'.charCodeAt(0)
				index -= aIndex
			}
		}

		return index
	}

	// Handle the loading for delete
	const handleDeleteDisplay = () => {
		// Check what is being deleted
		switch (regionToDelete) {
			// ALL
			case 'ALL':
				setDeleteAllLoading(true)
				break
			// AMER
			case 'AMER':
				setDeleteAMERLoading(true)
				break
			// APAC
			case 'APAC':
				setDeleteAPACLoading(true)
				break
			// EMEA
			case 'EMEA':
				setDeleteEMEALoading(true)
				break
		}

		// Close dialog
		setOpenDialog(false)
	}

	// Reset variables upon add/delete
	const resetVariables = () => {
		// Flags
		setAssignAMER(false)
		setAssignAPAC(false)
		setAssignEMEA(false)
		setDeleteAllLoading(false)
		setDeleteAMERLoading(false)
		setDeleteAPACLoading(false)
		setDeleteEMEALoading(false)
		// Errors
		setAMERCarrierDescriptionError('')
		setAPACCarrierDescriptionError('')
		setEMEACarrierDescriptionError('')
		setAMERIPError('')
		setAPACIPError('')
		setEMEAIPError('')
		// General
		setAMERIP('')
		setAPACIP('')
		setEMEAIP('')
	}

	// Clear arrays - Used for when deleting all
	const clearArrays = () => {
		// Flags
		setAMERExists(false)
		setAPACExists(false)
		setEMEAExists(false)
		// Carrier Descriptions
		setAMERCarrierDescription('')
		setAPACCarrierDescription('')
		setEMEACarrierDescription('')
		// IP value and arrays
		setAMERIP('')
		setAMERTrunkIPList([])
		setAPACIP('')
		setAPACTrunkIPList([])
		setEMEAIP('')
		setEMEATrunkIPList([])
	}

	// Handle functions
	const handleCarrierDescriptionChange = (
		e: { target: any },
		region: string
	) => {
		// Check which region changed
		switch (region) {
			case 'AMER':
				setAMERCarrierDescription(e.target.value)
				break

			case 'APAC':
				setAPACCarrierDescription(e.target.value)
				if (apacCarrierDescriptionError.length > 0) {
					setAPACCarrierDescriptionError('')
				}
				break

			case 'EMEA':
				setEMEACarrierDescription(e.target.value)
				if (emeaCarrierDescriptionError.length > 0) {
					setEMEACarrierDescriptionError('')
				}
				break
		}

		// Validate
		validateCarrierDescription(region, e.target.value)
	}

	// Validate Carrier Description
	const validateCarrierDescription = (region: string, value: string) => {
		// Check which region
		switch (region) {
			// AMER
			case 'AMER':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === apacCarrierDescription.trim() ||
						value.trim() === emeaCarrierDescription.trim()
					) {
						setAMERCarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setAMERCarrierDescriptionError('')
					}
				}
				break
			// APAC
			case 'APAC':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === amerCarrierDescription.trim() ||
						value.trim() === emeaCarrierDescription.trim()
					) {
						setAPACCarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setAPACCarrierDescriptionError('')
					}
				}
				break
			// EMEA
			case 'EMEA':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === amerCarrierDescription.trim() ||
						value.trim() === apacCarrierDescription.trim()
					) {
						setEMEACarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setEMEACarrierDescriptionError('')
					}
				}
				break
		}
	}

	// Save the trunk IP change
	const handleTrunkIPChange = async (e: { target: any }, region: string) => {
		// Check what region changed
		switch (region) {
			case 'AMER':
				setAMERIP(e.target.value)
				if (amerIPError.length > 0) {
					setAMERIPError('')
				}
				break

			case 'APAC':
				setAPACIP(e.target.value)
				if (apacIPError.length > 0) {
					setAPACIPError('')
				}
				break

			case 'EMEA':
				setEMEAIP(e.target.value)
				if (emeaIPError.length > 0) {
					setEMEAIPError('')
				}
				break
		}
	}

	// Handles if the user presses enter after adding an IP
	const handleKeyPress = async (e: any, region: string) => {
		// Check if enter key was pressed
		if (e.keyCode === 13) {
			var error = ''
			switch (region) {
				case 'AMER':
					// Ensure the IP is not empty
					if (amerIP.length > 0) {
						setLoadingAMER(true)
						error = await validateIP(amerIP)
						if (error && error.length > 0) {
							setAMERIPError(error)
						} else {
							if (amerTrunkIPList.length > 0) {
								setAMERTrunkIPList((ips) => [
									...ips,
									{ CustomerTrunkIP: amerIP },
								])
							} else {
								setAMERTrunkIPList([{ CustomerTrunkIP: amerIP }])
							}
							setAMERIP('')
						}
						setLoadingAMER(false)
					}
					break

				case 'APAC':
					// Ensure the IP is not empty
					if (apacIP.length > 0) {
						setLoadingAPAC(true)
						error = await validateIP(apacIP)
						if (error && error.length > 0) {
							setAPACIPError(error)
						} else {
							if (apacTrunkIPList.length > 0) {
								setAPACTrunkIPList((ips) => [
									...ips,
									{ CustomerTrunkIP: apacIP },
								])
							} else {
								setAPACTrunkIPList([{ CustomerTrunkIP: apacIP }])
							}
							setAPACIP('')
						}
						setLoadingAPAC(false)
					}
					break

				case 'EMEA':
					// Ensure the IP is not empty
					if (emeaIP.length > 0) {
						setLoadingEMEA(true)
						error = await validateIP(emeaIP)
						if (error && error.length > 0) {
							setEMEAIPError(error)
						} else {
							if (emeaTrunkIPList.length > 0) {
								setEMEATrunkIPList((ips) => [
									...ips,
									{ CustomerTrunkIP: emeaIP },
								])
							} else {
								setEMEATrunkIPList([{ CustomerTrunkIP: emeaIP }])
							}
							setEMEAIP('')
						}
						setLoadingEMEA(false)
					}
					break
			}
		}
	}

	// Handle if the user clicks the add IP button
	const handleAddIPButton = async (e: any, region: string) => {
		// Check if enter key was pressed
		var error = ''
		switch (region) {
			case 'AMER':
				// Ensure the IP is not empty
				if (amerIP.length > 0) {
					setLoadingAMER(true)
					error = await validateIP(amerIP)
					if (error && error.length > 0) {
						setAMERIPError(error)
					} else {
						if (amerTrunkIPList.length > 0) {
							setAMERTrunkIPList((ips) => [...ips, { CustomerTrunkIP: amerIP }])
						} else {
							setAMERTrunkIPList([{ CustomerTrunkIP: amerIP }])
						}
						setAMERIP('')
					}
					setLoadingAMER(false)
				}
				break

			case 'APAC':
				// Ensure the IP is not empty
				if (apacIP.length > 0) {
					setLoadingAPAC(true)
					error = await validateIP(apacIP)
					if (error && error.length > 0) {
						setAPACIPError(error)
					} else {
						if (apacTrunkIPList.length > 0) {
							setAPACTrunkIPList((ips) => [...ips, { CustomerTrunkIP: apacIP }])
						} else {
							setAPACTrunkIPList([{ CustomerTrunkIP: apacIP }])
						}
						setAPACIP('')
					}
					setLoadingAPAC(false)
				}
				break

			case 'EMEA':
				// Ensure the IP is not empty
				if (emeaIP.length > 0) {
					setLoadingEMEA(true)
					error = await validateIP(emeaIP)
					if (error && error.length > 0) {
						setEMEAIPError(error)
					} else {
						if (emeaTrunkIPList.length > 0) {
							setEMEATrunkIPList((ips) => [...ips, { CustomerTrunkIP: emeaIP }])
						} else {
							setEMEATrunkIPList([{ CustomerTrunkIP: emeaIP }])
						}
						setEMEAIP('')
					}
					setLoadingEMEA(false)
				}
				break
		}
	}

	// Handle validate IP - Check for format and if duplicate
	const validateIP = async (ip: string) => {
		// Check if there is a value
		var error = ''
		if (ip && ip.length > 0) {
			// Check if it is in the valid format
			var isIPValid = isIPAddress(ip)

			if (isIPValid) {
				// Check if IP already exists
				var isNotDuplicate = await checkForDuplicateIPAddress(ip)

				if (!isNotDuplicate) {
					error = 'The Trunk IP entered already exists. Please try another one.'
				}

				// Check if the IP was added for any regions
				var checkAMER = amerTrunkIPList.find(
					(amer) => amer.CustomerTrunkIP + '' === ip
				)
				if (checkAMER && checkAMER.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the AMER region. Please try another one.'
				}
				var checkAPAC = apacTrunkIPList.find(
					(apac) => apac.CustomerTrunkIP + '' === ip
				)
				if (checkAPAC && checkAPAC.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the APAC region. Please try another one.'
				}
				var checkEMEA = emeaTrunkIPList.find(
					(emea) => emea.CustomerTrunkIP + '' === ip
				)
				if (checkEMEA && checkEMEA.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the EMEA region. Please try another one.'
				}
			} else {
				error =
					'The Trunk IP entered is not in the correct format. Please try again.'
			}
		}

		return error
	}

	// Delete the IP from the AMER, APAC and EMEA trunk IP list
	const handleIPDelete = (ip: string, region: string) => {
		// Delete from list
		switch (region) {
			case 'AMER':
				setAMERTrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break

			case 'APAC':
				setAPACTrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break

			case 'EMEA':
				setEMEATrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break
		}
	}

	// Handle open dialog popup
	const handleOpenDialog = (region: string) => {
		var dialogTitle = ''
		var dialogDescription = ''
		switch (region) {
			case 'ALL':
				dialogTitle = 'Are you sure you wish to delete all these trunks?'
				dialogDescription = `Deleting all the trunk information will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'AMER':
				dialogTitle = 'Are you sure you wish to delete the AMER trunks?'
				dialogDescription = `Deleting this carrier description '${amerCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'APAC':
				dialogTitle = 'Are you sure you wish to delete the APAC trunks?'
				dialogDescription = `Deleting this carrier description '${apacCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'EMEA':
				dialogTitle = 'Are you sure you wish to delete the EMEA trunks?'
				dialogDescription = `Deleting this '${emeaCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break
		}

		// Set values
		setDialogTitle(dialogTitle)
		setDialogDescription(dialogDescription)
		setRegionToDelete(region)
		setOpenDialog(true)
	}

	// Handle close dialog popup
	const handleCloseDialog = () => {
		setOpenDialog(false)
		setRegionToDelete('')
	}

	// Disable add IP button depending on region
	const disableAddIPButton = (region: string) => {
		// Return variable
		var disableButton = false
		// Check which region
		switch (region) {
			// AMER
			case 'AMER':
				if (
					amerExists ||
					!assignAMER ||
					amerTrunkIPList.length >= 4 ||
					amerIP.length === 0
				) {
					disableButton = true
				}
				break
			// APAC
			case 'APAC':
				if (
					apacExists ||
					!assignAPAC ||
					apacTrunkIPList.length >= 4 ||
					apacIP.length === 0
				) {
					disableButton = true
				}
				break
			// EMEA
			case 'EMEA':
				if (
					emeaExists ||
					!assignEMEA ||
					emeaTrunkIPList.length >= 4 ||
					emeaIP.length === 0
				) {
					disableButton = true
				}
				break
		}

		return disableButton
	}

	// Disable add trunk button
	const disableAddTrunkButton = () => {
		// Return variable
		var disableButton = false

		if (
			// If no regions have been ticked
			(!assignAMER && !assignAPAC && !assignEMEA) ||
			// If amer ticked, make sure all fields is filled in
			(assignAMER &&
				(amerCarrierDescription.length === 0 ||
					amerTrunkIPList.length === 0)) ||
			// If apac ticked, make sure all fields is filled in
			(assignAPAC &&
				(apacCarrierDescription.length === 0 ||
					apacTrunkIPList.length === 0)) ||
			// If emea ticked, make sure all fields is filled in
			(assignEMEA &&
				(emeaCarrierDescription.length === 0 ||
					emeaTrunkIPList.length === 0)) ||
			// If any errors, disable
			// AMER Errors
			(assignAMER && amerCarrierDescriptionError.length > 0) ||
			(assignAMER && amerIPError.length > 0) ||
			// APAC Errors
			(assignAPAC && apacCarrierDescriptionError.length > 0) ||
			(assignAPAC && apacIPError.length > 0) ||
			// EMEA Errors
			(assignEMEA && emeaCarrierDescriptionError.length > 0) ||
			(assignEMEA && emeaIPError.length > 0)
		) {
			disableButton = true
		}

		return disableButton
	}

	return apiCallsDone ? (
		<>
			<Box className='add-trunk-container'>
				<Box className='add-trunk-content'>
					{/* Customer ID and Name */}
					<Box className='add-trunk-customer-container'>
						{/* Image and Customer Information */}
						<Box
							className='add-trunk-customer'
							border={1}
							borderColor='border.primary'>
							{/* Image */}
							<img
								src={require('../../../assets/icons/form/trunks/trunks-icon.png')}
								alt='Trunks'
							/>
							{/* Customer ID */}
							<Box>
								<Typography component='h2'>
									{customerName}{' '}
									<Tooltip title='Navigate to the customer overview'>
										<IconButton
											onClick={() =>
												navigate(`/dashboard/customer-overview/${customerID}`)
											}>
											<OpenInNewIcon sx={{ color: 'text.primary' }} />
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography component='p'>
									BizOrg: {bizOrg.length > 0 ? bizOrg : '(empty)'}
								</Typography>
							</Box>
							{/* Customer Name */}
						</Box>
					</Box>
					{/* Heading */}
					<Divider>
						<Typography component='h2' color='text.primary'>
							Trunk Details
						</Typography>
					</Divider>
					{/* Trunk Group ID */}
					<Box className='trunk-header-container'>
						<Box className='trunk-header'>
							<Typography component='p'>
								<strong>Trunk Group ID:</strong> {trunkGroupID}
							</Typography>
						</Box>
					</Box>
					{/* ALert - If any of the regions have been assigned, inform user */}
					{alertMessage.length > 0 ? (
						<Box className='add-trunk-alert'>
							<Alert severity='info'>{alertMessage}</Alert>
						</Box>
					) : null}
					{/* Trunk Regions */}
					<Box className='add-trunk-regions-container'>
						{/* AMER */}
						<Box
							className='add-trunk-region'
							bgcolor={amerExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> AMER
								</Typography>
								{amerExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete AMER Trunks'>
											<span>
												<IconButton
													disabled={deleteAMERLoading || deleteAllLoading}
													onClick={() => handleOpenDialog('AMER')}>
													<DeleteIcon />
												</IconButton>
												{deleteAMERLoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={amerExists}
										checked={amerExists || assignAMER}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignAMER}
										onChange={(e) => setAssignAMER(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									AMER Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={amerExists || !assignAMER}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={amerCarrierDescriptionError.length > 0}
									helperText={
										amerCarrierDescriptionError.length > 0
											? amerCarrierDescriptionError
											: ''
									}
									value={amerCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'AMER')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!amerExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										AMER Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												amerExists || !assignAMER || amerTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={amerIPError.length > 0}
											helperText={amerIPError.length > 0 ? amerIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'AMER')}
											value={amerIP}
											onChange={(e) => handleTrunkIPChange(e, 'AMER')}
										/>
										<LoadingButton
											disabled={disableAddIPButton('AMER')}
											startIcon={<AddIcon />}
											loading={loadingAMER}
											onClick={(e) => handleAddIPButton(e, 'AMER')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{amerTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4' color='text.header'>
										List of AMER Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{amerTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={amerExists || !assignAMER}
												key={index}
												label={item.CustomerTrunkIP}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'AMER')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
						{/* APAC */}
						<Box
							className='add-trunk-region'
							bgcolor={apacExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> APAC
								</Typography>
								{apacExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete APAC Trunks'>
											<span>
												<IconButton
													disabled={deleteAPACLoading || deleteAllLoading}
													onClick={() => handleOpenDialog('APAC')}>
													<DeleteIcon />
												</IconButton>
												{deleteAPACLoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={apacExists}
										checked={apacExists || assignAPAC}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignAPAC}
										onChange={(e) => setAssignAPAC(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									APAC Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={apacExists || !assignAPAC}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={apacCarrierDescriptionError.length > 0}
									helperText={
										apacCarrierDescriptionError.length > 0
											? apacCarrierDescriptionError
											: ''
									}
									value={apacCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'APAC')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!apacExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										APAC Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												apacExists || !assignAPAC || apacTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={apacIPError.length > 0}
											helperText={apacIPError.length > 0 ? apacIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'APAC')}
											value={apacIP}
											onChange={(e) => handleTrunkIPChange(e, 'APAC')}
										/>
										<LoadingButton
											disabled={disableAddIPButton('APAC')}
											startIcon={<AddIcon />}
											loading={loadingAPAC}
											onClick={(e) => handleAddIPButton(e, 'APAC')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{apacTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4'>
										List of APAC Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{apacTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={apacExists || !assignAPAC}
												key={index}
												label={item.CustomerTrunkIP}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'APAC')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
						{/* EMEA */}
						<Box
							className='add-trunk-region'
							bgcolor={emeaExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> EMEA
								</Typography>
								{emeaExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete EMEA Trunks'>
											<span>
												<IconButton
													disabled={deleteEMEALoading || deleteAllLoading}
													onClick={() => handleOpenDialog('EMEA')}>
													<DeleteIcon />
												</IconButton>
												{deleteEMEALoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={emeaExists}
										checked={emeaExists || assignEMEA}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignEMEA}
										onChange={(e) => setAssignEMEA(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									EMEA Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={emeaExists || !assignEMEA}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={emeaCarrierDescriptionError.length > 0}
									helperText={
										emeaCarrierDescriptionError.length > 0
											? emeaCarrierDescriptionError
											: ''
									}
									value={emeaCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'EMEA')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!emeaExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										EMEA Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												emeaExists || !assignEMEA || emeaTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={emeaIPError.length > 0}
											helperText={emeaIPError.length > 0 ? emeaIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'EMEA')}
											value={emeaIP}
											onChange={(e) => handleTrunkIPChange(e, 'EMEA')}
										/>
										<LoadingButton
											disabled={disableAddIPButton('EMEA')}
											startIcon={<AddIcon />}
											loading={loadingEMEA}
											onClick={(e) => handleAddIPButton(e, 'EMEA')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{emeaTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4' color='text.header'>
										List of EMEA Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{emeaTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={emeaExists || !assignEMEA}
												key={index}
												label={item.CustomerTrunkIP}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'EMEA')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
					</Box>
					{/* Button group */}
					<Box className='add-trunk-buttons'>
						{/* Add */}
						<LoadingButton
							disabled={disableAddTrunkButton()}
							className='add'
							variant='contained'
							loading={addLoading}
							onClick={addTrunks}>
							Add Trunk(s)
						</LoadingButton>
						{/* Delete */}
						<LoadingButton
							disabled={!amerExists && !apacExists && !emeaExists}
							loading={deleteAllLoading}
							className='delete'
							variant='contained'
							onClick={() => handleOpenDialog('ALL')}>
							Delete All Trunk(s)
						</LoadingButton>
					</Box>
				</Box>
				{/* Dialog */}
				<Dialog
					open={openDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{dialogDescription}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog} sx={{ color: '#db3d47' }}>
							Cancel
						</Button>
						<Button onClick={deleteTrunks} autoFocus>
							Delete Trunks
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</>
	) : (
		<LoadingCard
			loadingCardTitle={`Loading ${customerID} Trunk Information`}
			loadingCardDescription='Please wait as we load your trunk information'
		/>
	)
}

export default AddTrunk
