import { Box, Typography } from '@mui/material'
import './TDRSelfServiceProvisioning.scss'
import ReactPlayer from 'react-player'

function TDRSelfServiceProvisioning() {
	return (
		<>
			<Box id='gid234879' className='tdr-ssp-container'>
				{/* Header */}
				<Box className='tdr-ssp-header'>
					<img
						src={require(`../../assets/images/logos/lmn-logo.png`)}
						alt='Lumen Logo'
					/>
				</Box>
				{/* Banner */}
				<Box className='tdr-ssp-banner-container'>
					<Box className='tdr-ssp-banner'>
						<Box className='overlay' />
						<Box className='text m'>
							Teams Direct Routing - Self Service Provisioning
						</Box>
					</Box>
				</Box>
				{/* Content */}
				<Box className='tdr-ssp-content'>
					{/* Video and text */}
					<Box className='tdr-ssp-sc-video'>
						<Box className='text'>
							<Typography component='h2'>
								Provisioning Teams Direct Routing via Lumen is as easy as
								1...2...Teams!
							</Typography>
						</Box>
						{/* Main video - Set */}
						<Box className='video'>
							<ReactPlayer
								style={{
									border: '1px solid #6B6B6B',
								}}
								url={'https://vimeo.com/1029674832?share=copy'}
								width='100%'
								height='600px'
								controls={true}
								light={require('../../assets/images/tdr-ssp/video-tile.png')}
								playing
							/>
						</Box>
					</Box>
					{/* Heading */}
					<Box className='tdr-ssp-h'>
						<Typography component='h4'>
							Lets walk through the provisioning process...
						</Typography>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img'>
							<img
								src={require('../../assets/images/tdr-ssp/image-tile-1.png')}
								alt='People working'
							/>
						</Box>
						{/* Text */}
						<Box className='text'>
							<Typography component='p'>
								Upon activation of your Direct Routing Service, an email
								notification will be sent containing detailed instructions for
								accessing the Lumen UC Platform. This platform serves as a
								centralized hub for configuring Teams Direct Routing, offering
								reports on your configuration status and providing details on
								your platform utilization. Additionally, it offers a streamlined
								and user-friendly interface for configuring your users for Voice
								functionality.
							</Typography>
						</Box>
					</Box>
					{/* Steps */}
					<Box className='tdr-ssp-step-header'>
						{/* Number */}
						<Box className='step-number'>
							<Typography component='h3'>1</Typography>
						</Box>
						{/* Text */}
						<Box className='step-text'>
							<Typography component='h3'>
								ACCESSING THE LUMEN UC PLATFORM
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img-text ctr'>
							<img
								src={require('../../assets/images/tdr-ssp/image-tile-7.png')}
								alt='Enterprise Apps'
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>
								<strong>Lumen.ucplatform.io account creation:</strong>
							</Typography>
							<Typography component='p'>
								The first step is to click on the Accept button in the invite
								email, which is automatically generated by the Lumen ucplatform.
								The Accept link will redirect you to the platform to setup your
								account.
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img ctr'>
							<img
								style={{ width: '200px', height: '130px' }}
								src={require('../../assets/images/tdr-ssp/image-tile-8.png')}
								alt='Lumen Email'
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>Next set your password</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img ctr'>
							<img
								style={{ width: '200px', height: '130px' }}
								src={require('../../assets/images/tdr-ssp/image-tile-9.png')}
								alt='Lumen Email'
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>
								Finally setup MFA using your choice of authenticator app
							</Typography>
							<Typography component='p'>
								<strong>
									Upon completing these steps, you may proceed to log in to the
									Lumen UC Platform to access and review your configuration
									state.
								</strong>
							</Typography>
						</Box>
					</Box>
					{/* Steps */}
					<Box className='tdr-ssp-step-header'>
						{/* Number */}
						<Box className='step-number'>
							<Typography component='h3'>2</Typography>
						</Box>
						{/* Text */}
						<Box className='step-text'>
							<Typography component='h3'>
								CONFIGURING TEAMS DIRECT ROUTING
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img'>
							<img
								src={require('../../assets/images/tdr-ssp/image-tile-4.png')}
								alt='People working with graphs'
							/>
						</Box>
						{/* Text */}
						<Box className='text'>
							<Typography component='p'>
								To initiate the configuration for Teams Direct Routing, select
								the prominent 'Big Blue Button' featured on the Dashboard. Prior
								to initiating this procedure, we have curated a series of
								instructional videos below. These videos comprehensively guide
								you through the configuration steps and the specific elements we
								configure within your Microsoft Tenant.
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='video'>
							<ReactPlayer
								style={{
									border: '1px solid #6B6B6B',
								}}
								url={'https://vimeo.com/1005453665?share=copy'}
								controls={true}
								width='350px'
								height='200px'
								light={require('../../assets/images/tutorialVideoThumbnails/TeamsDROverview.png')}
								playing
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='h4'>
								Teams Direct routing provisioning steps
							</Typography>
							<Typography component='p'>
								This video guides you through the end-to-end process using Lumen
								UC Platform.{' '}
							</Typography>
						</Box>
					</Box>
					{/* Text */}
					<Typography component='p'>
						The videos below show the exact configuration applied in a customer
						Microsoft tenant. They are broken down into the three areas affected
						by this process.{' '}
					</Typography>
					{/* 3 Box */}
					<Box className='tdr-ssp-videos-container'>
						{/* Videos */}
						<Box className='tdr-ssp-videos'>
							{/* Step box */}
							<Box className='tdr-ssp-img-text'>
								{/* Image */}
								<Box className='video'>
									<ReactPlayer
										style={{
											border: '1px solid #6B6B6B',
										}}
										url={'https://vimeo.com/1005451826?share=copy'}
										controls={true}
										width='350px'
										height='200px'
										light={require('../../assets/images/tutorialVideoThumbnails/TeamsDRTenantEnterprise.png')}
										playing
									/>
								</Box>
								{/* Text */}
								<Box className='multi-text m'>
									<Typography component='h4'>
										ENTERPRISE APPLICATIONS
									</Typography>
								</Box>
							</Box>
							{/* Step box */}
							<Box className='tdr-ssp-img-text'>
								{/* Image */}
								<Box className='video'>
									<ReactPlayer
										style={{
											border: '1px solid #6B6B6B',
										}}
										url={'https://vimeo.com/872875243/4c63bb779e?share=copy'}
										controls={true}
										width='350px'
										height='200px'
										light={require('../../assets/images/tutorialVideoThumbnails/TeamsDRTenantActiveDirectory.png')}
										playing
									/>
								</Box>
								{/* Text */}
								<Box className='multi-text m'>
									<Typography component='h4'>ENTRA ID SETTINGS</Typography>
								</Box>
							</Box>
							{/* Step box */}
							<Box className='tdr-ssp-img-text'>
								{/* Image */}
								<Box className='video'>
									<ReactPlayer
										style={{
											border: '1px solid #6B6B6B',
										}}
										url={'https://vimeo.com/872875735/60a3dbb711?share=copy'}
										controls={true}
										width='350px'
										height='200px'
										light={require('../../assets/images/tutorialVideoThumbnails/TeamsDRVoiceSettings.png')}
										playing
									/>
								</Box>
								{/* Text */}
								<Box className='multi-text m'>
									<Typography component='h4'>TEAMS VOICE SETTINGS</Typography>
								</Box>
							</Box>
						</Box>
						{/* Laptop */}
						<img
							className='lptp-img'
							src={require('../../assets/images/tdr-ssp/laptop-s.png')}
							alt='Laptop Lumen Website'
						/>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img nbr ctr'>
							<img
								src={require('../../assets/images/tdr-ssp/image-tile-5.png')}
								alt='Github'
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>
								During the process, the execution of meticulously crafted and
								thoroughly tested scripts are run within your Microsoft Tenant.
								For transparency and your comprehensive understanding, you can
								review these scripts and their corresponding processes by
								accessing the GitHub location provided below. This transparency
								is designed to afford your company the opportunity for thorough
								due diligence, ensuring your comfort with the automation
								procedures in place.
							</Typography>
							<a href='https://github.com/ucplatform/TeamsDirectRouting-SelfServiceProvisioning'>
								GitHub - ucplatform/TeamsDirectRouting-SelfServiceProvisioning
							</a>
							<Typography component='p'>
								Upon successful completion of the process, navigate to the left
								menu where you will find an option for{' '}
								<strong>"Teams Configuration"</strong>. This section houses a
								comprehensive overview of your Logical Design Document (LLD),
								providing detailed insights into the elements configured within
								your Microsoft Tenant.
							</Typography>
						</Box>
					</Box>
					{/* Steps */}
					<Box className='tdr-ssp-step-header'>
						{/* Number */}
						<Box className='step-number'>
							<Typography component='h3'>3</Typography>
						</Box>
						{/* Text */}
						<Box className='step-text'>
							<Typography component='h3'>TEAMS USER MANAGEMENT</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img'>
							<img
								src={require('../../assets/images/tdr-ssp/image-tile-6.png')}
								alt='Young Professional'
							/>
						</Box>
						{/* Text */}
						<Box className='text'>
							<Typography component='p'>
								Having successfully reached this stage, Teams Direct Routing is
								now operational within your Microsoft Tenant. The next step
								involves assigning telephone numbers and the appropriate voice
								policies to your users. There are two methods available for
								accomplishing this task:
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img-text opt'>
							<Typography component='h3'>1</Typography>
							<Typography component='h4'>LUMEN UC PLATFORM</Typography>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>
								We've developed an intuitive tool designed for effortless
								assignment and management of telephone numbers. This tool
								simplifies the process by automatically allocating the
								appropriate Voice Policies based on the user's country and
								whether international calling is desired. For an in-depth
								understanding of our Teams User Management tools, please view
								the video provided below.
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='video'>
							<ReactPlayer
								style={{
									border: '1px solid #6B6B6B',
								}}
								url={'https://vimeo.com/857177606/fb3cd644f5?share=copy'}
								controls={true}
								width='350px'
								height='200px'
								light={require('../../assets/images/tutorialVideoThumbnails/TeamsUserManagementOverviewComplete.png')}
								playing
							/>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='h4'>
								TEAMS USER MANAGEMENT OVERVIEW
							</Typography>
							<Typography component='p'>
								Additional instructional videos covering Teams User Management
								are accessible within the Lumen UC Platform upon gaining access.
								These videos provide comprehensive guidance on each aspect
								involved in the processes.
							</Typography>
						</Box>
					</Box>
					{/* Step box */}
					<Box className='tdr-ssp-img-text'>
						{/* Image */}
						<Box className='img-text opt'>
							<Typography component='h3'>2</Typography>
							<Typography component='h4'>
								MICROSOFT TEAMS ADMIN CENTER
							</Typography>
						</Box>
						{/* Text */}
						<Box className='multi-text m'>
							<Typography component='p'>
								Another option is to utilize the Microsoft Teams Admin Center to
								allocate telephone numbers and voice policies through its
								interface. It's essential to note that there are no restrictions
								on the use of either tool, and both seamlessly utilize the same
								user information in the backend. Therefore, regardless of which
								tool you use to update user settings, both will remain
								synchronized and aligned.
							</Typography>
							<Typography component='p'>
								Upon gaining access to the Lumen UC Platform, you'll have access
								to comprehensive instructional videos detailing the various
								actions within the Microsoft Teams Admin Center.
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default TDRSelfServiceProvisioning
