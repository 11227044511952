import {
	Box,
	Button,
	MenuItem,
	Typography,
	Alert,
	Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RootState } from '../../store/store'
import Preloader from '../../components/shared/loading/preloader/Preloader'
import { InstanceStatuses } from '../../utils/enums/enums'
import {
	AddBulkContactDetailsRequestModel,
	AddCustomerRequestModel,
	AddCustomerResponseModel,
	AddOrderRequestModel,
	AddOrderResponseModel,
	AddRequest,
	ContactDetailModel,
	GetPOCInstancesResponseModel,
	PostHookObj,
} from '../../utils/interfaces/APIModels'
import { usePostBetaObjectWithoutRefetchMutation } from '../../services/proxyAPIData'
import {
	getEmptyAPIMutationObject,
	showErrorToast,
	showSuccessToast,
	toBeta,
	emailValidation,
	getUTCDateTimeNow,
} from '../../utils/helperFunctions/helperFunctions'
import { StyledTextBox } from '../../components/shared/styledComponents/StyledTextBox/StyledTextBox'
import './POC.scss'
import { setPageTitle } from '../../store/reducers/reducers'
import { useAppDispatch } from '../../store/hooks'
import { StyledSelect } from '../../components/shared/styledComponents/StyledSelect/StyledSelect'
import PostCustomAPIHook from '../../utils/customHooks/APICalls/PostCustomAPIHook'
import ErrorLogging from '../../utils/customHooks/ErrorLogging'
import PostAndRetrieveDataHook from '../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { ContactDetail, POCInstance } from '../../utils/interfaces/DBModels'

const AddPOCOrder = () => {
	const instanceStatusesList = useSelector(
		(state: RootState) => state.RootReducer.instanceStatusListReducer.value
	)
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)

	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const { postCustomAPI } = PostCustomAPIHook()
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()
	const { addErrorLog } = ErrorLogging()
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()

	const salesAgentEmailErrorMessage =
		'Invalid email format. Please enter a valid email address.'
	const newCustomerNameErrorMessage =
		'Invalid input. The name provided must be at least 2 characters long. Please enter a valid name.'
	const newCustomerPartnerRefErrorMessage =
		'Invalid input. A valid BizOrg ID must be at least 2 characters long.'
	const customerPrimaryEmailErrorMessage =
		'Invalid email format. Please enter a valid email address.'
	const customerTechnicalEmailErrorMessage =
		'Invalid email format. Please enter a valid email address.'
	const identicalEmailsErrorMessage =
		'Primary customer and technical customer emails must be different'
	const customerPrimaryContactNameErrorMessage =
		'Invalid input. The name provided must be at least 2 characters long. Please enter a valid name.'
	const customerTechnicalContactNameErrorMessage =
		'Invalid input. The name provided must be at least 2 characters long. Please enter a valid name.'
	const [isLoading, setIsLoading] = useState(false)
	const [areEmailsIdentical, setAreEmailsIdentical] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)
	const [salesAgentEmailError, setSalesAgentEmailError] = useState(false)
	const [instanceNameError, setInstanceNameError] = useState(false)
	const [newCustomerNameError, setNewCustomerNameError] = useState(false)
	const [customerPrimaryEmailError, setCustomerPrimaryEmailError] =
		useState(false)
	const [customerTechnicalEmailError, setCustomerTechnicalEmailError] =
		useState(false)
	const [newCustomerPartnerRefError, setNewCustomerPartnerRefError] =
		useState(false)
	const [salesAgentEmail, setSalesAgentEmail] = useState('')
	const [instanceName, setInstanceName] = useState('')
	const [newCustomerName, setNewCustomerName] = useState('')
	const [customerPrimaryContactName, setCustomerPrimaryContactName] =
		useState('')
	const [customerTechnicalContactName, setCustomerTechnicalContactName] =
		useState('')
	const [newCustomerPartnerRef, setNewCustomerPartnerRef] = useState('')
	const [customerPrimaryContactNameError, setCustomerPrimaryContactNameError] =
		useState(false)
	const [
		customerTechnicalContactNameError,
		setCustomerTechnicalContactNameError,
	] = useState(false)
	const [customerEmail, setCustomerEmail] = useState('')
	const [customerTechnicalContactEmail, setCustomerTechnicalContactEmail] =
		useState('')
	const [pocInstances, setPOCInstances] = useState(
		[] as GetPOCInstancesResponseModel[]
	)

	// Error logging
	const teamsSKUQuantity = 20
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const validateOrderInfo = async () => {
		if (!isFormValid) {
			showErrorToast(
				'There is an error on the form. Please correct this, then submit the form.'
			)
		} else {
			var errorMessage = ''
			var addCustomerResponseModel = {} as AddCustomerResponseModel
			var selectedInstance = instanceStatusesList.find(
				(x) => x.POCInstanceName === instanceName
			)

			if (selectedInstance) {
				setIsLoading(true)
				// Add Customer - HALO API
				var addCustomerRequest: AddCustomerRequestModel = {
					CustomerName: newCustomerName,
					PartnerCustomerReference: newCustomerPartnerRef,
				}

				var postCustomerObj: PostHookObj = {
					Action: `Add Customer for Trial Instance`,
					LoggedInUser: loggedInUser,
					RequestURL: 'customers',
					RequestObj: addCustomerRequest,
					ShowSuccessMessage: false,
					SuccessMessage: `Customer has been updated successfully`,
					ShowErrorMessage: true,
					ErrorMessage: `An error occurred when adding customer for trial order`,
					LogErrorToDB: true,
				}

				var addCustomerResponse = await postCustomAPI(postCustomerObj)
				if (addCustomerResponse && addCustomerResponse.isError) {
					errorMessage = addCustomerResponse.Content
				}

				if (errorMessage.length === 0 && addCustomerResponse) {
					addCustomerResponseModel = JSON.parse(
						addCustomerResponse?.Content
					) as AddCustomerResponseModel

					//Add order to DB and navigate to provisioning page
					var contactDetails: ContactDetailModel[] = [
						{
							Name: customerPrimaryContactName,
							Email: customerEmail,
							ContactType: 'Primary',
						},
					]
					if (
						customerTechnicalContactName.length > 0 &&
						customerTechnicalContactEmail.length > 0
					) {
						contactDetails.push({
							Name: customerTechnicalContactName,
							Email: customerTechnicalContactEmail,
							ContactType: 'Engineering',
						})
					}

					var bulkContactDetailsReq: AddBulkContactDetailsRequestModel = {
						ContactDetails: contactDetails,
						CustomerID: addCustomerResponseModel?.customerID,
					}

					var postContactDetailsObj: PostHookObj = {
						Action: `Add ContactDetails for Trial Instance`,
						LoggedInUser: loggedInUser,
						RequestURL: 'contactdetails/bulk',
						RequestObj: bulkContactDetailsReq,
						ShowSuccessMessage: false,
						SuccessMessage: `Contact Details have been added successfully`,
						ShowErrorMessage: true,
						ErrorMessage: `An error occurred when adding contact details for trial order`,
						LogErrorToDB: true,
					}

					var addContactDetailsResponse = await postCustomAPI(
						postContactDetailsObj
					)

					if (addContactDetailsResponse && addContactDetailsResponse.isError) {
						errorMessage = addContactDetailsResponse.Content
					}

					if (errorMessage.length === 0) {
						var addOrderRequest: AddOrderRequestModel = {
							CoTerm: false,
							Currency: 'USD',
							PartnerCustomerReference: newCustomerPartnerRef,
							SKUs: [
								{
									NonStandardPricing: false,
									Quantity: teamsSKUQuantity,
									AutoRenew: false,
									SKU: 'TEAMS-DRS-SIP-POC',
									SKUTerm: {
										Months: 1,
										Years: 0,
									},
									NRC: 0,
									MRC: 0,
								},
							],
							IsTest: false,
							OrderType: 'New',
							PartnerOrderID: `POC${addCustomerResponseModel?.customerID}`,
							OrderTerm: {
								Months: 1,
								Years: 0,
							},
							SalesAgentEmail: salesAgentEmail,
						}

						var postOrderObj: PostHookObj = {
							Action: `Add Trial Order`,
							LoggedInUser: loggedInUser,
							RequestURL: 'orders',
							RequestObj: addOrderRequest,
							ShowSuccessMessage: false,
							SuccessMessage: `Trial Order has been added successfully`,
							ShowErrorMessage: true,
							ErrorMessage: `An error occurred when adding trial order`,
							LogErrorToDB: true,
						}

						var addOrderResponse = await postCustomAPI(postOrderObj)
						if (addOrderResponse && addOrderResponse.isError) {
							errorMessage = addOrderResponse.Content
						}

						if (errorMessage.length === 0 && addOrderResponse) {
							var addOrderResponseModel = JSON.parse(
								addOrderResponse.Content
							) as AddOrderResponseModel

							if (addOrderResponseModel) {
								errorMessage = await updateTrialInstanceToInUse(
									addOrderResponseModel.SipcomOrderID,
									Number(selectedInstance?.POCInstanceID)
								)

								if (errorMessage.length === 0) {
									setIsLoading(false)
									showSuccessToast('Order added Successfully')
									navigate('/dashboard')
								} else {
									showErrorToast(
										'An error occurred while updating the trial instance status. Please contact support'
									)
								}
							}
						} else {
							showErrorToast(
								'An error occurred when adding Order. Please contact support'
							)

							// delete created customer
							await rollbackPartiallyCreatedPocInfo(
								addCustomerResponseModel?.customerID,
								true
							)
						}
					} else {
						await rollbackPartiallyCreatedPocInfo(
							addCustomerResponseModel?.customerID,
							false
						)
					}
				}
				setIsLoading(false)
			}
		}
	}

	const rollbackPartiallyCreatedPocInfo = async (
		customerID: string,
		removeCustomerContacts: boolean
	) => {
		var deleteRequest = {} as AddRequest
		var deleteDataQueryString = 'DeleteV2?Params='

		if (!removeCustomerContacts) {
			deleteDataQueryString += 'Customer'
			deleteRequest = {
				UserID: loggedInUser.userID,
				UserName: loggedInUser.email,
				Obj: {
					Customer: {
						CustomerID: customerID,
					},
				},
			}
		} else {
			deleteDataQueryString += 'ContactDetailMap,ContactDetail:List,Customer'
			var requestURL = `ContactDetail.ContactDetailMap.Where(ContactDetailMap.CustomerID = '${customerID}')`

			//Get added contact details
			var dataResponse = await postAndRetrieveDataFromDB(
				'Contact Details for new Lumen POC customer',
				requestURL,
				false,
				true
			)

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var contactDetailList = dataResponse.Obj
					.ContactDetailList as ContactDetail[]
				if (contactDetailList) {
					var contactDetailListToDelete = [] as ContactDetail[]
					contactDetailList.forEach((x) => {
						var _contactDetailToDelete: ContactDetail = {
							ContactDetailID: x.ContactDetailID,
						}
						contactDetailListToDelete.push(_contactDetailToDelete)
					})

					deleteRequest = {
						UserID: loggedInUser.userID,
						UserName: loggedInUser.email,
						Obj: {
							Customer: {
								CustomerID: customerID,
							},
							ContactDetailMap: {
								CustomerID: customerID,
							},
							ContactDetailList: contactDetailListToDelete,
						},
					}
				}
			}
		}

		var deleteApiMutation = getEmptyAPIMutationObject()
		deleteApiMutation.BetaObject = { Content: await toBeta(deleteRequest) }
		deleteApiMutation.QueryParam = deleteDataQueryString

		await postWithoutRefetch(deleteApiMutation)
			.unwrap()
			.then()
			.catch(async (error) => {
				if (error) {
					await addErrorLog(
						'Failed to rollback partially created info during Lumen Trial order creation',
						`${JSON.stringify(error?.data + '')}`
					)
				}
			})
	}

	const updateTrialInstanceToInUse = async (
		orderID: number,
		POCInstanceID: number
	) => {
		var errorMessage = ''
		var updateDataQueryString = 'UpdateV2?Params=POCInstance'

		var startDate = getUTCDateTimeNow()
		var endDate = new Date(
			getUTCDateTimeNow().setMonth(getUTCDateTimeNow().getMonth() + 1)
		)

		var pocInstanceToUpdate: POCInstance = {
			POCInstanceID: POCInstanceID,
			OrderID: orderID,
			InstanceStatusID: Number(InstanceStatuses.InUse),
			StartDate: startDate,
			EndDate: endDate,
		}

		var updateRequest: AddRequest = {
			UserID: loggedInUser.userID,
			UserName: loggedInUser.email,
			Obj: {
				POCInstance: pocInstanceToUpdate,
			},
		}

		var updateApiMutation = getEmptyAPIMutationObject()
		updateApiMutation.BetaObject = { Content: await toBeta(updateRequest) }
		updateApiMutation.QueryParam = updateDataQueryString

		await postWithoutRefetch(updateApiMutation)
			.unwrap()
			.then()
			.catch(async (error) => {
				if (error) {
					errorMessage = 'Unable to update status of trial instance'
					await addErrorLog(
						'Unable to update status of Lumen trial instance',
						`orderID: ${orderID} pocInstanceID: ${POCInstanceID} error info: ${JSON.stringify(
							error?.data + ''
						)}`
					)
				}
			})

		return errorMessage
	}

	const handleInstanceNameChange = (e: { target: any }) => {
		setInstanceNameError(true)
		var customerName = e.target.value
		if (customerName.length > 2) {
			setInstanceNameError(false)
			setInstanceName(customerName + '')
		}
	}

	const formIsValid = (
		_salesAgentEmail: string,
		_newCustomerName: string,
		_technicalName: string,
		_customerEmail: string,
		_customerTechnicalEmail: string,
		_customerPrimaryContactName: string,
		_newCustomerPartnerRef: string
	) => {
		setAreEmailsIdentical(false)
		var isValid = true
		if (!emailValidation(_salesAgentEmail)) {
			isValid = false
		}

		if (_newCustomerName.trim().length < 2) {
			isValid = false
		}

		if (!emailValidation(_customerEmail)) {
			isValid = false
		}
		if (
			_technicalName.length === 1 ||
			(_technicalName.length > 1 && _customerTechnicalEmail.length === 0)
		) {
			isValid = false
		}
		if (
			_customerTechnicalEmail.length > 0 &&
			(!emailValidation(_customerTechnicalEmail) || _technicalName.length < 2)
		) {
			isValid = false
		}
		if (
			_customerEmail.toLowerCase().trim() ===
				_customerTechnicalEmail.toLowerCase().trim() &&
			customerEmail.length !== 0 &&
			_customerTechnicalEmail.length !== 0
		) {
			setAreEmailsIdentical(true)
			isValid = false
		}
		if (_customerPrimaryContactName.length < 2) {
			isValid = false
		}

		if (_newCustomerPartnerRef.length < 2) {
			isValid = false
		}
		return isValid
	}
	const onChangeStringValue = async (val: string, field: string) => {
		setIsFormValid(false)
		setAreEmailsIdentical(false)
		var _salesAgentEmail = salesAgentEmail
		var _newCustomerName = newCustomerName
		var _customerEmail = customerEmail
		var _customerTechnicalEmail = customerTechnicalContactEmail
		var _customerPrimaryContactName = customerPrimaryContactName
		var _customerTechnicalContactName = customerTechnicalContactName
		var _newCustomerPartnerRef = newCustomerPartnerRef
		switch (field) {
			case 'salesAgentEmail':
				setSalesAgentEmail(val)
				_salesAgentEmail = val
				setSalesAgentEmailError(true)
				if (emailValidation(val)) {
					setSalesAgentEmailError(false)
				}
				break
			case 'newCustomerName':
				_newCustomerName = val
				setNewCustomerName(val)
				setNewCustomerNameError(true)
				if (val.trim().length >= 2) {
					setNewCustomerNameError(false)
				}
				break
			case 'customerPrimaryEmail':
				_customerEmail = val
				setCustomerEmail(val)
				setCustomerPrimaryEmailError(true)
				if (emailValidation(val)) {
					setCustomerPrimaryEmailError(false)
				}
				break
			case 'customerTechnicalEmail':
				_customerTechnicalEmail = val
				setCustomerTechnicalContactEmail(val)
				setCustomerTechnicalEmailError(true)
				if (emailValidation(val) || val.length === 0) {
					setCustomerTechnicalEmailError(false)
				}
				break
			case 'customerPrimaryContactName':
				_customerPrimaryContactName = val
				setCustomerPrimaryContactName(val)
				setCustomerPrimaryContactNameError(true)
				if (val.trim().length >= 2) {
					setCustomerPrimaryContactNameError(false)
				}
				break
			case 'customerTechnicalContactName':
				_customerTechnicalContactName = val
				setCustomerTechnicalContactName(val)
				setCustomerTechnicalContactNameError(true)
				if (val.length === 0 || val.trim().length >= 2) {
					setCustomerTechnicalContactNameError(false)
				}
				break
			case 'newCustomerPartnerRef':
				_newCustomerPartnerRef = val
				setNewCustomerPartnerRef(val)
				setNewCustomerPartnerRefError(true)
				if (val.trim().length >= 2) {
					setNewCustomerPartnerRefError(false)
				}
				break
		}
		setIsFormValid(
			formIsValid(
				_salesAgentEmail,
				_newCustomerName,
				_customerTechnicalContactName,
				_customerEmail,
				_customerTechnicalEmail,
				_customerPrimaryContactName,
				_newCustomerPartnerRef
			)
		)
	}

	useEffect(() => {
		if (pageTitle !== 'Add Trial Order') {
			dispatch(setPageTitle('Add Trial Order'))
		}

		var availableInstances = instanceStatusesList.filter(
			(x) => x.POCInstanceStatusID === InstanceStatuses.Available
		)
		if (availableInstances.length > 0) {
			setPOCInstances(availableInstances)
			setInstanceName(availableInstances[0].POCInstanceName)
		} else {
			showErrorToast('There are no available instances to add orders for')
			navigate('/')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageTitle, instanceStatusesList])

	return (
		<>
			{!isLoading ? (
				<Box className='poc-container'>
					<Box className='poc-form-content'>
						<Box className='poc-form'>
							<Typography component='h1'>Add Trial Order</Typography>
							<StyledTextBox
								fullWidth
								label='Sales Agent Email'
								variant='outlined'
								value={salesAgentEmail}
								onChange={(e) => {
									onChangeStringValue(e.target.value, 'salesAgentEmail')
								}}
								required
								error={salesAgentEmailError}
								id='outlined-error-helper-text'
								helperText={
									salesAgentEmailError
										? salesAgentEmailErrorMessage
										: 'Please enter a valid email address.'
								}
							/>
							<Box>
								<Typography
									component='span'
									color='text.header'
									className='form-label'>
									Instance Name:
								</Typography>
								<StyledSelect
									fullWidth
									required
									variant='outlined'
									className='form-input-select'
									value={instanceName}
									error={instanceNameError}
									onChange={handleInstanceNameChange}>
									{pocInstances.map((item) => {
										return (
											<MenuItem
												key={item.POCInstanceID + ''}
												value={item.POCInstanceName + ''}>
												{item.POCInstanceName}
											</MenuItem>
										)
									})}
								</StyledSelect>
							</Box>
							<StyledTextBox
								fullWidth
								label='New Customer Name'
								variant='outlined'
								value={newCustomerName}
								onChange={(e) =>
									onChangeStringValue(e.target.value, 'newCustomerName')
								}
								required
								error={newCustomerNameError}
								id='outlined-error-helper-text'
								helperText={
									newCustomerNameError
										? newCustomerNameErrorMessage
										: 'Please enter a valid customer name'
								}
							/>
							<StyledTextBox
								fullWidth
								label='BizOrg'
								variant='outlined'
								value={newCustomerPartnerRef}
								onChange={(e) =>
									onChangeStringValue(e.target.value, 'newCustomerPartnerRef')
								}
								required
								error={newCustomerPartnerRefError}
								id='outlined-error-helper-text'
								helperText={
									newCustomerPartnerRefError
										? newCustomerPartnerRefErrorMessage
										: 'Please enter a valid BizOrg ID'
								}
							/>
							<StyledTextBox
								fullWidth
								label='Customer Primary Contact Name'
								variant='outlined'
								value={customerPrimaryContactName}
								onChange={(e) =>
									onChangeStringValue(
										e.target.value,
										'customerPrimaryContactName'
									)
								}
								required
								error={customerPrimaryContactNameError}
								id='outlined-error-helper-text'
								helperText={
									customerPrimaryContactNameError
										? customerPrimaryContactNameErrorMessage
										: 'Please enter a valid name.'
								}
							/>
							<StyledTextBox
								fullWidth
								label='Customer Primary Contact Email'
								variant='outlined'
								value={customerEmail}
								onChange={(e) =>
									onChangeStringValue(e.target.value, 'customerPrimaryEmail')
								}
								required
								error={customerPrimaryEmailError || areEmailsIdentical}
								id='outlined-error-helper-text'
								helperText={
									customerPrimaryEmailError && !areEmailsIdentical
										? customerPrimaryEmailErrorMessage
										: 'Please enter a valid email address'
								}
							/>
							{areEmailsIdentical && (
								<Alert severity='error'>{identicalEmailsErrorMessage}</Alert>
							)}
							<StyledTextBox
								fullWidth
								label='Customer Technical Contact Name'
								variant='outlined'
								value={customerTechnicalContactName}
								onChange={(e) =>
									onChangeStringValue(
										e.target.value,
										'customerTechnicalContactName'
									)
								}
								error={customerTechnicalContactNameError}
								id='outlined-error-helper-text'
								helperText={
									customerTechnicalContactNameError
										? customerTechnicalContactNameErrorMessage
										: 'Please enter a valid name.'
								}
							/>
							<StyledTextBox
								fullWidth
								label='Customer Technical Contact Email'
								variant='outlined'
								value={customerTechnicalContactEmail}
								onChange={(e) =>
									onChangeStringValue(e.target.value, 'customerTechnicalEmail')
								}
								error={customerTechnicalEmailError || areEmailsIdentical}
								id='outlined-error-helper-text'
								helperText={
									customerTechnicalEmailError && !areEmailsIdentical
										? customerTechnicalEmailErrorMessage
										: 'Please enter a valid email address.'
								}
							/>
							{areEmailsIdentical && (
								<Alert severity='error'>{identicalEmailsErrorMessage}</Alert>
							)}
							<div className='form-button'>
								<Tooltip
									title={
										!isFormValid && 'Please make sure that all fields are valid'
									}>
									<span>
										<Button
											disabled={!isFormValid}
											variant='contained'
											onClick={() => {
												validateOrderInfo()
											}}>
											Add Order
										</Button>
									</span>
								</Tooltip>
							</div>
						</Box>
					</Box>
				</Box>
			) : (
				<Preloader />
			)}
		</>
	)
}

export default AddPOCOrder
