// User Management Endpoints
export enum Endpoints {
	ME_INFO = 1,
	DIRECTORY_ROLES = 2,
	GET_LICENSES = 3,
	GET_ALL_USER_LICENSES = 4,
	GET_LINE_URI_AND_ROUTING_PLANS = 5,
}

export enum Permissions {
	ViewCustomer = 1,
	AddCustomer = 2,
	UpdateCustomer = 3,
	DeleteCustomer = 4,
	AddMSTeamsUser = 5,
	UpdateMSTeamsUser = 6,
	ViewSharedCarriers = 7,
	AddSharedCarriers = 8,
	UpdateSharedCarriers = 9,
	DeleteSharedCarriers = 10,
	ViewMCCA = 11,
	AddMCCA = 12,
	UpdateMCCA = 13,
	DeleteMCCA = 14,
	ViewUCMC = 15,
	AddUCMC = 16,
	UpdateUCMC = 17,
	DeleteUCMC = 18,
	ViewZoom = 19,
	AddZoom = 20,
	DeleteZoom = 21,
	ViewBYOR = 22,
	AddBYOR = 23,
	UpdateBYOR = 24,
	DeleteBYOR = 25,
	ViewDDI = 26,
	AddDDI = 27,
	UpdateDDI = 28,
	ViewUsers = 29,
	AddUsers = 30,
	UpdateUsers = 31,
	DeleteUsers = 32,
	ViewOrders = 33,
	AddOrders = 34,
	UpdateOrders = 35,
	ViewReports = 36,
	ExportReports = 37,
	AddTeamsDirectRoutingConfig = 38,
	ViewTeamsDirectRoutingConfig = 39,
	ViewMSTeamsUser = 40,
	DeleteMSTeamsUser = 41,
	ViewTrunkGroups = 42,
	AddTrunkGroups = 43,
	UpdateTrunkGroups = 44,
	DeleteTrunkGroups = 45,
	UpdateZoom = 46,
	MSTeamsExtentionUpload = 47,
	ManageTeamsUsers = 48,
	ProvisionTeamsDirectRouting = 49,
	ViewDashboard = 50,
	ViewPriceBooks = 51,
	AddPriceBooks = 52,
	UpdatePriceBooks = 53,
	ViewAddress = 54,
	AddAddress = 55,
	UpdateAddress = 56,
	ViewSettings = 57,
	ViewAppearanceSettings = 58,
	ViewPortalUserSettings = 59,
	ViewCommunicationSettings = 60,
}

export enum InvalidIDs {
	No_Carrier = 1,
	No_Customer = '000000',
	No_Partner = 1,
}

export enum Roles {
	ITEngineer = 1,
	Sales = 2,
	PMO = 3,
	InternalAdmin = 4,
	ComplianceReporting = 5,
	ITSupport = 6,
	Porting = 7,
	PartnerAdmin = 8,
	SelfService = 9,
	CustomerAdmin = 10,
	ReportingBilling = 11,
}

export enum ProvisioningSteps {
	ProvisioningStarted = 1,
	CreatingDomains = 2,
	VerifyingDomains = 3,
	ConfiguringServiceAccounts = 4,
	ConfiguringTeamsDRVoice = 5,
	TeamsDRVoiceConfigurationComplete = 6,
	StartingServiceManagerConfiguration = 7,
	AddingTeamsAdminRoleToSMApp = 8,
	ServiceManagerConfigurationComplete = 9,
	TeamsDRConfigurationError = 101,
	ServiceManagerError = 102,
}

export enum SDASteps {
	GlobalAdminAndHaloUsersLicenseCheck = 1,
	LicenseSelection = 2,
	CreatingAndVerifyingDomains = 3,
	TeamsDRVoiceConfiguration = 4,
	ServiceManagerConfiguration = 5,
	SDAConfigurationComplete = 6,
}

export enum TokenType {
	GraphToken = 1,
	TeamsToken = 2,
}

export enum UserAdminPermissionsEnums {
	GlobalAdmin = 1,
	TeamsAdmin = 2,
	TeamsCommunicationsAdmin = 3,
}

export enum CustomerStatus {
	ACTIVE = 1,
	NOT_ACTIVE = 2,
}

export enum OrderStatuses {
	AwaitingPartnerApproval = 'AW-PART-APP',
	PartnerApproved = 'PART-APP',
	AwaitingSalesApproval = 'AW-SA-APP',
	SalesApproved = 'SA-APP',
	AwaitingFinanceApproval = 'AW-FIN-APP',
	FinanceApproved = 'FIN-APP',
	SystemApproved = 'SYS-APP',
	InProgress = 'INPROG',
	Complete = 'COMP',
	Rejected = 'REJEC',
	Active = 'ACTIV',
}

export enum OrderType {
	New = 1,
	Modify = 2,
	Renewal = 3,
	TrueUp = 4,
	Disconnect = 5,
}

export enum Services {
	MSTeamsDirectRouting = 1,
	Zoom = 2,
	UCMC = 3,
	MCCA = 4,
	BYOR = 5,
	BYOC = 6,
	BYOT = 7,
	BYPT = 8,
	DSIP = 9,
	SharedCarrier = 10,
	BYPC = 11,
	MSTeamsOperatorConnect = 12,
}

export enum OrderSKUStatuses {
	InProgress = 1,
	Active = 2,
}

// Dev
// export enum SREServices {
// 	TEAMS_DR = 1,
// 	BYOT = 2,
// 	BYOC = 3,
// 	BYOR = 4,
// 	ZOOM = 5,
// 	MCCA = 6,
// 	UCMC = 7,
// 	TEAMS_OCA = 8,
// 	PSTN_Lumen = 9,
// 	BYPT = 10,
// }

// Prod
export enum SREServices {
	TEAMS_DR = 3,
	BYOT = 4,
	BYOC = 5,
	BYOR = 6,
	ZOOM = 7,
	MCCA = 8,
	UCMC = 9,
	TEAMS_OCA = 10,
	PSTN_Lumen = 11,
	BYPT = 12,
}

export enum CallDeliveryMethods {
	Hunt = 1,
	RoundRobin = 2,
	LeastBusy = 3,
	ProportionalDistribution = 4,
	IowestSustainedRate = 5,
}

export enum CustomerStatuses {
	Active = 1,
	NotActive = 2,
}

export enum InstanceStatuses {
	Available = 1,
	InUse = 2,
}

export enum InternetProtocols {
	UDP = 1,
	TCP = 2,
}

export enum StrategyOption {
	Priority = 1,
	Weight = 2,
}

export enum TrunkTypes {
	BYOT = 1,
	BYPT = 2,
	BYOC = 3,
	DSIP = 4,
}

export enum RegionOptions {
	AMER = 1,
	APAC = 2,
	EMEA = 3,
}

export enum PartnerCodeToIDMapping {
	lmn = 2,
	swo = 4,
	cac = 3,
	sip = 6,
}

export enum Carriers {
	No_Carrier = 1,
	Lumen = 2,
	Gamma = 3,
	BT = 4,
	Colt_VOIP = 5,
	Colt_Number_Hosting = 6,
	DIDWW = 7,
	Voxbone = 8,
	Bandwidth = 9,
	SINTEL = 10,
	RedTone = 11,
	GTT = 12,
	Interlepeer = 13,
	GVM = 14,
	Net2Phone = 15,
	BYOC = 16,
	Not_Applicable = 17,
	Orange = 18,
	Supertel = 19,
	Level3 = 21,
}

export enum DDIStatuses {
	Received = 1,
	Allocated = 2,
	PendingEmergencyAddressData = 3,
	Validation = 4,
	FOC = 5,
	Inactive = 6,
	Active = 7,
	Test = 8,
	Exported = 9,
	Terminated = 10,
	ReadyForImplementation = 11,
}

export enum DDITypes {
	Port = 1,
	Test = 2,
	New = 3,
	Resource = 4,
	User = 5,
	PartnerManaged = 6,
}

export enum CustomerPartners {
	No_Partner = 1,
	Lumen = 2,
	ContentAndCloud = 3,
	SoftwareOne = 4,
	O2Business = 5,
	Sipcom = 6,
	Cloud9 = 7,
	IPC = 8,
	DemoPartner = 9,
}

export enum AddressTypes {
	Billing = 1,
	Corporate = 2,
	Residential = 3,
	EmergencyAddress = 4,
	Government = 5,
	Microsoft = 6,
}

export enum TeamsUserManagementAssign {
	ReadyForLicenseCall = 1,
	ReadyForPhoneNumberCall = 2,
	AssignCallsDone = 3,
}

export enum TeamsUserManagementUnassign {
	ReadyForPhoneNumberCall = 1,
	ReadyForLicenseCall = 2,
	UnassignCallsDone = 3,
}

export enum ServiceManagementCalls {
	ReadyForGlobalAdminCall = 1,
	ReadyForExistingSMStatusCall = 2,
	ReadyForSMTeamsAdminCall = 3,
	ReadyForSMStatusAndReportCall = 4,
	ReadyForCleanupCall = 5,
	ServiceManagementCallsDone = 6,
}

export enum SDAStatus {
	NoTeamsOrderSKUFound = 0,
	NotReadyForSDA = 1,
	ReadyForSDA = 2,
	SDACompleted = 3,
}

export enum ServiceManagementStatusCheck {
	NoServiceManagementConfigFound = 0,
	ServiceManagementFailure = 1,
	ServiceManagementSuccess = 2,
}

// Teams User Management - Checking what is being assigned/unassigned
export enum TeamsUserConfigurationTypes {
	'Assign a Base License' = 1,
	'Assign an Addon License' = 2,
	'Assign a Phone Number' = 3,
	'Assign a Base License And an Addon License' = 4,
	'Assign a Base License And a Phone Number' = 5,
	'Assign an Addon License And a Phone Number' = 6,
	'Assign a Base License, an Addon License And a Phone Number' = 7,
	'Unassign a Base License' = 8,
	'Unassign an Addon License' = 9,
	'Unassign a Phone Number' = 10,
	'Unassign a Base License And an Addon License' = 11,
	'Unassign a Base License And a Phone Number' = 12,
	'Unassign an Addon License And a Phone Number' = 13,
	'Unassign a Base License, an Addon License And a Phone Number' = 14,
	'Change the Voice Routing Policy to National Calling' = 15,
	'Change the Voice Routing Policy to International Calling' = 16,
}

// Teams User Management - VRP
export enum TeamsUserVRPTypes {
	NotOurVRP = 1,
	NationalVRP = 2,
	InternationalVRP = 3,
}

// Teams User Management - Configuration Status
export enum TeamsUserConfigurationStatuses {
	PendingFirstCall = 1,
	PendingFirstCheck = 2,
	PendingSecondCall = 3,
	PendingSecondCheck = 4,
	CompleteAll = 200,
}

// Teams User Management - Assignment Types
export enum TeamsUserManagementTypes {
	BaseLicense = 1,
	AddonLicense = 2,
	PhoneNumber = 3,
}

// Configuration Type
export enum TeamsLicenseConfigurationTypes {
	Assign = 1,
	Unassign = 2,
}

export enum UserManagementModals {
	RemoveUserAuth0 = 1,
	PendingInvites = 2,
	InviteMembers = 3,
}

export enum Modals {
	E911Configurations = 1,
	ResendAuth0Invites = 2,
	AddTN = 3,
	ConfirmOrder = 4,
	SKUPriceUpdate = 5,
	SKUNRCUpdate = 6,
	SKUTermUpdate = 7,
	OrderNotes = 8,
	AutoRenewUpdate = 9,
	AssignTNAddresses = 10,
	OCServiceInformation = 11,
}

export enum DocumentationTypes {
	AddDID = 1,
	AddTrunks = 2,
	TeamsE911CallRoutingConfiguration = 3,
	TenantConfiguration = 4,
}

// FAQs
export enum FAQTypes {
	'Email Notifications' = 1,
	'Teams Direct Routing' = 2,
	'Service Manager' = 3,
	'Teams User Management' = 4,
}

// Tutorial Videos
export enum TutorialVideoTypes {
	'Teams Direct Routing Provisioning' = 1,
	'Teams User Management' = 2,
	'Teams Admin Center Guides' = 3,
}

export enum ContactDetailType {
	Billing = 'Billing',
	Engineering = 'Engineering',
}

export enum UserManagementType {
	Members = 1,
	User = 2,
	DeleteUser = 3,
	PendingInvites = 4,
}

export enum TrueFalseAny {
	False = 0,
	True = 1,
	Any = 2,
}

export enum RadioValues {
	PartnerAdmin = 'Partner Admin',
	CustomerAdmin = 'Customer Admin',
}

export enum OCLeadStatus {
	ConsentSubmitted = 1,
	ConsentAcknowledged = 2,
	CustomerContacted = 3,
	AgreementSigned = 4,
	ConsentDeclined = 5,
	ContractTerminated = 6,
	ConsentNotSubmitted = 100,
}

export enum LeadStatus {
	Live = 1,
	Sold = 2,
	Dead = 3,
}

export enum ProductActionStatus {
	COMP,
	FAIL,
	'IN-PROG',
	'NOT-START',
}

export enum TeamsDRServiceValueDisplayOptions {
	PartnerServiceID = 1,
	DomainName = 2,
	ServiceID = 3,
}

export enum LeadWeightEnum {
	Pipeline = 1,
	'Best Case' = 2,
	Commit = 3,
	'Closed Won' = 4,
	'Closed Lost' = 5,
}

export enum ServiceCategory {
	Application = 1,
	Connectivity = 2,
}

export enum ServiceTypes {
	MSTeamsDirectRouting = 1,
	Zoom = 2,
	UCMC = 3,
	MCCA = 4,
	BYOR = 5,
	BYOC = 6,
	BYOT = 7,
	BYPT = 8,
	DSIP = 9,
	SharedCarrier = 10,
	BYPC = 11,
	MSTeamsOperatorConnect = 12,
}

export enum FilteredOrderStatus {
	NoRecords = 0,
	Success = 1,
	Failure = 2,
}

// Order Filter Mappings
export enum OrderFilterKeyMappings {
	ExternalOrderID = 'Order ID',
	CustomerPartnerReference = 'BizOrg',
	CustomerID = 'Customer ID',
	CustomerName = 'Customer Name',
	OrderStatusID = 'Order Status',
	PartnerOrderID = 'Partner Order ID',
	PurchaseOrder = 'Purchase Order',
	SipcomDirect = 'Sipcom Direct',
}

// Return type - This is used for the GET function to determine what return type you want
export enum ReturnTypes {
	ObjectOrList = 1,
	String = 2,
}

// TUM Build Statuses (Used for loading the Teams User MGT page)
export enum TUMBuildStatuses {
	NotYetStarted = 0,
	Loading = 1,
	Success = 2,
	Failure = 3,
}

// TUM Status Types
export enum TUMLoadTypes {
	Admin = 1,
	Precheck = 2,
	Retrieval = 3,
}

export enum TUMPagination {
	Next = 1,
	Previous = 2,
}

// Teams User MGT - Update statuses
export enum TUMUpdateStatuses {
	Pending = 0,
	Success = 1,
	Failure = 2,
}

// Teams User MGT Filter select
export enum TUMUserFilterOptions {
	'Username (UPN)' = 1,
	'Name' = 2,
}

// Teams User MGT - Overview Display Options
export enum TUMOverviewDisplayOptions {
	BaseLicenseDisplay = 1,
	AddonLicenseDisplay = 2,
	DDIRangeDisplay = 3,
}

// Config type - Used for Teams User MGT
export enum TUMConfigurationTypes {
	'Add License' = 1,
	'Remove License' = 2,
	'Add TN' = 3,
	'Remove TN' = 4,
}

// Phone Number type - Used for Teams User MGT
export enum TUMPhoneNumberTypes {
	'TDR' = 1,
	'OC' = 2,
}

// License types
export enum TUMLicenseTypes {
	Base = 1,
	Addon = 2,
	AdditionalAddon = 2,
}

export enum TUMPhoneTypes {
	'Teams Direct Routing' = 1,
	'Operator Connect' = 12,
}

export enum ContactDetailTypes {
	Billing = 1,
	Engineering = 2,
	Sales = 3,
	Admin = 4,
	Finance = 5,
}

// Current usage in role emails mgt. Used to track if something in the form has changed.
export enum FormChangeStatus {
	NoChange = 0,
	NewValue = 1,
	UpdatedValue = 2,
}
